import { Button, Col, Dropdown, Menu, Tooltip } from 'antd'
import { LoginOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons'

import AppContext from './AppContext'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import useResponsive from '../../hooks/useResponsive'

const ProfileMenu = ({ onShowLogin }) => {
  const { loginInProgress, currentUser, logout } = useContext(AppContext)
  const bp = useResponsive()

  const tooltipText = currentUser ? `You're logged in as ${currentUser.firstName} ${currentUser.lastName}` : 'Login'

  const menu = (
    <Menu selectable={false} id="programs" style={{ minWidth: '15em' }}>
      <Menu.Item key="profile" icon={<UserOutlined />}>
        <Link to="/profile">My profile</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        <Link to="/" onClick={logout}>
          Logout
        </Link>
      </Menu.Item>
    </Menu>
  )

  const dropdown = (
    <Dropdown overlay={menu} placement="topRight" trigger={['click']}>
      <Tooltip title={tooltipText} placement="left">
        <Button icon={<UserOutlined className="menuIcon" />} type="link">
          {bp.xsOrSm ? '' : `${currentUser?.firstName || currentUser?.username}`}
        </Button>
      </Tooltip>
    </Dropdown>
  )
  const login = (
    <Button icon={<LoginOutlined className="menuIcon" />} type="link" onClick={onShowLogin}>
      {bp.xsOrSm ? '' : 'Login'}
    </Button>
  )

  const result = loginInProgress ? null : currentUser ? dropdown : login

  return <Col className="menuItem">{result}</Col>
}

export default ProfileMenu
