import ReactMarkdown from 'react-markdown'
import Section from '../common/Section'
import { Typography } from 'antd'
import _ from 'lodash'
import { transformMarkdownUri } from '../../util/commonUtil'
import useEvents from '../../hooks/api/useEvents'
const { Text, Paragraph } = Typography

const getDateTime = (event) => {
  let result = ''
  if (event?.date) result += 'Date: ' + event.date
  if (event?.startTime) result += 'Start: ' + event.startTime
  if (event?.endTime) result += 'End: ' + event.endTime

  return result
}

const EventsPage = () => {
  const { events } = useEvents()

  return (
    <>
      {_.isEmpty(events) && (
        <Section title="Events">
          <Paragraph>Check back soon as we release details about events and tournaments for this season.</Paragraph>
        </Section>
      )}

      {events?.map((record, i) => {
        const event = record.attributes
        const dateTime = getDateTime(event)
        return (
          <Section title={event.name}>
            <>
              {dateTime && (
                <Text type="secondary" italic>
                  {dateTime}
                </Text>
              )}
              <ReactMarkdown children={event.description} transformImageUri={transformMarkdownUri} />
            </>
          </Section>
        )
      })}
    </>
  )
}

export default EventsPage
