import { useEffect, useState } from 'react'

import { GET } from '../../gql/booking.gql'
import axios from '../../axios/axios'
import { config } from '../../util/config'
import { notifyError } from '../../util/notifyUtil'
import { useQuery } from '@apollo/client'

const useUsers = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const result = await axios.get('/users-permissions/admin/users')
        setData(result.data)
      } catch (e) {
        setError(e)
        notifyError('error', e)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return { users: data, loading, error }
}

export default useUsers
