import Section from '../common/Section'
import { Typography } from 'antd'
const { Paragraph } = Typography

const AboutPage = () => {
  return (
    <>
      <Section title="About">
        <Paragraph>TODO</Paragraph>
      </Section>
    </>
  )
}

export default AboutPage
