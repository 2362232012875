import { Divider, Space, Switch } from 'antd'
import { getTimeslotDisplayValue, getWeekDisplayValue } from '../../util/campUtil'

import Section from '../common/Section'
import { Table } from 'ant-table-extensions'
import _ from 'lodash'
import { compareStrings } from '../../util/compareUtil'
import { defaultDateTime } from '../../util/dateTimeUtil'
import useCampSignups from '../../hooks/api/useCampSignups'
import { useState } from 'react'

const flatten = (data) => {
  if (!data) {
    return null
  }
  return _.flatMap(data, (data) => ({ id: data.id, ...data.attributes }))
}

const summarizeData = (data) => {
  if (!data) {
    return {}
  }
  let result = _.flatMap(data, (data) => ({ id: data.id, ...data.attributes }))

  let recordsByTimeslot = _.groupBy(result, (r) => r.week + '-' + r.timeslot)
  let summaryByTimeslot = []
  for (const timeslotKey in recordsByTimeslot) {
    console.log(timeslotKey)
    const count = _.size(recordsByTimeslot[timeslotKey])
    if (count > 0) {
      const firstRecord = _.first(recordsByTimeslot[timeslotKey])
      summaryByTimeslot.push({ key: timeslotKey, weekKey: firstRecord.week, timeKey: firstRecord.timeslot, count })
    }
  }

  summaryByTimeslot = _.sortBy(summaryByTimeslot, (s) => s.key)
  return { summaryByTimeslot, recordsByTimeslot }
}

const colProps = (dataIndex) => {
  return {
    sorter: (a, b) => compareStrings(_.get(a, dataIndex), _.get(b, dataIndex)),
  }
}

const CampSignupsPage = () => {
  const [groupedMode, setGroupedMode] = useState(true)
  const { campSignups, loading } = useCampSignups()
  let flatData = campSignups ? _.filter(flatten(campSignups), (r) => r.timeslot !== 'advanced') : null
  flatData = _.sortBy(flatData, (d) => d.week + d.timeslot)
  const { summaryByTimeslot, recordsByTimeslot } = summarizeData(campSignups)

  const summaryColumns = [
    {
      title: 'Timeslot',
      width: '25em',
      dataIndex: 'timeslot',
      ...colProps('timeslot'),
      render: (value, record) => getWeekDisplayValue(record.weekKey) + ' @ ' + getTimeslotDisplayValue(record.timeKey),
    },
    { title: 'Number in timeslot', dataIndex: 'count', ...colProps('count') },
  ]

  const columns = [
    { title: '', key: 'blank', width: '5em' },
    {
      title: 'Signup date/time',
      dataIndex: 'createdAt',
      ...colProps('createdAt'),
      render: (text, record) => defaultDateTime(text),
    },
    { title: 'Last name', dataIndex: 'lastName', ...colProps('lastName') },
    { title: 'First name', dataIndex: 'firstName', ...colProps('firstName') },
    { title: 'Age', dataIndex: 'age', ...colProps('age') },
    { title: 'Email', dataIndex: 'email', ...colProps('email') },
    { title: 'Phone', dataIndex: 'phone', ...colProps('phone') },
    { title: 'Emerg Contact Name', dataIndex: 'emergContactName', ...colProps('emergContactName') },
    { title: 'Emerg Contact Number', dataIndex: 'emergContactPhone', ...colProps('emergContactPhone') },
  ]
  const allColumns = [
    {
      title: 'Week',
      dataIndex: 'week',
      render: (value, record) => getWeekDisplayValue(value),
      sorter: {
        compare: (a, b) => compareStrings(_.get(a, 'week'), _.get(b, 'week')),
        multiple: 2,
      },
    },
    {
      title: 'Time',
      dataIndex: 'timeslot',
      render: (value, record) => getTimeslotDisplayValue(value),
      sorter: {
        compare: (a, b) => compareStrings(_.get(a, 'timeslot'), _.get(b, 'timeslot')),
        multiple: 1,
      },
    },
    { title: 'ID', dataIndex: 'id', ...colProps('id') },
    {
      title: 'Signup date/time',
      dataIndex: 'createdAt',
      ...colProps('createdAt'),
      render: (text, record) => defaultDateTime(text),
    },
    { title: 'Last name', dataIndex: 'lastName', ...colProps('lastName') },
    { title: 'First name', dataIndex: 'firstName', ...colProps('firstName') },
    { title: 'Age', dataIndex: 'age', ...colProps('age') },
    { title: 'Email', dataIndex: 'email', ...colProps('email') },
    { title: 'Phone', dataIndex: 'phone', ...colProps('phone') },
    { title: 'Emerg Contact Name', dataIndex: 'emergContactName', ...colProps('emergContactName') },
    { title: 'Emerg Contact Number', dataIndex: 'emergContactPhone', ...colProps('emergContactPhone') },
  ]

  const expandedRowRender = (summaryRecord, b, c) => (
    <Table rowKey="id" columns={columns} dataSource={recordsByTimeslot?.[summaryRecord.key]} pagination={false} />
  )

  return (
    <>
      <div style={{ overflowX: 'scroll' }}>
        <Section title="Camp signups" />
        <Space direction="horizontal">
          <b>Group by timeslot?</b> <Switch checked={groupedMode} onChange={() => setGroupedMode((val) => !val)} />
        </Space>
        <Divider />
        {summaryByTimeslot && groupedMode && (
          <Table
            className="compactTable"
            style={{ minWidth: '121em' }}
            size="small"
            columns={summaryColumns}
            dataSource={summaryByTimeslot}
            expandable={{
              showExpandColumn: true,
              expandRowByClick: true,
              indentSize: 200,
              expandedRowRender,
            }}
            loading={loading}
            pagination={false}
          />
        )}
        {flatData && !groupedMode && (
          <Table
            className="compactTable"
            style={{ minWidth: '121em' }}
            size="small"
            columns={allColumns}
            dataSource={flatData}
            loading={loading}
            pagination={false}
            exportable
            exportableProps={{ showColumnPicker: true, btnProps: { type: 'link' } }}
            searchable
          />
        )}
      </div>
    </>
  )
}

export default CampSignupsPage
