import { useEffect, useState } from 'react'

import { GET } from '../../gql/booking.gql'
import axios from '../../axios/axios'
import { config } from '../../util/config'
import { notifyError } from '../../util/notifyUtil'
import { useQuery } from '@apollo/client'

const useAdultDoublesSignups = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const result = await axios.get('/admin/adult-doubles-signups')
        setData(result.data.data)
      } catch (e) {
        setError(e)
        notifyError('error', e)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return { signups: data, loading, error }
}

export default useAdultDoublesSignups
