import { Button, Form, Input, Modal, Space, Typography } from 'antd'
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { CURRENT_YEAR } from '../../util/commonUtil'
import { LOGIN } from '../../gql/auth.gql'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import axios from '../../axios/axios'

const { Paragraph } = Typography

const Login = ({ visible, onSuccess, onClose, onForgotPassword }) => {
  const [loginError, setLoginError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (visible) {
      setLoginError(false)
    }
  }, [visible])

  const doLogin = async (credentials) => {
    try {
      let { identifier } = credentials
      identifier = _.trim(identifier)
      setLoading(true)
      const result = await axios.post('/auth/local', { ...credentials, identifier }, { headers: { Authorization: '' } })
      onSuccess(result.data)
    } catch (e) {
      setLoginError(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal title="Login" visible={visible} onCancel={onClose} footer={null} destroyOnClose>
      <Form onFinish={doLogin} layout="vertical">
        <Form.Item name="identifier" required={false} rules={[{ required: true, message: '' }]}>
          <Input autoFocus placeholder="Username" />
        </Form.Item>
        <Form.Item name="password" required={false} rules={[{ required: true, message: '' }]}>
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Space direction="vertical">
          {/* <Paragraph>
            <Button className="noPad" type="link" onClick={onForgotPassword}>
              Forgot your password?
            </Button>
          </Paragraph> */}
          {loginError && <Paragraph type="danger">Invalid username or password.</Paragraph>}
          <Space direction="horizontal">
            <Form.Item>
              <Button htmlType="submit" type="primary" loading={loading} disabled={loading}>
                Login
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="link" onClick={onForgotPassword}>
                Forgot your password?
              </Button>
            </Form.Item>
            {/* <Form.Item>
              <Button type="link" onClick={onClose}>
                Cancel
              </Button>
            </Form.Item> */}
          </Space>
          <Paragraph className="note">
            If you're not yet a {CURRENT_YEAR} season club member, please see our{' '}
            <Link to="/membership" onClick={onClose}>
              membership page
            </Link>{' '}
            for more information and to register online.
          </Paragraph>
        </Space>
      </Form>
    </Modal>
  )
}

export default Login
