import { Card, Col } from 'antd'

import CalendarEntry from './CalendarEntry'

const CalendarMonth = ({ monthName, entries }) => {
  return (
    <Col xs={24} md={12} lg={12} xl={8} xxl={8}>
      <Card
        type="inner"
        size="small"
        className="calendarMonth"
        title={monthName}
        style={{ marginBottom: '1em', border: 'none' }}
      >
        {entries?.map((entry) => {
          return <CalendarEntry date={entry.date} time={entry.time} note={entry.note} label={entry.label} />
        })}
      </Card>
    </Col>
  )
}

export default CalendarMonth
