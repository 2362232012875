import _ from 'lodash'

export const PERM_REGISTRATIONS = 'isRegistrationAdmin'
export const PERM_JUNIOR_CAMPS = 'isJuniorCampAdmin'
export const PERM_ADULT_DOUBLES_ADMIN = 'isAdultDoublesAdmin'
export const PERM_ADULT_LESSONS_ADMIN = 'isAdultLessonsAdmin'

const ADMIN_PERMISSIONS = [PERM_ADULT_DOUBLES_ADMIN, PERM_JUNIOR_CAMPS]

export const isSuperAdmin = (currentUser) => {
  return currentUser?.isAdmin === true
}

export const hasPermission = (currentUser, permission) => {
  if (!currentUser) {
    return false
  }

  const permissionValue = _.get(currentUser, permission)
  if (_.isBoolean(permissionValue) && permissionValue === true) {
    return true
  }

  if (isSuperAdmin(currentUser)) {
    return true
  }

  return false
}

export const hasAnyAdminPermission = (currentUser) =>
  isSuperAdmin(currentUser) || _.some(ADMIN_PERMISSIONS, (perm) => hasPermission(currentUser, perm))
