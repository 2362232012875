import { Typography } from 'antd'

const { Title } = Typography

const Section = ({ title, children, level = 1, ...rest }) => {
  return (
    <div {...rest}>
      <Title level={level}>{title}</Title>
      {children}
    </div>
  )
}

export default Section
