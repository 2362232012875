import { Alert, Space } from 'antd'

const Note = ({ heading, content }) => {
  return (
    <Alert
      description={
        <>
          {heading && <b>{heading}</b>}
          {content}
        </>
      }
      type="note"
    />
  )
}

export default Note
