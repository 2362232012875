import {
  MoreOutlined as ActionsMenuIcon,
  DeleteOutlined as CancelIcon,
  EditOutlined as EditIcon,
  ClockCircleOutlined as LastMinuteIcon,
} from '@ant-design/icons'
import { Button, Col, Popover, Row, Tag, Typography } from 'antd'
import { getPlayersText, mayBeBooked, mayBeCancelled, mayBeEdited } from '../../util/bookingUtil'

import AppContext from '../common/AppContext'
import _ from 'lodash'
import { notifyInfo } from '../../util/notifyUtil'
import { useContext } from 'react'
import useResponsive from '../../hooks/useResponsive'

const { Text, Paragraph } = Typography

const getTagColour = (bookingType) => {
  switch (bookingType) {
    case 'Lesson':
      return 'cyan'
    case 'Ladder':
      return 'blue'
    case 'Tournament':
      return 'purple'
    default:
      return 'geekblue'
  }
}

const MAX_SPACER_HEIGHT = 60

const Timeslot = ({
  time,
  court,
  duration,
  booking,
  span = 1,
  timeslotSpan = 1,
  isSpacer,
  message,
  isBlocked,
  isBookedByCurrentUser,
  onAdd,
  onEdit,
  onDelete,
  heightOverride,
}) => {
  const { currentUser } = useContext(AppContext)
  const bp = useResponsive()
  const cancelAllowed = mayBeCancelled(booking, currentUser)
  const editAllowed = mayBeEdited(booking, currentUser)
  let bookingAllowed = false

  // Status is one of: available, blocked, booked, bookedByCurrentUser
  let height = duration * 1.2
  if (isSpacer && height > MAX_SPACER_HEIGHT) {
    height = MAX_SPACER_HEIGHT
  }
  if (heightOverride && heightOverride > 0) {
    height = heightOverride
  }

  let status = 'notavailable'
  if (isSpacer) {
    status = 'spacer'
  } else if (isBlocked) {
    status = 'blocked'
  } else if (booking) {
    status = isBookedByCurrentUser ? 'bookedByCurrentUser' : 'booked'
  } else if (!currentUser || mayBeBooked(time, currentUser)) {
    status = 'available'
    bookingAllowed = true
  }

  const multiCourtStyle = span > 1 || isBlocked ? 'multiCourt' : ''

  const desc = booking?.description ? (
    <Paragraph className={`bookingDescription ${multiCourtStyle}`}>{booking.description}</Paragraph>
  ) : null
  const comment =
    booking?.comment || message ? (
      <Paragraph className={`bookingComment ${multiCourtStyle}`}>{booking?.comment || message}</Paragraph>
    ) : null
  const playersStr = getPlayersText(booking)

  let ownerName = null
  if (booking?.createdByUser) {
    const owner = booking.createdByUser
    const firstName = owner.firstName
    const lastName = owner.lastName ? owner.lastName.substring(0, 1) + '.' : null
    if (!firstName && !lastName) {
      ownerName = booking.username
    } else {
      ownerName = (firstName || '') + ' ' + (lastName || '')
    }
    if (_.isEmpty(_.trim(ownerName))) {
      ownerName = booking.createdByUser.username
    }
  }

  const owner = ownerName && booking?.showOwner ? <span className="bookingOwner">{ownerName}</span> : null

  let tag = null
  if (booking && booking.bookingType !== 'General') {
    let bookingType = bp.xs ? booking.bookingType.substring(0, 1) : _.toLower(booking.bookingType)
    tag = (
      <Tag title={booking.bookingType} color={getTagColour(booking.bookingType)}>
        {bookingType}
      </Tag>
    )
  }

  const handleDelete = () => {
    if (booking && onDelete) {
      onDelete(booking)
    }
  }
  const handleEdit = () => {
    if (booking && onEdit) {
      onEdit(booking)
    }
  }
  const handleAdd = () => {
    onAdd?.(time, court)
  }

  const clickProps = bookingAllowed ? { onClick: handleAdd } : {}

  const actions = (
    <div className="bookingActions">
      {editAllowed && (
        <Button type="link" onClick={handleEdit} icon={<EditIcon className="bookingActionIcon" />} title="Edit" />
      )}
      {cancelAllowed && (
        <Button type="link" onClick={handleDelete} icon={<CancelIcon className="bookingActionIcon" />} title="Delete" />
      )}
    </div>
  )

  const lastMinuteIndicator = (
    <span className="bookingLastMinute" title="This was a last-minute booking">
      <LastMinuteIcon />
    </span>
  )

  const style = { height: height * timeslotSpan + 'px', width: `${29 * span}%` }

  const title = booking && playersStr ? `${ownerName && booking?.showOwner ? ownerName : ''}\n${playersStr}` : ''

  return (
    <div title={title} style={style} className={`bookingTimeslot ${status} ${multiCourtStyle}`} {...clickProps}>
      {booking ? (
        <>
          {booking.isLastMinute && lastMinuteIndicator}
          {owner}
          {desc || comment}
          {playersStr && <div className="bookingPlayers">{playersStr}</div>}
          {actions}
          {tag && <div className="bookingTag">{tag}</div>}
        </>
      ) : (
        message && comment
      )}
    </div>
  )
}

export default Timeslot
