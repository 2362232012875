import Section from '../common/Section'
import { Typography } from 'antd'
const { Title, Paragraph } = Typography

const CodeOfConductPage = () => {
  return (
    <>
      <Section title="Code of Conduct">
        <Paragraph>
          The following procedures and practices apply to all Hespeler Tennis Club sanctioned programming and events.
        </Paragraph>

        <Title level={3}>Fair Play</Title>
        <Paragraph>
          Players shall call all balls, double bounces, and foul shots, fairly and honestly. This includes calling out
          the score after every point, using score cards when provided, making out calls loudly or sufficiently visible
          so ones opponent is aware of the call, etc.
        </Paragraph>

        <Title level={3}>Abuse of Balls</Title>
        <Paragraph>
          Players shall not violently, dangerously, or with anger hit, kick, or throw a tennis ball while on the grounds
          except in the reasonable pursuit of a point during a match (including warm-up). For purposes of this rule,
          abuse of balls is defined as intentionally or recklessly hitting a ball out of the enclosure of the court,
          hitting a ball dangerously within the court, or hitting a ball with disregard of the consequences.
        </Paragraph>

        <Title level={3}>Abuse of Equipment</Title>
        <Paragraph>
          Players and their supporters shall not violently, dangerously, or with anger hit, kick, or throw a racquet or
          other equipment within the precincts of the courts. For the purposes of this rule, abuse of racquets or
          equipment is defined as intentionally, dangerously, and violently destroying or damaging racquets or equipment
          or intentionally or violently hitting the net, court, or other fixture out of anger or frustration.
        </Paragraph>

        <Title level={3}>Physical Abuse</Title>
        <Paragraph>
          Players and their supporters shall not at any time physically abuse any official, opponent, spectator, or
          other person within the precincts of the courts. For purposes of this rule, physical abuse refers to the
          unauthorized touching of another person.
        </Paragraph>

        <Title level={3}>Verbal Abuse</Title>
        <Paragraph>
          Players and their supporters shall not at any time verbally abuse any official, opponent, spectator, or other
          person within the precincts of the courts. Verbal abuse is defined as any statement directed at another person
          that implies dishonesty or is derogatory, insulting, or otherwise abusive.
        </Paragraph>

        <Title level={3}>Audible Obscenity</Title>
        <Paragraph>
          Players and their supporters shall not use audible obscenities while on-site. An audible obscenity is defined
          as the use of words commonly known and understood to be profane and uttered clearly and loudly enough to be
          heard.
        </Paragraph>

        <Title level={3}>Visible Obscenity</Title>
        <Paragraph>
          Players and their supporters shall not make obscene gestures while on-site. A visible obscenity is defined as
          the making of a sign with ones hands and/or racquet or balls that commonly has an obscene meaning.
        </Paragraph>

        <Title level={3}>Failure to Give Best Effort</Title>
        <Paragraph>
          All players shall use their best efforts during matches when competing in a Hespeler Tennis Club sanctioned
          event.
        </Paragraph>

        <Title level={3}>Unsportsmanlike Conduct</Title>
        <Paragraph>
          Players and their supporters shall at all times conduct themselves in a sportsmanlike manner and give due
          regard to the authority of officials and the rights of opponents, spectators, and others. Unsportsmanlike
          conduct is defined as any misconduct that is clearly abusive or detrimental to the success of a tournament,
          event, and/or the sport.
        </Paragraph>
      </Section>

      <Section title="Violations of the Code of Conduct">
        {/* Failure to abide by the aforementioned policy will result in the following consequences:
        <ul class="indented">
          <li>First and second documented and witnessed violations will result in warnings</li>
          <li>
            A third documented and witnessed violation may result in revocation of club membership (pro-rated fees will
            be refunded)
          </li>
        </ul> */}
        <Paragraph>
          To file a complaint regarding a code violation, report the incident to the convener of the event in a timely
          manner. All complaints will be handled confidentially and with the utmost discretion.
        </Paragraph>
      </Section>
    </>
  )
}

export default CodeOfConductPage
