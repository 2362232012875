import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Space, Typography } from 'antd'
import { defaultDate, defaultTime, formatDateTime, shortDateTime } from '../../util/dateTimeUtil'

import AppContext from '../common/AppContext'
import _ from 'lodash'
import { useContext } from 'react'
import { useEffect } from 'react'
import useResponsive from '../../hooks/useResponsive'
import { useWindowSize } from '../../hooks/useWindowSize'

const { Text, Paragraph, Title } = Typography

const LessonDeleteConfirmationModal = ({ booking, visible, onOk, onClose }) => {
  return (
    <Modal
      title="Cancel lesson signup"
      onOk={() => onOk(booking)}
      onCancel={onClose}
      onClose={onClose}
      okText="Yes "
      cancelText="No"
      okButtonProps={{ type: 'primary', danger: true }}
      cancelButtonProps={{ type: 'link' }}
      visible={visible}
      closable={true}
      maskClosable={true}
      keyboard={true}
      destroyOnClose={true}
    >
      <Paragraph>Are you sure you want to cancel your signup for this lesson?</Paragraph>
      <Paragraph className="note">
        <b>Note:</b> We monitor attempts to circumvent our rules, such as cancelling a lesson and then booking the same
        lesson as a last-minute signup.
      </Paragraph>
    </Modal>
  )
}

export default LessonDeleteConfirmationModal
