import { Button, Col, Row, Space, Typography } from 'antd'

import { Link } from 'react-router-dom'
import Section from '../common/Section'
import { config } from '../../util/config'

const { Paragraph, Title } = Typography
const RegistrationSection = () => {
  return (
    <>
      <Section title="Registration" level={2}>
        {config.EARLY_REGISTRATION_ENABLED && !config.REGISTRATION_ENABLED && (
          <>
            <Paragraph>Club registration for returning members and summer camp signups are now open!</Paragraph>
            <Title level={4}>Club registration</Title>
            <Paragraph>
              If you're a returning member, register using the link we emailed to you. Check your spam folder if you
              can't find it.
            </Paragraph>
            <Paragraph>
              Due to increased demand this year, we may choose to cap the number of club members, so register today.
              Registration for new club members will open on <b>April 14</b>.
            </Paragraph>
            <Paragraph>
              <Title level={4}>Summer camps</Title>
              <Link to="/juniorcamps">
                <Button type="primary">Sign up for Summer camps</Button>
              </Link>
            </Paragraph>
          </>
        )}

        {config.REGISTRATION_ENABLED && (
          <>
            <Paragraph strong>Club registration and summer camp signups are open!</Paragraph>
            <Paragraph>
              Due to increased demand this year, we may choose to cap the number of club members, so register early.
            </Paragraph>
            <Paragraph>
              <Space direction="horizontal">
                <Link to="/register">
                  <Button type="primary">Club registration</Button>
                </Link>

                <Link to="/juniorcamps">
                  <Button type="primary">Summer camps</Button>
                </Link>
              </Space>
            </Paragraph>
          </>
        )}
        {!config.REGISTRATION_ENABLED && !config.EARLY_REGISTRATION_ENABLED && (
          <>
            <Paragraph>
              Club registration and summer camp signups for the upcoming season will open on April 1.
            </Paragraph>
            <Paragraph>
              Check back here and watch your email for more information about the club and our programs for the upcoming
              season.
            </Paragraph>
            <Paragraph>
              <Link className="padRight" to="/membership">
                <Button className="padRight" type="link">
                  More details
                </Button>
              </Link>
            </Paragraph>
          </>
        )}
      </Section>
    </>
  )
}

export default RegistrationSection
