import { EmailLink } from '../common/EmailLink'
import JuniorCampsRefundPolicy from './JuniorCampsRefundPolicy'
import Section from '../common/Section'
import { Typography } from 'antd'
const { Paragraph } = Typography

const RefundPolicyPage = () => {
  return (
    <>
      <Section title="Membership Fee Refund Policy">
        <Paragraph>
          The Hespeler Tennis Club will offer a refund if injury or illness prevents a member from using their
          membership.
        </Paragraph>
        <Paragraph>Refund requests received after May 31st must be accompanied by a doctor's note.</Paragraph>
        <Paragraph>All refunds will be subject an administrative fee as outlined below:</Paragraph>
        <Paragraph style={{ fontSize: '1.1em' }}>
          <ul>
            <li>
              <b>10%</b> - Before May 31st
            </li>
            <li>
              <b>25%</b> - Before June 30th
            </li>
            <li>
              <b>50%</b> - Before July 31st
            </li>
            <li>
              <b>75%</b> - Before August 31st
            </li>
          </ul>
        </Paragraph>
        <Paragraph strong>No refunds after August 31st for any reason.</Paragraph>
        <Paragraph italic type="secondary">
          In the event that one member of a couple or family membership requests a refund, the refund amount will be
          pro-rated for that individual and will be subject to the administrative fee as outlined above.
        </Paragraph>
      </Section>

      <Section title="Junior Summer Camps (lessons) Refund Policy">
        <JuniorCampsRefundPolicy />
      </Section>
    </>
  )
}

export default RefundPolicyPage
