import Section from '../common/Section'
import { Typography } from 'antd'
const { Title } = Typography

const PoliciesPage = () => {
  return (
    <>
      <Title level={1}>Policies and Procedures - Enforcement and Consequences</Title>
      Why we enforce our Policies and Procedures:
      <ul>
        <li>To ensure the safety and enjoyment of all HTC members, staff, and volunteers</li>
        <li>To ensure players maintain a professional sportsperson attitude on the courts</li>
        <li>To maintain and enhance the image of HTC</li>
      </ul>
      <Title level={3}>Consequences of violating the HTC Policies and Procedures</Title>
      Failure to comply with the policies and procedures below may result in temporary or permanent restrictions placed
      on your membership (e.g. revocation of booking privileges, or removal from social leagues/programs), or revocation
      of membership entirely, at the sole discretion of the HTC Board.
      <Section title="Policies and Procedures">
        <Title level={2}>Court Etiquette</Title>
        <ul>
          <li>
            Players must always conduct themselves in a sportsmanlike manner. The following actions will not be
            permitted:
          </li>
          <ul>
            <li>Making derogatory, critical, or disrespectful comments to an opponent or partner</li>
            <li>Throwing a racket (in any direction or location)</li>
            <li>Intentionally hitting a ball dangerously or recklessly (in any direction or location)</li>
          </ul>
          <li>While on or near the courts, ensure your cell phone ringer volume is off or low.</li>
          <li>
            Prior to leaving the courts, ensure you have collected all belongings, garbage (including metal lids from
            fresh ball tins), and balls.
          </li>
          <li>
            Be considerate to your opponent and towards the players on neighbouring courts. This includes using a low
            voice during any on-court discussions / conversations.
          </li>
          <li>
            Always give your opponent the benefit of the doubt on close line calls. If you are not absolutely sure the
            ball was out, then you should play it as good. This includes serves.
          </li>
          <li>
            For safety reasons, if a ball or player from an adjacent court comes onto your court, you should immediately
            call a let.
          </li>
          <li>
            The rules of tennis state that a shot is considered good unless it is called out. Always call out balls,
            even if the call seems obvious. If you are standing outside the court and the ball touches your racquet
            before it bounces, the point is awarded to your opponent.
          </li>
          <li>
            The receiver must be ready before the server starts the point, on both the first and second serve. If a ball
            rolls onto your court between the first and second serve, causing an interruption, the server is entitled to
            two serves.
          </li>
          <li>When crossing behind a court, wait until play has stopped. </li>
          <li>When returning balls to another court, wait until they have finished their point.</li>
          <li>When calling for your ball from another court, wait until their point is over.</li>
          <li>
            If you are the last member leaving the courts ensure the gate is closed tightly and the lights are turned
            off.
          </li>
          <li>Tennis courts are to be used for tennis only. No other sports or activities are permitted.</li>
          <li>No animals, smoking, or alcoholic beverages are permitted on the courts or in the clubhouse.</li>
        </ul>
        <Title level={2}>Guests</Title>
        <ul>
          <li>
            Guests are welcome to HTC for no more than 3 visits, for the purpose of playing tennis (not pickleball).{' '}
          </li>
          <li>
            Court reservations must include the first and last name of all players and guests must be identified as
            such.
          </li>
        </ul>
        <Title level={2}>Dress Code</Title>
        <ul>
          <li>All members must be able to produce their current "shoe tag" as proof of membership upon request.</li>
          <li>Non-marking shoes must be worn. (Tennis shoes are highly recommended. Sandals are not permitted).</li>
          <li>Appropriate tennis attire (including a shirt) must be worn at all times. </li>
        </ul>
        <Title level={2}>Court Reservations</Title>
        <ul>
          <li>
            Courts can only be used if they have been reserved online, and only club members are permitted to book
            courts.
          </li>
          <li>
            Court reservations must include the first and last name of all players and guests must be identified as
            such.
          </li>
          <li>
            Names on court reservations must be accurate at the time of play. As applicable, last-minute changes must be
            made to ensure accuracy.
          </li>
          <li>
            Two members are not permitted to book two consecutive hours of court time together, unless one hour is a
            "last-minute booking" made within 2 hours of the court time.{' '}
          </li>
          <li>One member may play two consecutive hours of singles with different opponents.</li>
          <li>Three or four members may book two consecutive hours of court time together.</li>
        </ul>
        <Title level={4}>Consequences for breaking court reservation policies</Title>
        <ul>
          <li>
            Court reservations that are not in compliance with the policies listed above may be removed by an HTC Board
            or Staff member at any time without notification to the club member that created the reservation.
          </li>
          <li>
            Failure to comply with any of the policies and procedures listed above will result in the loss of court
            reservation privileges.
          </li>
          <li>First time offenders will be given a verbal and/or written warning.</li>
          <li>Second time offenders will have their court booking privileges revoked for 7 days.</li>
          <li>
            Third time offenders will have their court booking privileges permanently revoked for the remainder of the
            season. Following a third offense, the HTC Board will decide whether the individual’s membership will also
            be revoked or if the individual will be permitted to hit with other members for the remainder of the season.{' '}
          </li>
          <li>
            Violations may be{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.google.com/forms/d/e/1FAIpQLSctgJGQhD5RBHZgzfP_BSiabdU-SqfLUWE_bbJHItDfos3OWA/viewform?usp=sf_link"
            >
              reported by any member using the online form
            </a>{' '}
            or by scanning the QR Code on the "HTC Policies & Procedures Violations Reporting" Poster at the club house.
          </li>
        </ul>
      </Section>
    </>
  )
}

export default PoliciesPage
