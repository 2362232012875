import { Divider, Space, Switch } from 'antd'
import { getTimeslotDisplayValue, getWeekDisplayValue } from '../../util/campUtil'

import Section from '../common/Section'
import { Table } from 'ant-table-extensions'
import _ from 'lodash'
import { compareStrings } from '../../util/compareUtil'
import { defaultDateTime } from '../../util/dateTimeUtil'
import useCampSignups from '../../hooks/api/useCampSignups'
import { useState } from 'react'

const flatten = (data) => {
  if (!data) {
    return null
  }
  return _.flatMap(data, (data) => ({ id: data.id, ...data.attributes }))
}

const summarizeData = (data) => {
  if (!data) {
    return {}
  }
  let result = _.flatMap(data, (data) => ({ id: data.id, ...data.attributes }))

  let recordsByTimeslot = _.groupBy(result, (r) => r.week + '-' + r.timeslot)
  let summaryByTimeslot = []
  for (const timeslotKey in recordsByTimeslot) {
    console.log(timeslotKey)
    const count = _.size(recordsByTimeslot[timeslotKey])
    if (count > 0) {
      const firstRecord = _.first(recordsByTimeslot[timeslotKey])
      summaryByTimeslot.push({ key: timeslotKey, weekKey: firstRecord.week, timeKey: firstRecord.timeslot, count })
    }
  }

  summaryByTimeslot = _.sortBy(summaryByTimeslot, (s) => s.key)
  return { summaryByTimeslot, recordsByTimeslot }
}

const colProps = (dataIndex) => {
  return {
    sorter: (a, b) => compareStrings(_.get(a, dataIndex), _.get(b, dataIndex)),
  }
}

const AdvancedJuniorLessonSignups = () => {
  const { campSignups, loading } = useCampSignups()
  let flatData = campSignups ? _.filter(flatten(campSignups), (r) => r.timeslot === 'advanced') : null
  flatData = _.sortBy(flatData, (d) => d.week + d.timeslot)

  const allColumns = [
    { title: 'ID', dataIndex: 'id', ...colProps('id') },
    {
      title: 'Signup date/time',
      dataIndex: 'createdAt',
      ...colProps('createdAt'),
      render: (text, record) => defaultDateTime(text),
    },
    { title: 'Last name', dataIndex: 'lastName', ...colProps('lastName') },
    { title: 'First name', dataIndex: 'firstName', ...colProps('firstName') },
    { title: 'Age', dataIndex: 'age', ...colProps('age') },
    { title: 'Email', dataIndex: 'email', ...colProps('email') },
    { title: 'Emerg Contact Name', dataIndex: 'emergContactName', ...colProps('emergContactName') },
    { title: 'Emerg Contact Number', dataIndex: 'emergContactPhone', ...colProps('emergContactPhone') },
  ]

  return (
    <>
      <div style={{ overflowX: 'scroll' }}>
        <Section title="Junior Advanced lesson signups" />
        <Divider />
        {flatData && (
          <Table
            className="compactTable"
            style={{ minWidth: '121em' }}
            size="small"
            columns={allColumns}
            dataSource={flatData}
            loading={loading}
            pagination={false}
            exportable
            exportableProps={{ showColumnPicker: true, btnProps: { type: 'link' } }}
            searchable
          />
        )}
      </div>
    </>
  )
}

export default AdvancedJuniorLessonSignups
