import _ from 'lodash'

const MAX_PER_SESSION = 8 * 3

const WEEKS_TEMPLATE = [
  { key: 'week1', value: 'Week 1: July 2 - 5', note: '(short week)', isFull: false },
  { key: 'week2', value: 'Week 2: July 8 - 12', isFull: false },
  { key: 'week3', value: 'Week 3: July 15 - 19', isFull: false },
  { key: 'week4', value: 'Week 4: July 22 - 26', isFull: false },
  { key: 'week5', value: 'Week 5: July 29 - Aug 2', isFull: false },
  { key: 'week6', value: 'Week 6: August 6 - 9', note: '(short week)', isFull: false },
  { key: 'week7', value: 'Week 7: August 12 - 16', isFull: false },
  { key: 'week8', value: 'Week 8: August 19 - 23', isFull: false },
  // { key: 'week9', value: 'Week 9: August 26 - 30', isFull: false },
]

const TIMES_TEMPLATE = [
  { key: 'early', value: '9:00 - 10:30am', note: '' },
  { key: 'late', value: '10:45 - 12:15pm', note: '' },
  { key: 'full', value: '9:00 - 12:15pm', note: '' },
]

export const getTimeslotDisplayValue = (timeKey) =>
  _.find(TIMES_TEMPLATE, (t) => t.key === timeKey)?.value || `Unknown (${timeKey})`
export const getWeekDisplayValue = (weekKey) =>
  _.find(WEEKS_TEMPLATE, (w) => w.key === weekKey)?.value || `Unknown (${weekKey})`

export const getDates = (stats) => {
  const result = []

  for (const week of WEEKS_TEMPLATE) {
    const timesForWeek = _.filter(stats, (entry) => entry.weekKey === week.key)
    const isEarlyFull = _.find(timesForWeek, (t) => t.timeKey === 'early')?.isFull || false
    const isLateFull = _.find(timesForWeek, (t) => t.timeKey === 'late')?.isFull || false
    const isFullFull = _.find(timesForWeek, (t) => t.timeKey === 'full')?.isFull || false
    const isFull = week.isFull || (isEarlyFull && isLateFull && isFullFull)
    result.push({ ...week, disabled: isFull })
  }

  return result
}

export const getTimes = (weekKey, stats) => {
  const result = []

  for (const time of TIMES_TEMPLATE) {
    const timeslotStats = _.find(stats, (entry) => entry.weekKey === weekKey && entry.timeKey === time.key)
    const spotsRemaining = timeslotStats ? timeslotStats.spotsRemaining : MAX_PER_SESSION
    const spotsRemainingNote = spotsRemaining === 1 ? ' spot available' : ' spots available'
    const isFull = timeslotStats ? timeslotStats.isFull : false
    const note = `${time.note}  ${isFull ? '(full)' : '(' + spotsRemaining + spotsRemainingNote + ')'}`
    result.push({ ...time, disabled: isFull, note })
  }

  return result
}
