import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, from } from '@apollo/client'

import { RetryLink } from '@apollo/client/link/retry'
import { config } from '../util/config'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    )

  if (networkError) console.log(`[Network error]: ${networkError}`)
  forward(operation)
})

const publicToken =
  '1db64352335204ab7f18c2a3d37f1abb7f8d52ba5c32995ef1eeb10aced58ec96662c48f138bc48636bf741fcf4ebc49044347cc381b83341018b5811f72f28ddb7312a9398431f8f3f16785b0ce308bd3dd2d34c1c2ac369101a64cbb071b61b2115c30648188429b3c778d93b702407b72d9a2f409385c9b6d0fcf14f46c8e'

const authLink = setContext((_, { headers }) => {
  // Get the user's authentication token from local storage (if logged-in).
  // If not logged-in, use the default token for readonly access.
  // const token = null // localStorage.getItem('authToken') || publicToken
  const token = localStorage.getItem('authToken')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => {
      return error?.statusCode !== 400
    },
  },
})

const cache = new InMemoryCache({
  typePolicies: {},
})

const timeStartLink = new ApolloLink((operation, forward) => {
  operation.setContext({ start: new Date() })
  return forward(operation)
})

const logTimeLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    // data from a previous link
    const time = new Date() - operation.getContext().start
    console.log(`GraphQL operation '${operation.operationName}' took ${time}ms to complete`)
    return data
  })
})

const httpLink = new HttpLink({ uri: config.API_GRAPHQL_ENDPOINT })
const link = from([retryLink, timeStartLink, logTimeLink, errorLink, authLink, httpLink])

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-first',
  },
  query: {
    fetchPolicy: 'cache-first',
  },
  mutate: {},
}

export const client = new ApolloClient({
  defaultOptions,
  link,
  cache,
  connectToDevTools: config.ENABLE_APOLLO_DEVTOOLS,
})
