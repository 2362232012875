import { Button, Col, Row, Typography } from 'antd'

import { Link } from 'react-router-dom'
import Section from '../common/Section'

const { Paragraph } = Typography

const LaddersSection = () => {
  return (
    <>
      <Section title="Singles and Doubles Ladders" level={2}>
        <Paragraph>
          Our co-ed ladders are open to club members of all skill levels.{' '}
          <Link className="padRight" to="/ladder">
            <Button className="padRight" type="link">
              More details
            </Button>
          </Link>
        </Paragraph>
        <Paragraph>
          <Button
            className="padRight"
            target="_blank"
            href="https://app.tennisrungs.com/hespeler-tennis-club/tennis-ladders/122440318"
            type="link"
          >
            Ladder standings and results
          </Button>
        </Paragraph>
      </Section>
    </>
  )
}

export default LaddersSection
