import { Alert, Button, Divider, Steps, Typography } from 'antd'
import { gql, useMutation } from '@apollo/client'
import { notifyError, notifySuccess, notifyWarn } from '../../util/notifyUtil'
import { useEffect, useState } from 'react'

import { EmailLink } from '../common/EmailLink'
import Json from '../common/Json'
import RegisterStep1Details from './RegisterStep1Details'
import RegisterStep2AddMembers from './RegisterStep2AddMembers'
import RegisterStep2Details from './RegisterStep2AddMembers'
import RegisterStep3Summary from './RegisterStep3Summary'
import RegisterStep4Pay from './RegisterStep4Pay'
import RegisterStep5Done from './RegisterStep5Done'
import RegistrationSummary from './RegistrationSummary'
import Section from '../common/Section'
import _ from 'lodash'
import { config } from '../../util/config'
import useQueryParams from '../../hooks/useQueryParams'

const { Step } = Steps
const { Paragraph, Text, Title } = Typography

const RegisterPage = () => {
  const [current, setCurrent] = useState(0)
  const [invalidCode, setInvalidCode] = useState(false)
  const [registration, setRegistration] = useState({ members: [] })
  const queryParams = useQueryParams()

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  useEffect(() => {
    const isSkipPayment = queryParams.get('c') === config.SKIP_PAYMENT_CODE
    var isReturning = false
    if (queryParams.get('r')) {
      if (queryParams.get('r') === config.EARLY_REGISTRATION_CODE) {
        isReturning = true
      } else {
        setInvalidCode(true)
      }
    }
    setRegistration((r) => ({ ...r, isReturning, isSkipPayment }))
  }, [queryParams])

  if (invalidCode) {
    return (
      <>
        <Title>Registration</Title>
        <Alert
          type="error"
          description={
            <>
              Sorry, the registration code you've provided is not valid. Please{' '}
              <EmailLink email="webadmin">contact us</EmailLink> for assistance.
            </>
          }
          style={{ marginBottom: '2em' }}
        />
      </>
    )
  }

  let allowRegister = config.REGISTRATION_ENABLED || registration.isSkipPayment
  if (!config.REGISTRATION_ENABLED) {
    allowRegister = registration.isReturning
  }

  if (!allowRegister) {
    return (
      <>
        <Title>Registration</Title>
        <Paragraph>
          Registration is closed for this season. Registration for the upcoming season will open in the spring.
        </Paragraph>
      </>
    )
  }

  return (
    <>
      {/* <Title level={2}>Club Registration</Title> */}

      <div className="registrationSteps">
        <Steps current={current} className="registrationSteps" size="small">
          <Step key="details" title="Registration details" />
          <Step key="add-members" title="Member info" />
          <Step key="review" title="Review & Pay" />
          {/* <Step key="pay" title="Pay" /> */}
          <Step key="done" title="Done" />
        </Steps>
      </div>

      {registration?.isSkipPayment && current < 3 && (
        <Alert
          type="warning"
          message="Non-PayPal registration"
          description="When registration is complete, you won't be prompted to pay via PayPal and will need to send an e-transfer or cheque instead."
          style={{ marginBottom: '2em' }}
        />
      )}

      {current === 0 && (
        <RegisterStep1Details registration={registration} setRegistration={setRegistration} onNext={next} />
      )}
      {current === 1 && (
        <RegisterStep2AddMembers
          registration={registration}
          setRegistration={setRegistration}
          onPrevious={prev}
          onNext={next}
        />
      )}
      {current === 2 && (
        <RegisterStep3Summary
          registration={registration}
          setRegistration={setRegistration}
          onPrevious={prev}
          onNext={next}
        />
      )}
      {current === 3 && <RegisterStep4Pay registration={registration} />}
      {current === 4 && <RegisterStep5Done setRegistration={setRegistration} registration={registration} />}

      {/* <div className="steps-action">
        {current > 0 && current < STEPS_COUNT - 1 && (
          <Button style={{ marginRight: '10px' }} onClick={() => prev()}>
            Previous
          </Button>
        )}
        {current < STEPS_COUNT - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
      </div> */}
    </>
  )
}

export default RegisterPage
