import { JUNIOR_LESSONS_END, JUNIOR_LESSONS_START } from '../../constants'

import { EmailLink } from '../common/EmailLink'
import Section from '../common/Section'
import { Typography } from 'antd'
const { Paragraph, Title, Text } = Typography

const JuniorEveningLessons = () => {
  return (
    <>
      <Section title="Junior After-School and Lessons">
        <Paragraph>After-school spring lessons are offered free with a junior or family membership.</Paragraph>
        <Paragraph>
          One lesson per week for 6 weeks is offered to all Junior members (aged 5-17) free of charge.
        </Paragraph>
        <Paragraph>
          Lessons will run the week of {JUNIOR_LESSONS_START} through {JUNIOR_LESSONS_END} on{' '}
          <b>Tuesdays, Wednesdays, and Thursdays</b> from <b>4:30-5:30pm</b> or <b>5:30-6:30pm</b>
        </Paragraph>
        <Paragraph>
          The preferred day of week and time slot for after-school spring lessons must be selected during the
          registration process. If you have been unable to select your preferred day of week or time slot then please
          contact the <EmailLink email="juniorclub">Junior Club Director</EmailLink>.
        </Paragraph>
      </Section>
    </>
  )
}

export default JuniorEveningLessons
