import { EmailLink } from '../common/EmailLink'
import { Typography } from 'antd'

const { Paragraph } = Typography
const JuniorCampsRefundPolicy = () => {
  return (
    <Paragraph>
      <ul>
        <li>No refunds will be provided for juniors wishing to cancel a summer camp signup.</li>
        <li>No swapping of weeks will be allowed for summer camp signups.</li>
        <li>
          In the event of extenuating circumstances please contact the{' '}
          <EmailLink email="juniorclub">Junior Membership Director</EmailLink> to discuss options.
        </li>
      </ul>
    </Paragraph>
  )
}

export default JuniorCampsRefundPolicy
