import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Space, Typography } from 'antd'
import { defaultDate, defaultTime, formatDateTime, shortDateTime } from '../../util/dateTimeUtil'

import AppContext from '../common/AppContext'
import _ from 'lodash'
import { useContext } from 'react'
import { useEffect } from 'react'
import useResponsive from '../../hooks/useResponsive'
import { useWindowSize } from '../../hooks/useWindowSize'

const { Text, Paragraph, Title } = Typography

const BookingMessageModal = ({ title, message, visible, onOk, onClose }) => {
  const { currentUser } = useContext(AppContext)

  return (
    <Modal
      title={title}
      onOk={onOk}
      onCancel={onClose}
      onClose={onClose}
      footer={[
        <Button key="ok" type="primary" onClick={onOk}>
          Ok
        </Button>,
      ]}
      cancelText={''}
      visible={visible}
      closable={true}
      maskClosable={true}
      keyboard={true}
      destroyOnClose={true}
    >
      {message}
    </Modal>
  )
}

export default BookingMessageModal
