import { Alert, Typography } from 'antd'

const { Paragraph } = Typography

export const InvalidTimezone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
  return (
    <Alert
      type="error"
      message="Invalid timezone"
      description={
        <>
          <Paragraph>
            Sorry, this functionality is disabled because you browser or device is set to a different timezone than that
            of the Hespeler Tennis Club.
          </Paragraph>
          <Paragraph>
            Your current timezone is <i>{timeZone}</i>. To enable this functionality, please correct your timezone or
            temporarily set it to <i>America/Toronto</i>
          </Paragraph>
        </>
      }
    />
  )
}
