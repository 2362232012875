import { Menu as AntMenu, Button, Col, Dropdown } from 'antd'
import {
  CalendarOutlined,
  EnvironmentOutlined,
  ExclamationCircleOutlined,
  HomeTwoTone,
  InfoCircleOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import {
  PERM_ADULT_DOUBLES_ADMIN,
  PERM_ADULT_LESSONS_ADMIN,
  PERM_JUNIOR_CAMPS,
  PERM_REGISTRATIONS,
  hasAnyAdminPermission,
  hasPermission,
} from '../../util/authUtil'

import AppContext from './AppContext'
import { Link } from 'react-router-dom'
import { notifyInfo } from '../../util/notifyUtil'
import { useContext } from 'react'
import useResponsive from '../../hooks/useResponsive'

const linkMenuItem = (label, shortLabel, Icon, link, bp) => {
  label = bp.xs ? shortLabel || label : label

  return (
    <Link to={link}>
      <Button icon={Icon && <Icon className="menuIcon" />} type="link" className={bp.xs ? 'iconOnly' : ''}>
        {label}
        {/* {bp.xs && Icon ? "" : label} */}
      </Button>
    </Link>
  )
}

const Menu = () => {
  const bp = useResponsive()
  const { currentUser } = useContext(AppContext)

  const dropdownProps = { trigger: ['click'] }

  const showAdminMenu = hasAnyAdminPermission(currentUser)

  const aboutMenu = (
    <AntMenu selectable={false} id="search-menu">
      <AntMenu.Item key="home">
        <Link to="/">Home</Link>
      </AntMenu.Item>
      <AntMenu.Divider />
      <AntMenu.Item key="covid">
        <Link to="/covid">COVID-19</Link>
      </AntMenu.Item>
      <AntMenu.Item key="faq">
        <Link to="/faq">Frequently asked questions</Link>
      </AntMenu.Item>
      <AntMenu.Item key="notforprofit">
        <Link to="/incorporation">Not-for-profit incorporation</Link>
      </AntMenu.Item>
      <AntMenu.Divider />
      <AntMenu.ItemGroup key="rules" title="Rules and regulations">
        <AntMenu.Item key="policies">
          <Link to="/policies">Policies and procedures</Link>
        </AntMenu.Item>
        <AntMenu.Item key="code-of-conduct">
          <Link to="/code-of-conduct">Code of conduct</Link>
        </AntMenu.Item>
        <AntMenu.Item key="refund-policy">
          <Link to="/refund-policy">Refund policy</Link>
        </AntMenu.Item>
      </AntMenu.ItemGroup>
      <AntMenu.Divider />
      <AntMenu.ItemGroup key="people" title="Staff and volunteers">
        <AntMenu.Item key="directors">
          <Link to="/directors">Board of directors / Coordinators</Link>
        </AntMenu.Item>
        <AntMenu.Item key="coaching">
          <Link to="/coaching">Coaching staff</Link>
        </AntMenu.Item>
      </AntMenu.ItemGroup>
      {showAdminMenu && (
        <>
          <AntMenu.Divider />
          <AntMenu.ItemGroup key="admin" title="Admin">
            {hasPermission(currentUser, PERM_REGISTRATIONS) && (
              <AntMenu.Item key="registrations">
                <Link to="/admin/registrations">Registrations</Link>
              </AntMenu.Item>
            )}
            {hasPermission(currentUser, PERM_REGISTRATIONS) && (
              <AntMenu.Item key="users">
                <Link to="/admin/users">Users</Link>
              </AntMenu.Item>
            )}
            {hasPermission(currentUser, PERM_JUNIOR_CAMPS) && (
              <AntMenu.Item key="camp-signups">
                <Link to="/admin/camp-signups">Camp signups</Link>
              </AntMenu.Item>
            )}
            {hasPermission(currentUser, PERM_JUNIOR_CAMPS) && (
              <AntMenu.Item key="adv-camp-signups">
                <Link to="/admin/adv-camp-signups">Junior advanced lesson signups</Link>
              </AntMenu.Item>
            )}
            {hasPermission(currentUser, PERM_ADULT_LESSONS_ADMIN) && (
              <AntMenu.Item key="adult-lesson-signups">
                <Link to="/admin/adult-lesson-signups">Adult lesson signups</Link>
              </AntMenu.Item>
            )}
            {hasPermission(currentUser, PERM_ADULT_DOUBLES_ADMIN) && (
              <AntMenu.Item key="adult-doubles-signups">
                <Link to="/admin/adult-doubles-signups">Adult doubles signups</Link>
              </AntMenu.Item>
            )}
          </AntMenu.ItemGroup>
        </>
      )}
    </AntMenu>
  )

  const programsMenu = (
    <AntMenu selectable={false} id="programs">
      <AntMenu.ItemGroup key="juniors" title="Juniors">
        <AntMenu.Item key="juniorlessons">
          <Link to="/juniorlessons">Spring lessons</Link>
        </AntMenu.Item>
        <AntMenu.Item key="juniorcamps">
          <Link to="/juniorcamps">Summer camps</Link>
        </AntMenu.Item>
        <AntMenu.Item key="junioradvancedlessons">
          <Link to="/junioradvancedlessons">Advanced junior lessons</Link>
        </AntMenu.Item>
        {/* <AntMenu.Item key="juniordropins">
          <Link to="/juniordropins">Weekly drop-Ins</Link>
        </AntMenu.Item> */}
      </AntMenu.ItemGroup>
      <AntMenu.Divider />
      <AntMenu.ItemGroup key="adults" title="Adults">
        <AntMenu.Item key="social-doubles">
          <Link to="/adult-doubles">Adult social doubles</Link>
        </AntMenu.Item>
        <AntMenu.Item key="ladders">
          <Link to="/ladder">Ladders</Link>
        </AntMenu.Item>
        <AntMenu.Item key="lessons">
          <Link to="/lessons">Lessons</Link>
        </AntMenu.Item>
        {/* <AntMenu.Item key="dropins">
          <Link to="/dropins">Weekly drop-Ins</Link>
        </AntMenu.Item> */}
        <AntMenu.Item key="interurban">
          <Link to="/interurban">Interurban</Link>
        </AntMenu.Item>
      </AntMenu.ItemGroup>
    </AntMenu>
  )

  const calendarMenu = (
    <AntMenu selectable={false} id="calendar">
      <AntMenu.Item key="calendar">
        <Link to="/calendar">Club calendar</Link>
      </AntMenu.Item>
      <AntMenu.Item key="reserve">
        <Link to="/courtbooking">Book a court</Link>
      </AntMenu.Item>
    </AntMenu>
  )

  const aboutDropdown = (
    <Dropdown overlay={aboutMenu} {...dropdownProps}>
      <Button icon={<InfoCircleOutlined className="menuIcon" />} type="link">
        About
      </Button>
    </Dropdown>
  )
  const calendarDropdown = (
    <Dropdown overlay={calendarMenu} {...dropdownProps}>
      <Button icon={<CalendarOutlined className="menuIcon" />} type="link">
        {bp.xsOrSm ? 'Calendar' : 'Calendar & Events'}
      </Button>
    </Dropdown>
  )

  const programsDropdown = (
    <Dropdown overlay={programsMenu} {...dropdownProps}>
      <Button icon={<UnorderedListOutlined className="menuIcon" />} type="link">
        {bp.xs ? 'Programs' : 'Programs'}
      </Button>
    </Dropdown>
  )

  return (
    <Col className="menuItem">
      {aboutDropdown}
      {calendarDropdown}
      {programsDropdown}
      {linkMenuItem('Membership', null, TeamOutlined, '/membership', bp)}
      {linkMenuItem('Contact', '', EnvironmentOutlined, '/contact', bp)}
      {/* {hasPermission(currentUser, 'admin') ? adminDropdown : null} */}
    </Col>
  )
}

export default Menu
