import { Button } from 'antd'

export const EmailLink = ({ email, children, ...props }) => {
  const emailAddr = `${email}@hespelertennis.on.ca`
  return (
    <Button className="noPad" type="link" href={`mailto:${emailAddr}`} {...props}>
      {children || emailAddr}
    </Button>
  )
}
