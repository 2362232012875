import { isAxiosError, isGraphQLError, transformServerError } from './errorUtil'

import _ from 'lodash'
import { notification } from 'antd'

const DEFAULT_ERROR_NOTIFICATION_HEADING = 'An error occurred'

export const transformMessage = (message, description) => {
  message = message || description
  let result
  if (_.isNil(message)) {
    return null
  } else if (_.isString(message)) {
    result = { message, description }
  } else if (isAxiosError(message)) {
    result = {
      message: DEFAULT_ERROR_NOTIFICATION_HEADING,
      ...transformServerError(message),
    }
  } else if (isGraphQLError(message)) {
    result = {
      message: DEFAULT_ERROR_NOTIFICATION_HEADING,
      ...transformServerError(message),
    }
  } else if (message.message) {
    // Message is a suitable notification config.
    result = message
  } else {
    // Message was some object that wasn't a notification config.
    result = { description: JSON.stringify(message) }
  }
  return result
}

const notify = (key, fn, message, description, duration = 3, overrides = {}) => {
  if (!_.isString(key) || (_.isString(key) && _.isNil(message || description))) {
    throw new Error('Notify call without a message key')
  }
  const defaultMsg = transformMessage(message, description)
  if (!_.isNil(defaultMsg)) {
    defaultMsg.duration = duration
    defaultMsg.key = key
    const msg = { ...defaultMsg, ..._.get(overrides, defaultMsg.code) }
    fn = fn || notification.info
    if (key) {
      notification.close(key)
    }
    // fn(msg)
    fn({ onClick: () => notification.close(key), ...msg })
    return msg
  }
}

export const notifyError = (key, msg, desc, overrides, duration = 13) => {
  console.error(msg)
  return notify(key, notification.error, msg, desc, duration, overrides)
}
export const notifyWarn = (key, msg, desc, duration = 7) => {
  console.warn(msg)
  return notify(key, notification.warn, msg, desc, duration)
}

export const notifyInfo = (key, msg, desc, duration = 5) => {
  console.info(msg)
  return notify(key, notification.info, msg, desc, duration)
}

export const notifySuccess = (key, msg, desc, duration = 2) => {
  console.info(msg, desc)
  return notify(key, notification.success, msg, desc, duration)
}
export const notifySaveSuccess = (msg) => {
  return notification.success({
    key: 'save',
    className: 'ant-notification-notice-saveSuccess',
    closeIcon: ' ',
    message: msg || 'Saved',
    duration: 2,
  })
}

export const notifyFormValidationErrors = () =>
  notifyWarn(
    'form-warnings-save-denied',
    'Missing or invalid form data',
    'Please fill out all required fields and fix any validation errors before saving.',
  )

export const hideAllNotifications = () => notification.destroy()
export const hideNotification = (key) => notification.close(key)

export const notifyNotImplemented = () => notifyInfo('not-implemented', 'Not implemented yet')
