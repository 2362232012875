import { Button, Form, Select, Typography } from 'antd'

import { InfoCircleOutlined as InfoIcon } from '@ant-design/icons'

const { Text } = Typography

const required = [{ whitespace: true, required: true, message: '' }]

const TimeSelectInput = ({ label = 'Time', name = 'time', times, ...props }) => {
  return (
    <Form.Item label={label} name={name} rules={required}>
      <Select dropdownMatchSelectWidth={false} {...props}>
        {times?.map(({ key, value, disabled, note }) => (
          <Select.Option key={key} disabled={disabled}>
            {value}
            <Text italic type="secondary" style={{ paddingLeft: '1em' }}>
              {note}
            </Text>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default TimeSelectInput
