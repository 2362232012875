import { add, getDay, getHours, intervalToDuration, isSameMinute, parseISO, set } from 'date-fns'

import AppContext from '../common/AppContext'
import BookingTime from './BookingTime'
import { Row } from 'antd'
import Timeslot from './Timeslot'
import _ from 'lodash'
import { getStartTimeAsDate } from '../../util/bookingUtil'
import { useContext } from 'react'

const BookingRow = ({
  time,
  duration,
  heightOverride,
  message,
  span = 3,
  nextTime,
  bookings,
  onAdd,
  onEdit,
  onDelete,
}) => {
  const { currentUser } = useContext(AppContext)
  const timeslotEnd = add(time, { minutes: duration })
  const durUntilNextTimeslot = nextTime ? intervalToDuration({ start: timeslotEnd, end: nextTime }) : null
  let minUntilNextTimeslot = 0
  if (nextTime) {
    const { minutes, hours } = durUntilNextTimeslot
    minUntilNextTimeslot = hours * 60 + minutes
  }

  let court1Timeslot = <Timeslot time={time} duration={duration} onAdd={onAdd} court={1} />
  let court2Timeslot = <Timeslot time={time} duration={duration} onAdd={onAdd} court={2} />
  let court3Timeslot = <Timeslot time={time} duration={duration} onAdd={onAdd} court={3} />

  if (message) {
    court1Timeslot = (
      <Timeslot
        time={time}
        duration={duration}
        onAdd={onAdd}
        court={1}
        span={span}
        message={message}
        isBlocked={true}
        heightOverride={heightOverride}
      />
    )
    const timeslotProps = {
      time,
      duration,
      onAdd,
      heightOverride,
    }
    court2Timeslot = <Timeslot {...timeslotProps} court={2} span={1} />
    court3Timeslot = <Timeslot {...timeslotProps} court={3} span={1} />
    if (span >= 2) {
      court2Timeslot = null
    }
    if (span >= 3) {
      court3Timeslot = null
    }
    // } else if (!_.isEmpty(bookings)) {
  }
  if (!_.isEmpty(bookings)) {
    const bookingsForTimeslot = _.filter(bookings, (b) => {
      return isSameMinute(getStartTimeAsDate(b), time)
    })
    if (!_.isEmpty(bookingsForTimeslot)) {
      const temp = _.mapKeys(bookingsForTimeslot, (b) => {
        return _.join(
          _.map(b.courts, (c) => c.id),
          '+',
        )
      })

      const keys = _.sortBy(_.keys(temp), (k) => k)

      _.forEach(keys, (origCourtCode) => {
        const booking = temp[origCourtCode]
        // Ensure the court code components are sorted
        const courtCode = _.join(
          _.sortBy(_.split(origCourtCode, '+'), (k) => k),
          '+',
        )

        const isBookedByCurrentUser = currentUser ? currentUser.id === booking.createdByUser.id : false
        const timeslotProps = {
          time,
          duration,
          booking,
          isBookedByCurrentUser,
          onEdit,
          onDelete,
          heightOverride,
        }
        const timeslot = <Timeslot {...timeslotProps} />

        if (courtCode === '1') {
          court1Timeslot = timeslot
        } else if (courtCode === '2') {
          court2Timeslot = timeslot
        } else if (courtCode === '3') {
          court3Timeslot = timeslot
        } else if (courtCode === '1+2') {
          court1Timeslot = <Timeslot {...timeslotProps} span={2} />
          court2Timeslot = null
        } else if (courtCode === '2+3') {
          court2Timeslot = <Timeslot {...timeslotProps} span={2} />
          court3Timeslot = null
        } else if (courtCode === '1+2+3') {
          court1Timeslot = <Timeslot {...timeslotProps} span={3} />
          court2Timeslot = null
          court3Timeslot = null
        } else if (courtCode === '1+3') {
          court1Timeslot = timeslot
          court3Timeslot = timeslot
        } else {
          console.log(`Unhandled court booking combination: '${courtCode}'`)
        }
      })
    }
  }

  return (
    <>
      <div className="bookingRow">
        <BookingTime time={time} />
        {court1Timeslot}
        {court2Timeslot}
        {court3Timeslot}
      </div>
      {minUntilNextTimeslot > 1 && (
        <div className="bookingRow">
          <BookingTime time={timeslotEnd} />
          <Timeslot time={timeslotEnd} duration={minUntilNextTimeslot} isSpacer={true} court={1} span={3} />
        </div>
      )}
    </>
  )
}

export default BookingRow
