import { Button, Divider, Table, Tooltip, Typography } from 'antd'
import { DeleteOutlined as DeleteIcon, EditOutlined as EditIcon } from '@ant-design/icons'

import Json from '../common/Json'
import _ from 'lodash'

const { Title, Paragraph } = Typography

const RegistrationSummary = ({ registration, setRegistration, editMember, selectedMemberIndex, editable = true }) => {
  const removeMember = (i) => {
    const members = [...registration.members.slice(0, i), ...registration.members.slice(i + 1)]
    const newRegistration = { ...registration, members }
    setRegistration(newRegistration)
  }

  const getRowClassName = (record, index) => {
    return index === selectedMemberIndex ? 'registrationMembersTableSelectedRow' : ''
  }

  const actionsCol = {
    key: 'actions',
    width: '5em',
    render: (text, record, index) => {
      return (
        <span style={{ whiteSpace: 'nowrap' }}>
          <Tooltip title="Edit" placement="left">
            <Button size="small" icon={<EditIcon />} type="link" onClick={() => editMember(index)} />
          </Tooltip>
          <Tooltip title="Remove" placement="right">
            <Button size="small" icon={<DeleteIcon />} type="link" onClick={() => removeMember(index)} />
          </Tooltip>
        </span>
      )
    },
  }
  const columns = [
    { title: 'Name', ellipsis: true, render: (text, record) => `${record.firstName} ${record.lastName}` },
    { title: 'Username', dataIndex: 'username', ellipsis: true, render: (text, record) => text || '(none)' },
    {
      title: 'Contact',
      dataIndex: 'email',
      responsive: ['md'],
      ellipsis: true,
      render: (text, record) => (
        <>
          {record.email}
          <br />
          {record.phone}
        </>
      ),
    },
    { title: 'Level', dataIndex: 'skill', width: '5em' },
    { title: 'Age group', dataIndex: 'ageGroup', width: '8em', render: (text, record) => _.capitalize(text) },
    ...(editable ? [actionsCol] : []),
  ]
  return (
    <>
      {/* <Title level={3}>Members</Title> */}
      <Paragraph>
        {/* <Json object={registration} /> */}
        {_.size(registration?.members) > 0 && (
          <Table
            className="registrationMembersTable"
            columns={columns}
            dataSource={registration?.members}
            size="small"
            pagination={false}
            rowClassName={getRowClassName}
          />
        )}
      </Paragraph>
    </>
  )
}

export default RegistrationSummary
