import { Button, Col, Row, Typography } from 'antd'

import { Link } from 'react-router-dom'
import Section from '../common/Section'

const { Paragraph, Text } = Typography
const CourtBookingsSection = () => {
  return (
    <>
      <Section title="Court Bookings" level={2}>
        <Paragraph>
          <Text>All court time must be booked using our court bookings system.</Text>
        </Paragraph>
        <Paragraph>
          <Link to="/courtbooking" style={{ verticalAlign: 'text-bottom' }}>
            <Button color="green" type="primary">
              Book a court
            </Button>
          </Link>
        </Paragraph>
      </Section>
    </>
  )
}

export default CourtBookingsSection
