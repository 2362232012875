import { Collapse, Switch } from 'antd'

import Section from '../common/Section'
import { Table } from 'ant-table-extensions'
import _ from 'lodash'
import { compareStrings } from '../../util/compareUtil'
import { defaultDateTime } from '../../util/dateTimeUtil'
import { useState } from 'react'
import useUsers from '../../hooks/api/useUsers'

const colProps = (dataIndex) => {
  return {
    sorter: (a, b) => compareStrings(_.get(a, dataIndex), _.get(b, dataIndex)),
  }
}

const UserListPage = () => {
  let { users, loading } = useUsers()
  users = _.map(users, (u) => ({
    ...u,
    registrationNumber: u.registration?.registrationNumber,
    registrationLastname: u.registration?.lastName,
    membershipType: u.registration?.membershipType,
    username: _.startsWith(u.username, 'unset-') ? '(none)' : u.username,
  }))
  const [juniorLessonsOnly, setJuniorlessonsOnly] = useState(false)

  const data = juniorLessonsOnly ? _.filter(users, (u) => !_.isNil(u.juniorLessonsDay)) : users

  const columns = [
    { title: 'Row', width: '3em', dataIndex: 'id', render: (value, record, index) => <>{index + 1}</> },
    { title: 'ID', width: '3em', dataIndex: 'id', ...colProps('id') },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      width: '12em',
      ...colProps('createdAt'),
      render: (text, record) => defaultDateTime(text),
    },
    { title: 'Reg #', width: '10em', dataIndex: 'registrationNumber', ...colProps('registrationNumber') },
    { title: 'Reg Lastname', width: '12em', dataIndex: 'registrationLastname', ...colProps('registrationLastname') },
    { title: 'Membership', width: '8em', dataIndex: 'membershipType', ...colProps('membershipType') },
    { title: 'Last name', width: '15em', dataIndex: 'lastName', ...colProps('lastName') },
    { title: 'First name', width: '15em', dataIndex: 'firstName', ...colProps('firstName') },
    { title: 'Username', width: '20em', dataIndex: 'username', ...colProps('username') },
    { title: 'Age group', width: '7em', dataIndex: 'ageGroup', ...colProps('ageGroup') },
    { title: 'Gender', width: '7em', dataIndex: 'gender', ...colProps('gender') },
    { title: 'Email', width: '20em', dataIndex: 'email', ...colProps('email') },
    { title: 'Phone', width: '9em', dataIndex: 'phone', ...colProps('phone') },
    { title: 'Skill level', width: '5em', dataIndex: 'skill', ...colProps('skill') },
    { title: 'Membership type', width: '9em', dataIndex: 'membershipType', ...colProps('membershipType') },
    { title: 'Birth year', width: '6em', dataIndex: 'birthYear', ...colProps('birthYear') },
    {
      title: 'Adult doubles?',
      width: '7em',
      dataIndex: 'interestedInAdultDoubles',
      ...colProps('interestedInAdultDoubles'),
      render: (value) => (value ? 'YES' : 'NO'),
    },
    {
      title: 'Interurban?',
      width: '7em',
      dataIndex: 'interestedInInterurban',
      ...colProps('interestedInInterurban'),
      render: (value) => (value ? 'YES' : 'NO'),
    },
    { title: 'Junior lessons day', width: '9em', dataIndex: 'juniorLessonsDay', ...colProps('juniorLessonsDay') },
    {
      title: 'Junior lessons time',
      width: '9em',
      dataIndex: 'juniorLessonsTime',
      ...colProps('juniorLessonsTime'),
      render: (text, record) => {
        if (text === '_4pm') {
          return '4:30'
        } else if (text === '_5pm') {
          return '5:30'
        } else {
          return text
        }
      },
    },
    {
      title: 'Disabled',
      width: '5em',
      dataIndex: 'blocked',
      ...colProps('blocked'),
      render: (value, record) => (value ? 'YES' : 'No'),
    },
  ]

  return (
    <Section title="Users">
      <b>Show junior lessons only?</b>{' '}
      <Switch checked={juniorLessonsOnly} onChange={() => setJuniorlessonsOnly((val) => !val)} />
      <Table
        // style={{ minWidth: '120em' }}
        className="compactTable"
        size="small"
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={false}
        exportable
        scroll={{ y: 500 }}
        // scroll={{ x: 2000, y: 600 }}
        exportableProps={{ showColumnPicker: true, btnProps: { type: 'link' } }}
        // tableLayout="auto"
        searchable
      />
    </Section>
  )
}

export default UserListPage
