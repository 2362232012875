import _ from 'lodash'
import { contains } from './stringUtil'

const ERROR_CONTACT_MSG = (
  <div style={{ marginTop: '1em' }}>
    If this problem persists, please contact{' '}
    <a href="mailto:webadmin@hespelertennis.on.ca">webadmin@hespelertennis.on.ca</a>
  </div>
)

export const checkRequiredProp = (prop, propName) => {
  if (_.isNil(prop)) {
    throw new Error(`Required prop '${propName}' was not provided`)
  }
}

export const isGraphQLError = (error) => {
  const { graphQLErrors, networkError, clientErrors } = error
  return graphQLErrors || networkError || clientErrors
}

export const isAxiosError = (error) => {
  return error?.isAxiosError === true || !_.isNil(error?.response?.data?.error)
}

export const ERRORS = Object.freeze({
  UNKNOWN: 0,
  BAD_REQUEST: 1,
  NOT_AUTHENTICATED: 2,
  NOT_AUTHORIZED: 3,
  FOREIGN_KEY_CONSTRAINT: 4,
  INVALID_GRAPHQL_QUERY: 5,
  CONCURRENT_UPDATE: 6,
  SYNCHRONIZATION_ERROR: 7,
  ENTITY_NOT_FOUND: 8,
  QUERY_ERROR: 9,
})

export const transformServerError = (error) => {
  const { graphQLErrors, networkError } = error
  const DEFAULT_ERROR = {
    code: ERRORS.UNKNOWN,
    message: 'An unknown error occurred',
    description: 'Please try again or contact technical support for assistance.',
  }
  let result
  if (isAxiosError(error)) {
    const err = error?.response?.data?.error || error?.response?.error
    if (err) {
      result =
        mapErrorMessage(err.message) ||
        mapErrorCode(err.message) ||
        mapErrorCode(err.name) ||
        mapErrorStatus(err.status)
    }
  } else if (networkError) {
    result =
      mapErrorMessage(networkError.message) ||
      mapErrorCode(networkError.message) ||
      mapErrorStatus(networkError.statusCode)
  } else if (graphQLErrors) {
    for (let err of graphQLErrors) {
      result =
        mapErrorMessage(err.message) ||
        mapErrorCode(err.extensions?.csi?.name) ||
        mapErrorCode(err.extensions?.csi?.code) ||
        mapErrorCode(err.extensions?.code)
      if (result) break
    }
  }
  return result || DEFAULT_ERROR
}

const mapErrorMessage = (message) => {
  // Right now a lot of server errors use the same error code but different
  // messages.  For now, see if we can determine what happened using the
  // message first.  In general logic should be added to mapErrorCode rather
  // than here.
  // TODO: update the server to use specific error code instead.
  if (contains(message, 'foreign key constraint fails')) {
    return {
      code: ERRORS.FOREIGN_KEY_CONSTRAINT,
      message: 'Unable to delete',
      description:
        "Couldn't delete this record because it has children that can't be automatically deleted. Delete any children first and try again.",
    }
  } else if (contains(message, 'got invalid value')) {
    return {
      code: ERRORS.INVALID_GRAPHQL_QUERY,
      description: 'Invalid GraphQL query.',
    }
  } else if (contains(message, 'adult-lessons-session-limit')) {
    return {
      code: ERRORS.NOT_AUTHORIZED,
      description: 'Sorry, this session is already full.',
    }
  } else if (contains(message, 'adult-lessons-user-signup-limit')) {
    return {
      code: ERRORS.NOT_AUTHORIZED,
      description:
        "Sorry, you've reached your maximum allowed signups.  When space allows, sign up for additional lessons using a 'last-minute' signup, i.e. within 3 days of the lesson.",
    }
  }
  return null
}

const mapErrorCode = (code) => {
  switch (code) {
    case 'USERNAME_NOT_AVAILABLE':
      return {
        code,
        message: 'Username not available',
        description: <>Sorry, this username is not available.</>,
      }

    case 'ValidationError':
      return {
        code: ERRORS.BAD_REQUEST,
        message: 'Bad request',
        description: (
          <>
            The server returned an error while trying to process a request.
            {ERROR_CONTACT_MSG}
          </>
        ),
      }
    case 'INTERNAL_SERVER_ERROR':
      return {
        code: ERRORS.UNKNOWN,
        message: 'Server error',
        description: (
          <>
            The server returned an error while trying to process a request.
            {ERROR_CONTACT_MSG}
          </>
        ),
      }
    case 'ENTITY_STALE_UPDATE':
      return {
        code: ERRORS.CONCURRENT_UPDATE,
        message: 'Unable to save',
        description:
          'Someone updated this record while you were editing it.  Click "Cancel" or refresh the page to get the current version and make your changes again.',
      }
    case 'SYNCHRONIZATION_ERROR':
      return {
        code: ERRORS.SYNCHRONIZATION_ERROR,
        message: 'Unable to save',
        description:
          'Someone may have updated this record while you were editing it.  Click "Cancel" or refresh the page to get the current version and make your changes again.',
      }
    case 'ENTITY_NOT_FOUND':
      return {
        code: ERRORS.ENTITY_NOT_FOUND,
        message: 'Not found',
        description: 'This record could not be found.',
      }
    case 'Failed to fetch':
      return {
        code: ERRORS.QUERY_ERROR,
        message: 'Server not available',
        description: (
          <>
            There was an error communicating with the server. Check your network connection and try again.
            {ERROR_CONTACT_MSG}
          </>
        ),
      }
    case 'FORGOT_PASSWORD_NO_USER':
      return {
        code,
        description: 'No member was found with that username.',
      }
    case 'FORGOT_PASSWORD_USER_DISABLED':
      return {
        code,
        description: "This user is currently disabled so you can't reset its password.",
      }
    case 'FORGOT_PASSWORD_BAD_EMAIL':
      return {
        code,
        description: "There was an error determining this user's email address.",
      }
    case 'RESET_PASSWORD_INVALID_CODE':
      return {
        code,
        description:
          'Your reset-password link is invalid or has expired.  Please request a new link using the forgot-password function.',
      }
    case 'RESET_PASSWORD_MISMATCH':
      return {
        code,
        description: "The new password and confirmation password don't match.",
      }
    case 'EXISTING_BOOKING':
      return {
        code,
        description: 'Sorry, that booking is no longer available.',
      }
    default:
      return null
  }
}

const mapErrorStatus = (status) => {
  if (status === 400) {
    return {
      code: ERRORS.BAD_REQUEST,
      message: 'Bad request',
      description: 'The server could not process the request because it was invalid.',
    }
  } else if (status === 401) {
    return {
      code: ERRORS.NOT_AUTHENTICATED,
      message: 'Not authenticated',
      description: 'You must be logged in to perform this operation.',
    }
  } else if (status === 403) {
    return {
      code: ERRORS.NOT_AUTHORIZED,
      message: 'Not authorized',
      description: "You don't have access to perform this operation.",
    }
  }
  return null
}
