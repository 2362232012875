import { Col, Typography } from 'antd'

const { Text, Paragraph, Title, Link } = Typography

const InterurbanClubInfo = ({ name, url, address, dates }) => {
  return (
    <Col style={{ padding: '1em', border: '0px solid #eee', maxWidth: '15em' }}>
      <Link href={url} target="_blank">
        {name}
      </Link>
      <br />
      <span style={{ fontSize: '.9em' }}>{address}</span>
      <br />
      <span style={{ fontSize: '.9em' }}>
        {dates && (
          <>
            <b>Match date:</b> {dates}
          </>
        )}
      </span>
    </Col>
  )
}

export default InterurbanClubInfo
