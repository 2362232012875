import { Button, Col, Row, Space, Typography } from 'antd'

import { Link } from 'react-router-dom'
import Section from '../common/Section'
import { config } from '../../util/config'

const { Paragraph, Title } = Typography
const StringingSection = () => {
  return (
    <>
      <Section title="Racquet Stringing Services" level={2}>
        <Paragraph>
          Racquet stringing services are provided to club members by coach Josh Varghese.
          <Link to="/stringing">
            <Button type="link">More details</Button>
          </Link>
        </Paragraph>
      </Section>
    </>
  )
}

export default StringingSection
