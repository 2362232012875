import { Divider, Space, Switch } from 'antd'
import { compareBools, compareStrings } from '../../util/compareUtil'
import { useEffect, useState } from 'react'

import Section from '../common/Section'
import { Table } from 'ant-table-extensions'
import _ from 'lodash'
import axios from '../../axios/axios'
import { defaultDateTime } from '../../util/dateTimeUtil'
import { notifyError } from '../../util/notifyUtil'
import useAdultDoublesSignups from '../../hooks/api/useAdultDoublesSignups'
import useAdultLessonSignups from '../../hooks/api/useAdultLessonSignups'

const colProps = (dataIndex) => {
  return {
    sorter: (a, b) => compareStrings(_.get(a, dataIndex), _.get(b, dataIndex)),
  }
}

const getSessionName = (session) => {
  switch (session) {
    case 'monday-beg+int':
      return 'Mon (beg/int)'
    case 'monday-int+adv':
      return 'Mon (int/adv)'
    case 'wednesday-int+adv':
      return 'Wed (int/adv)'
    default:
      return session
  }
}

const AdultDoublesSignupsPage = () => {
  const { signups, loading } = useAdultDoublesSignups()

  let data = _.flatMap(signups, (d) => ({ ...d.user, ...d }))
  data = _.map(data, (d) => ({ ...d, name: d.user?.firstName + ' ' + d.user?.lastName }))

  const columns = [
    { title: 'Row', width: '3em', fixed: 'left', dataIndex: 'id', render: (value, record, index) => <>{index + 1}</> },
    { title: 'ID', dataIndex: 'id', width: '3em', ...colProps('id') },
    {
      title: 'Signup date/time',
      dataIndex: 'createdAt',
      width: '20em',
      ...colProps('createdAt'),
      render: (text, record) => defaultDateTime(text),
    },
    {
      title: 'Session',
      dataIndex: 'session',
      width: '10em',
      render: (value, record) => getSessionName(value),
      sorter: {
        compare: (a, b) => compareStrings(_.get(a, 'session'), _.get(b, 'session')),
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '15em',
      ...colProps('name'),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      width: '12em',
      ...colProps('phone'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '20em',
      ...colProps('email'),
    },
    {
      title: 'Skill',
      dataIndex: 'skill',
      width: '5em',
      ...colProps('skill'),
    },
    {
      title: 'Will spare',
      dataIndex: 'willSpare',
      width: '8em',
      render: (value, record) => (value ? 'Yes' : 'No'),
      sorter: {
        compare: (a, b) => compareBools(_.get(a, 'willSpare'), _.get(b, 'willSpare')),
      },
    },
    {
      title: 'Able to switch nights',
      dataIndex: 'ableToSwitchNights',
      width: '10em',
      render: (value, record) => (value ? 'Yes' : 'No'),
      sorter: {
        compare: (a, b) => compareBools(_.get(a, 'ableToSwitchNights'), _.get(b, 'ableToSwitchNights')),
      },
    },
    {
      title: 'Able to play back-to-back',
      dataIndex: 'availableToPlayBackToBack',
      width: '10em',
      render: (value, record) => (value ? 'Yes' : 'No'),
      sorter: {
        compare: (a, b) => compareBools(_.get(a, 'availableToPlayBackToBack'), _.get(b, 'availableToPlayBackToBack')),
      },
    },
    {
      title: 'Interested in playing both nights',
      dataIndex: 'interestedInPlayingBothNights',
      width: '10em',
      render: (value, record) => (value ? 'Yes' : 'No'),
      sorter: {
        compare: (a, b) =>
          compareBools(_.get(a, 'interestedInPlayingBothNights'), _.get(b, 'interestedInPlayingBothNights')),
      },
    },
    {
      title: 'Notes/Unavailable',
      dataIndex: 'notes',
      width: '40em',
    },
  ]

  return (
    <>
      <div style={{ overflowX: 'scroll' }}>
        <Section title="Adult doubles signups" />
        <Divider />
        <Table
          className="compactTable"
          size="small"
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={false}
          exportable
          exportableProps={{ showColumnPicker: true, btnProps: { type: 'link' } }}
          searchable
        />
      </div>
    </>
  )
}

export default AdultDoublesSignupsPage
