import { APRIL, MAY } from '../constants'
import {
  add,
  differenceInCalendarDays,
  isMonday,
  isPast,
  isSameDay,
  isSunday,
  isThursday,
  isTuesday,
  isWednesday,
  parseISO,
  set,
} from 'date-fns'
import { formatDateTime, jsonDateShort } from './dateTimeUtil'

import _ from 'lodash'

const MAX_PER_SESSION = 4 * 3

const WEEKS_TEMPLATE = []

let date = set(new Date(), { month: MAY, date: 15 })
for (let i = 0; i < 18; i++) {
  const key = jsonDateShort(date)
  if (key !== '2023-08-30') {
    const value = formatDateTime(date, 'eeee MMMM d')
    WEEKS_TEMPLATE.push({ key, value })
  }
  date = add(date, { weeks: 1 })
}

const TIMES_TEMPLATE = [
  { key: 'early', value: '7:15 - 8:15pm (beginner)', note: '' },
  { key: 'late', value: '8:30 - 9:30pm (int/adv)', note: '' },
]

export const getTimeslotDisplayValue = (timeKey) =>
  _.find(TIMES_TEMPLATE, (t) => t.key === timeKey)?.value || `Unknown (${timeKey})`
export const getWeekDisplayValue = (dateKey) =>
  _.find(WEEKS_TEMPLATE, (w) => w.key === dateKey)?.value || `Unknown (${dateKey})`

export const isLastMinuteEnabledForCurrentWeek = (now) => isMonday(now) || isTuesday(now) || isWednesday(now)
export const getDates = (stats, now, enableOnlyLastminute = false) => {
  const result = []

  for (const week of WEEKS_TEMPLATE) {
    const date = parseISO(week.key)
    const daysUntilLesson = differenceInCalendarDays(date, now)
    const isLastMinute = daysUntilLesson >= 0 && daysUntilLesson < 3
    const timesForWeek = _.filter(stats, (entry) => entry.dateKey === week.key)
    const isEarlyFull = _.find(timesForWeek, (t) => t.timeKey === 'early')?.isFull || false
    const isLateFull = _.find(timesForWeek, (t) => t.timeKey === 'late')?.isFull || false
    const isWeekFull = isEarlyFull && isLateFull
    const isPastDate = !isSameDay(now, date) && isPast(date)
    const note = isLastMinute ? '(last-minute signup)' : null
    result.push({
      ...week,
      isLastMinute,
      disabled: isWeekFull || isPastDate || (enableOnlyLastminute && !isLastMinute),
      note,
    })
  }

  return result
}

export const getTimes = (dateKey, stats, now) => {
  const result = []

  for (const time of TIMES_TEMPLATE) {
    const timeslotStats = _.find(stats, (entry) => entry.dateKey === dateKey && entry.timeKey === time.key)
    const spotsRemaining = timeslotStats ? timeslotStats.spotsRemaining : MAX_PER_SESSION
    const spotsRemainingNote = spotsRemaining === 1 ? ' spot available' : ' spots available'
    const isFull = timeslotStats ? timeslotStats.isFull : false
    const note = `${time.note}  ${isFull ? '(full)' : '(' + spotsRemaining + spotsRemainingNote + ')'}`
    result.push({ ...time, disabled: isFull, note })
  }

  return result
}
