import { Button, Col, Row, Typography } from 'antd'

import { ADULT_LESSONS_START } from '../../constants'
import { Link } from 'react-router-dom'
import Section from '../common/Section'

const { Paragraph } = Typography
const AdultLessonsSection = () => {
  return (
    <>
      <Section title="Adult Group Lessons" level={2}>
        <Paragraph>
          Sign ups for the <Link to="/lessons">Wednesday night adult group lessons</Link> are now open.{' '}
          <div class="note">
            Lessons run each Wednesday starting {ADULT_LESSONS_START}, for beginners (7:15-8:15pm) and
            intermediate/advanced (8:30-9:30pm).
          </div>
        </Paragraph>
        <Paragraph>
          <Link to="/lessons">
            <Button>Sign up</Button>
          </Link>
        </Paragraph>
      </Section>
    </>
  )
}

export default AdultLessonsSection
