import { Button, Col, Form, Input, Row, Select, Typography } from 'antd'
import { hideAllNotifications, notifyError } from '../../util/notifyUtil'
import { useContext, useState } from 'react'

import AppContext from '../common/AppContext'
import Section from '../common/Section'
import SkillLevelInput from '../common/SkillLevelInput'
import _ from 'lodash'
import axios from '../../axios/axios'
import { transformServerError } from '../../util/errorUtil'

const colProps = { xs: 24, sm: 12, lg: 10, xl: 8 }
const required = [{ whitespace: true, required: true, message: '' }]

const usernameRule = [
  {
    whitespace: true,
    min: 3,
    message: 'Username must be at least 3 characters',
  },
]

const ProfilePage = () => {
  const { currentUser, setCurrentUser } = useContext(AppContext)
  const [form] = Form.useForm()
  const [saving, setSaving] = useState(false)
  const [editing, setEditing] = useState()

  const validateUsername = async (username) => {
    try {
      hideAllNotifications()
      // Check the server
      await axios.post('/registration/validateUsername', { username }, { noauth: true })
      return true
    } catch (e) {
      const msg = transformServerError(e)
      if (msg.code === 'USERNAME_NOT_AVAILABLE') {
        return false
      } else {
        notifyError('profile', e)
      }
    }
  }

  const cancel = () => {
    form.resetFields()
    setEditing(false)
  }

  const onFinish = async (fields) => {
    setSaving(true)
    const username = _.trim(_.toLower(fields.username))
    try {
      if (username !== currentUser.username) {
        const isValid = await validateUsername(username)
        if (!isValid) {
          notifyError('profile', 'Sorry, this username is already taken.')
          return false
        }
      }
      const result = await axios.put(`/users-permissions/users/me`, { ...fields, username })
      setCurrentUser(result.data)
      setEditing(false)
    } catch (e) {
      notifyError('profile', 'An error occurred while saving your profile')
    } finally {
      setSaving(false)
    }
  }

  return (
    <>
      <Section title="My profile">
        <Form name="profile" layout="vertical" form={form} onFinish={onFinish} size="small" initialValues={currentUser}>
          <Row gutter={10}>
            <Col {...colProps}>
              <Form.Item label="Username" name="username" rules={usernameRule}>
                <Input disabled={!editing} style={{ textTransform: 'lowercase' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col {...colProps}>
              <Form.Item label="First name" name="firstName" rules={required}>
                <Input disabled={!editing} />
              </Form.Item>
            </Col>
            <Col {...colProps}>
              <Form.Item label="Last name" name="lastName" rules={required}>
                <Input disabled={!editing} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col {...colProps}>
              <Form.Item label="Email" name="email" rules={required}>
                <Input disabled={!editing} />
              </Form.Item>
            </Col>
            <Col {...colProps}>
              <Form.Item label="Phone" name="phone" rules={required}>
                <Input disabled={!editing} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col {...colProps}>
              <Form.Item label="Gender" name="gender">
                <Select disabled={!editing}>
                  <Select.Option key="female">Female</Select.Option>
                  <Select.Option key="male">Male</Select.Option>
                  <Select.Option key="other">Other</Select.Option>
                  <Select.Option key="undisclosed">Undisclosed</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...colProps}>
              <SkillLevelInput disabled={!editing} required={true} />
            </Col>
          </Row>
          {editing && (
            <Row>
              <Col>
                <>
                  <Button type="primary" size="large" htmlType="submit" loading={saving}>
                    Save
                  </Button>
                  <Button type="link" size="large" onClick={cancel}>
                    Cancel
                  </Button>
                </>
              </Col>
            </Row>
          )}
        </Form>
        {!editing && (
          <Row>
            <Col>
              <Button type="primary" size="large" onClick={() => setEditing(true)}>
                Edit
              </Button>
            </Col>
          </Row>
        )}
      </Section>
    </>
  )
}

export default ProfilePage
