import { Button, Col, Row, Typography } from 'antd'

import { Link } from 'react-router-dom'
import Section from '../common/Section'

const { Paragraph } = Typography
const DoublesLeagueSection = () => {
  return (
    <>
      <Section title="Adult Social Doubles" level={2}>
        <Paragraph>
          This program is for players interested in playing doubles tennis in a fun but competitive format.{' '}
          <Link to="/adult-doubles">More details</Link>
        </Paragraph>
        <Paragraph>
          <Link to="/adult-doubles-register">
            <Button>Sign up</Button>
          </Link>
        </Paragraph>
      </Section>
    </>
  )
}

export default DoublesLeagueSection
