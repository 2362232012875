import { add, format, getDay, set } from 'date-fns'

import { Col } from 'antd'

// const style = { borderRight: '1px solid #bbb', padding: '5px' }

const BookingTime = ({ time }) => {
  return <div className="bookingTime">{format(time, 'h:mm a')}</div>
}

export default BookingTime
