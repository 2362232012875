const GoogleCalendar = () => {
  return (
    <iframe
      title="Calendar"
      src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FToronto&src=aGVzcGVsZXJ0ZW5uaXNAZ21haWwuY29t&src=b202cGM1YzByMDJhc2VxYjBuY3FmNzkzMGNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=Y2VmM3BzZTh1b3M0b3Z0NG8zNG00MXF0ZnNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=bm85ZXQxMW1wZmZxaWtxamEyYjNxZTljMmdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=dTVsaGk0NXJobzBvMTA2bjE5Y3BzZm5hNjhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=M2c2cmY0NTdjYW1wZ2c1cHIwYTQ0a3BnOWdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=YzVxMHZtcXFtdmNjYTdrdGp0OXZyYzJjbThAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=cmZjaHRoMXY3bGhjdXFlOWlvZ2xhbGlhdWNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=cmU0bXEwY2g3ZGViZW1rdGVvNDJoMnBudjBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23AD1457&color=%234285F4&color=%230B8043&color=%23F6BF26&color=%237986CB&color=%230B8043&color=%23A79B8E&color=%23E4C441&color=%23039BE5"
      width="100%"
      height="700"
      frameborder="0"
      scrolling="no"
      style={{ paddingTop: '2em' }}
    ></iframe>
  )
}

export default GoogleCalendar
