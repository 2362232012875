import Section from '../common/Section'
import { Typography } from 'antd'

const { Text, Paragraph } = Typography

const StringingPage = () => {
  return (
    <Section title="Racquet Stringing Services">
      <Paragraph>Racquet stringing services are provided to club members by coach Josh Varghese.</Paragraph>
      <Paragraph>
        Contact Josh via email at <a href="mailto:jvstringing@gmail.com">jvstringing@gmail.com</a>
      </Paragraph>
      <Paragraph>
        <ul>
          <li>$15 per racquet (you provide the strings)</li>
          <li>1-2 days turnaround time</li>
          <li>Text or email beforehand to either meet at the club, or before leaving your racquet in the clubhouse.</li>
        </ul>
        Josh also has a limited collection of synthetic and polyester strings available for purchase. Please contact him
        for more information.
      </Paragraph>
    </Section>
  )
}

export default StringingPage
