import {
  PlusCircleOutlined as AddUserIcon,
  LeftOutlined as BackIcon,
  InfoCircleOutlined as InfoIcon,
  RightOutlined as NextIcon,
} from '@ant-design/icons'
import { Button, Checkbox, Col, Divider, Form, Input, Popover, Row, Select, Space, Tooltip, Typography } from 'antd'
import { EMAIL_RULES, toNumber } from '../../util/commonUtil'
import { hideNotification, notifyError, notifyInfo, notifyWarn } from '../../util/notifyUtil'
import { useEffect, useState } from 'react'

import GenderInput from '../common/GenderInput'
import { Link } from 'react-router-dom'
import RegistrationSummary from './RegistrationSummary'
import SkillLevelInput from '../common/SkillLevelInput'
import _ from 'lodash'
import axios from '../../axios/axios'
import { transformServerError } from '../../util/errorUtil'

const { Text, Title, Paragraph } = Typography

const MIN_JUNIOR_BIRTH_YEAR = new Date().getFullYear() - 18
const MAX_JUNIOR_BIRTH_YEAR = new Date().getFullYear() - 3

const interestedInAdultDoublesEnabled = false
const interestedInInterurbanEnabled = false

const colProps = { xs: 24, sm: 12, lg: 6, xl: 6 }
const required = [{ whitespace: true, required: true, message: '' }]
const birthYearRule = [
  {
    whitespace: true,
    type: 'number',
    transform: toNumber,
    required: true,
    min: MIN_JUNIOR_BIRTH_YEAR,
    max: MAX_JUNIOR_BIRTH_YEAR,
    message: `Birth year must be between ${MIN_JUNIOR_BIRTH_YEAR} and ${MAX_JUNIOR_BIRTH_YEAR}`,
  },
]
const usernameRule = [
  {
    whitespace: true,
    min: 3,
    message: 'Username must be at least 3 characters',
  },
]
const passwordRule = [
  {
    whitespace: true,
    required: true,
    min: 6,
    message: `Password must be at least 6 characters`,
  },
]

const RegisterStep2Details = ({ registration, setRegistration, onPrevious, onNext }) => {
  const [showForm, setShowForm] = useState()
  const [editing, setEditing] = useState(false)
  const [selectedMemberIndex, setSelectedMemberIndex] = useState(false)
  const [isJunior, setIsJunior] = useState(false)
  const [isAdult, setIsAdult] = useState(false)
  const [passwordRequired, setPasswordRequired] = useState(false)

  const validateUsername = async (username) => {
    let usernames = _.map(registration.members, (member) => member.username)
    if (editing) {
      // Exclude this user's username
      usernames.splice(selectedMemberIndex, 1)
    }
    if (_.includes(usernames, username)) {
      return false
    }
    try {
      // Check the server
      await axios.post('/registration/validateUsername', { username }, { noauth: true })
      return true
    } catch (e) {
      const msg = transformServerError(e)
      if (msg.code === 'USERNAME_NOT_AVAILABLE') {
        return false
      } else {
        notifyError('error', e)
      }
    }
  }

  const addOrUpdateMember = async (rawValues) => {
    hideNotification('username-validation')
    // Sanitize
    const { username, juniorLessonsDay, juniorLessonsTime, ...rest } = rawValues
    const sanitizedValues = {
      username: _.trim(_.toLower(username)),
      ...rest,
      juniorLessonsDay: _.isEmpty(juniorLessonsDay) ? undefined : juniorLessonsDay,
      juniorLessonsTime: _.isEmpty(juniorLessonsTime) ? undefined : juniorLessonsTime,
    }

    if (sanitizedValues.username) {
      const validUsername = await validateUsername(sanitizedValues.username)
      if (!validUsername) {
        notifyError(
          'username-validation',
          'Username not available',
          `Sorry, the username "${sanitizedValues.username}" is already taken.`,
        )
        return
      }
    }

    let members
    if (editing) {
      members = [
        ...registration.members.slice(0, selectedMemberIndex),
        { ...sanitizedValues },
        ...registration.members.slice(selectedMemberIndex + 1),
      ]
    } else {
      members = [...registration.members, { ...sanitizedValues }]
    }
    setRegistration({ ...registration, members })
    setShowForm(false)
    setSelectedMemberIndex(null)
  }

  const setAgeGroupOptions = (ageGroup) => {
    const isJunior = ageGroup === 'junior'
    setIsJunior(isJunior)
    setIsAdult(!isJunior)
    if (!isJunior) {
      form.setFieldsValue({ birthYear: undefined, juniorLessonsDay: undefined, juniorLessonsTime: undefined })
    }
  }

  const editMember = (i) => {
    form.setFieldsValue(registration.members[i])
    setSelectedMemberIndex(i)
    setAgeGroupOptions(registration.members[i].ageGroup)
    setEditing(true)
    setPasswordRequired(false)
    setShowForm(true)
  }

  const [form] = Form.useForm()

  const addMember = () => {
    form.setFieldsValue(defaults)
    setEditing(false)
    setPasswordRequired(false)
    setShowForm(true)
  }

  const cancel = () => {
    setShowForm(false)
    setEditing(false)
    setPasswordRequired(false)
    setSelectedMemberIndex(null)
  }

  const memberCount = _.size(registration.members)

  useEffect(() => {
    setShowForm(memberCount === 0)
  }, [memberCount])

  const { lastName, email, phone } = registration
  const defaults = {
    username: null,
    password: null,
    firstName: null,
    skill: null,
    ageGroup: null,
    gender: null,
    interestedInAdultDoubles: false,
    interestedInInterurban: false,
    lastName,
    email,
    phone,
  }

  const ageGroupOptions = [
    { value: 'adult', name: 'Adult', description: '18 or older' },
    { value: 'junior', name: 'Junior', description: 'Under 18' },
    { value: 'senior', name: 'Senior', description: '60 or older' },
    { value: 'student', name: 'Student', description: '18+ full-time student' },
  ]

  const usernameChanged = (e) => {
    const hasUsername = !!e.target?.value
    setPasswordRequired(hasUsername)
    if (!hasUsername) {
      form.setFields([{ name: 'password', errors: [] }])
      form.setFieldsValue({ password: undefined })
    }
  }

  return (
    <>
      <Paragraph>Provide details for each member linked to this registration.</Paragraph>
      <RegistrationSummary
        registration={registration}
        setRegistration={setRegistration}
        editMember={editMember}
        selectedMemberIndex={selectedMemberIndex}
      />
      <Form
        name="registration-details"
        layout="vertical"
        size="small"
        form={form}
        onFinish={addOrUpdateMember}
        initialValues={defaults}
      >
        {showForm && (
          <div className="registrationMemberForm">
            <Title style={{ marginTop: '0' }} level={3}>
              Member #{editing ? selectedMemberIndex + 1 : memberCount + 1}
            </Title>
            <Row gutter={10}>
              <Col {...colProps}>
                <Form.Item label="First name" name="firstName" rules={required}>
                  <Input autoFocus />
                </Form.Item>
              </Col>
              <Col {...colProps}>
                <Form.Item label="Last name" name="lastName" rules={required}>
                  <Input />
                </Form.Item>
              </Col>
              <Col {...colProps}>
                <Form.Item label="Email" name="email" rules={EMAIL_RULES}>
                  <Input />
                </Form.Item>
              </Col>
              <Col {...colProps}>
                <Form.Item label="Phone" name="phone" rules={required}>
                  <Input />
                </Form.Item>
              </Col>
              <Col {...colProps}>
                <SkillLevelInput />
              </Col>
              <Col {...colProps}>
                <GenderInput />
              </Col>
              <Col {...colProps}>
                <Form.Item label="Age group" name="ageGroup" rules={required}>
                  <Select dropdownMatchSelectWidth={false} onChange={setAgeGroupOptions}>
                    {ageGroupOptions.map(({ value, name, description }) => (
                      <Select.Option key={value}>
                        {name}
                        <Text italic type="secondary" style={{ paddingLeft: '2em' }}>
                          {description}
                        </Text>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {isJunior && (
              <Row gutter={10}>
                <Col span={24}>
                  <Paragraph className="note" style={{ marginTop: '2em', marginBottom: '0' }}>
                    Spring lessons are offered for free for interested junior members. Lessons are one session per week,
                    for 6 weeks.
                  </Paragraph>
                </Col>
                <Col {...colProps}>
                  <Form.Item label="Birth year" name="birthYear" rules={birthYearRule}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...colProps}>
                  <Form.Item label="Spring lessons day" name="juniorLessonsDay">
                    <Select allowClear>
                      <Select.Option key="">Not interested</Select.Option>
                      <Select.Option key="tuesday">Tuesdays</Select.Option>
                      <Select.Option key="wednesday">Wednesdays</Select.Option>
                      <Select.Option key="thursday">Thursdays</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...colProps}>
                  <Form.Item label="Spring lessons time" name="juniorLessonsTime">
                    <Select allowClear>
                      <Select.Option key="">Not interested</Select.Option>
                      <Select.Option key="_4pm">4:30 - 5:30pm</Select.Option>
                      <Select.Option key="_5pm">5:30 - 6:30pm</Select.Option>
                      <Select.Option key="noPreference">No preference</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}

            {isAdult && (interestedInAdultDoublesEnabled || interestedInInterurbanEnabled) && (
              <Row gutter={10}>
                <Col span={24}>
                  <Paragraph className="note" style={{ marginTop: '2em', marginBottom: '1em' }}>
                    Let us know if you might be interested in joining the following programs. We use this to guage
                    interest which will help us plan the year. <br />
                    More information about these programs and how to sign up will be sent out early in the season.
                  </Paragraph>
                </Col>
                {interestedInAdultDoublesEnabled && (
                  <Col span={24}>
                    <Form.Item label="" noStyle name="interestedInAdultDoubles" valuePropName="checked">
                      <Checkbox>I'm interested in playing in the Adult Social Doubles league.</Checkbox>
                    </Form.Item>
                    <Popover
                      overlayStyle={{ maxWidth: '30em' }}
                      placement="right"
                      content={
                        <Paragraph>
                          This is a social doubles league with various divisions based on skill level. See{' '}
                          <Link to="/adult-doubles" target="_blank">
                            Adult Social Doubles
                          </Link>{' '}
                          for more information.
                        </Paragraph>
                      }
                    >
                      <InfoIcon />
                    </Popover>
                  </Col>
                )}
                {interestedInInterurbanEnabled && (
                  <Col span={24}>
                    <Form.Item label="" noStyle name="interestedInInterurban" valuePropName="checked">
                      <Checkbox>I'm interested in trying out for the Interurban doubles team.</Checkbox>
                    </Form.Item>
                    <Popover
                      overlayStyle={{ maxWidth: '30em' }}
                      placement="right"
                      content={
                        <Paragraph>
                          This is a competitive doubles travel team for advanced players that plays other teams in the
                          region.
                        </Paragraph>
                      }
                    >
                      <InfoIcon />
                    </Popover>
                  </Col>
                )}
              </Row>
            )}

            <Row gutter={10}>
              <Col span={24}>
                <Paragraph className="note" style={{ marginTop: '2em', marginBottom: '0' }}>
                  A username and password is required to allow this member to reserve court time. This is your{' '}
                  <u>only opportunity</u> to create website logins.
                </Paragraph>
              </Col>
              <Col {...colProps}>
                <Form.Item label="Username" name="username" onChange={usernameChanged} rules={usernameRule}>
                  <Input style={{ textTransform: 'lowercase' }} />
                </Form.Item>
              </Col>
              <Col {...colProps}>
                <Form.Item label="Password" name="password" rules={passwordRequired ? passwordRule : []}>
                  <Input.Password />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Col>
                <Space direction="horizontal">
                  <Button htmlType="submit" type="primary" size="middle">
                    {editing ? 'Save changes' : 'Add'}
                  </Button>
                  {memberCount > 0 && (
                    <Button type="link" onClick={cancel}>
                      Cancel
                    </Button>
                  )}
                </Space>
              </Col>
            </Row>
          </div>
        )}

        <Row justify="space-between" style={{ paddingTop: '2em' }}>
          <Col>
            <Button icon={<BackIcon />} onClick={onPrevious} size="large" disabled={memberCount === 0 || showForm}>
              Back
            </Button>
          </Col>
          <Col>
            <Space direction="horizontal">
              {!showForm && memberCount > 0 && (
                <Text style={{ paddingRight: '2em' }}>
                  <Button type="link" size="large" onClick={addMember}>
                    Add another member
                  </Button>
                  or
                </Text>
              )}
              <Form.Item>
                <Button
                  icon={<NextIcon />}
                  type="primary"
                  size="large"
                  onClick={onNext}
                  disabled={memberCount === 0 || showForm}
                >
                  Review & Pay
                </Button>
              </Form.Item>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default RegisterStep2Details
