import { Carousel, Col, Row, Typography } from 'antd'

import Section from '../common/Section'
import photo1 from '../../assets/images/photos/clubhouse.jpg'
import photo2 from '../../assets/images/photos/courts1.jpg'
import photo3 from '../../assets/images/photos/courts2.jpg'
import photo4 from '../../assets/images/photos/courts3.jpg'
import photo5 from '../../assets/images/photos/courts4.jpg'

const { Paragraph, Title } = Typography

const WelcomeSection = () => {
  return (
    <>
      <Section title="Welcome to the Hespeler Tennis Club" />
      <Row align="top" gutter={30} style={{ paddingBottom: '2em' }}>
        <Col xs={24} lg={14} style={{ marginBottom: '2em' }}>
          <Paragraph>
            The Hespeler Tennis Club is a not for profit organization, offering tennis facilities and outdoor programs
            to the community. The club is located in beautiful Forbes park in the Hespeler area of Cambridge.
          </Paragraph>
          <Paragraph>We have a clubhouse and three fenced, lighted outdoor courts.</Paragraph>
          <Paragraph>Members can can book courts, or join some of the many organized programs we offer.</Paragraph>
          <Paragraph>
            <Title level={2} style={{ marginTop: '2em' }}>
              Our mission
            </Title>
            <blockquote>
              The Hespeler Tennis Club is dedicated to making tennis available to community members of all ages and
              levels of ability. Enjoyment of the game of tennis is enhanced through skilled coaching, recreation, and
              competitive play in a social atmosphere of friendliness, encouragement, and respect. We are committed to
              encouraging junior participation and skill development.
            </blockquote>
          </Paragraph>
        </Col>
        <Col xs={0} sm={0} lg={10}>
          <Carousel
            centerMode={false}
            autoplay={false}
            speed={800}
            autoplaySpeed={6000}
            draggable={true}
            pauseOnDotsHover={true}
            pauseOnHover={true}
          >
            <div className="homepagePhoto">
              <img src={photo5} alt="Courts" />
            </div>
            <div className="homepagePhoto">
              <img src={photo3} alt="Courts" />
            </div>
            <div className="homepagePhoto">
              <img src={photo2} alt="Courts" />
            </div>
            <div className="homepagePhoto">
              <img src={photo1} alt="Clubhouse" />
            </div>
            <div className="homepagePhoto">
              <img src={photo4} alt="Courts" />
            </div>
          </Carousel>
        </Col>
      </Row>
    </>
  )
}

export default WelcomeSection
