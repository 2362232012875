import Section from '../common/Section'
import { Typography } from 'antd'
const { Paragraph, Text, Title } = Typography

const CovidPage = () => {
  return (
    <>
      <Section title="COVID-19">
        <Paragraph>
          As directed by the City of Cambridge, we will follow guidelines set by Tennis Canada and the OTA, as well as
          all regional and provincial regulations.
        </Paragraph>
        <Paragraph>
          <Title level={3}>Below are some general guidelines to playing tennis at HTC at this time:</Title>
          <ul className="ulPadded">
            <li>Please do not come to HTC if you have COVID-19 symptoms or have tested positive for COVID-19.</li>
            <li>
              Members must register via the HTC website. Guests will be allowed up to 3 free visits but must sign the
              waiver (accessible via a QR code posted on the clubhouse) prior to their first time on the courts.
            </li>
            <li>
              All court time will require an online booking with the names of all players (even those from the same
              household).
            </li>
            <li>
              Courts must be booked online prior to play. Please do not show up at the courts unless you have a court
              time booked.
            </li>
            <li>
              A database will be kept containing the information from the online booking system to enable members to be
              contacted should the need arise.
            </li>
            <li>While playing, practice social distancing at all times.</li>
            <li>There shall be no congregation on the courts or near the clubhouse before/after playing.</li>
            <li>Hand sanitizer should be used before and after touching the court gate.</li>
            <li>The Club Pro will be available to book lessons in accordance with OTA guidelines. </li>
          </ul>
        </Paragraph>

        <Paragraph style={{ marginTop: '3em' }}>
          Failure to follow instructions and guidance provided by HTC staff will result in removal from ​the HTC for the
          year without refund of registration fees.
        </Paragraph>
        <Paragraph>
          The use of personal protective equipment is at the discretion of each member and will not be enforced;
          however, the use of face masks and/or gloves should be considered.
        </Paragraph>
        <Paragraph>
          The Hespeler Tennis Club retains the right to refuse court time to anyone showing COVID-19 symptoms.
        </Paragraph>
      </Section>
    </>
  )
}

export default CovidPage
