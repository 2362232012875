import _ from 'lodash'
import { isBlank } from './stringUtil'

/**
 * Compare the two provided strings.  Null/undefined/empty are treated the same and
 * sort before strings that have a value.
 * @param {*} a
 * @param {*} b
 * @returns
 */
export const compareStrings = (a, b) => {
  const aBlank = isBlank(a)
  const bBlank = isBlank(b)
  if (aBlank && bBlank) {
    return 0
  } else if (aBlank) {
    return -1
  } else if (bBlank) {
    return 1
  } else {
    return a === b ? 0 : a < b ? -1 : 1
  }
}

export const compareBools = (a, b) => {
  if (a === b) {
    return 0
  } else {
    return a ? 1 : -1
  }
}

export const compareNumbers = (a, b) => {
  const aIsNil = _.isNil(a)
  const bIsNil = _.isNil(b)
  if (aIsNil !== bIsNil) {
    // Have nil sort before non-nil (otherwise 0 sorts before null/undefined)
    return aIsNil ? -1 : 1
  } else {
    return a - b
  }
}
