import { gql } from '@apollo/client'

export const GET = gql`
  query {
    results: bookings {
      meta {
        pagination {
          total
        }
      }
      data {
        id
        attributes {
          __typename
          bookingType
          startTime
          endTime
          comment
          description
          createdAt
          updatedAt
        }
      }
    }
  }
`
