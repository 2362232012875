import { Button, Collapse, Typography } from 'antd'
import { FacebookLink, InstagramLink, MailingListLink, TwitterLink } from '../common/SocialLinks'

import { EmailLink } from '../common/EmailLink'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import Section from '../common/Section'

const { Text, Paragraph } = Typography
const { Panel } = Collapse

const FaqPage = () => {
  return (
    <Section title="Frequently Asked Questions">
      <Collapse ghost={false} bordered={false} defaultActiveKey={[]}>
        <Panel showArrow={true} className="faqPanel" key="covid" header="What is the club doing about COVID-19?">
          See our <Link to="/covid">COVID-19 information page</Link> for the latest details about rules and procedures
          related to COVID-19.
        </Panel>
        <Panel showArrow={true} className="faqPanel" key="whats-going-on" header="How can I find out what's going on?">
          Connect on social media and join our newsletter:
          <ul>
            <li>
              <FacebookLink />
            </li>
            <li>
              <TwitterLink />
            </li>
            <li>
              <InstagramLink />
            </li>
            <li>
              <MailingListLink />
            </li>
          </ul>
        </Panel>
        <Panel
          showArrow={true}
          className="faqPanel"
          key="shoe-tags"
          header="I signed up online - how do I get my 'shoe tag?'"
        >
          Pick up your shoe tag any time there are <Link to="/calendar">club programs</Link> going on. Please be sure to
          wear your shoe tag so that you can easily be identified as a member.
        </Panel>
        <Panel showArrow={true} className="faqPanel" key="forgot-gate-code" header="I forgot the gate code">
          <EmailLink email="info">Email us</EmailLink> for a reminder (be sure to include your name in your request)
        </Panel>
        <Panel showArrow={true} className="faqPanel" key="guests" header='Guest / "bring a friend" privileges'>
          <Paragraph>
            Yes, as a member you are welcome to bring a friend with you. We want you to feel comfortable sharing your
            club with friends and family from time to time, especially if they simply want to try tennis or are not sure
            yet about becoming a member themselves.
          </Paragraph>
          <Paragraph>
            <b>THIS PRIVILEGE IS NEVER MEANT TO REPLACE A MEMBERSHIP FOR YOUR SPOUSE/COMPANION(s).</b>
          </Paragraph>

          <Paragraph>
            There is a limit of 3 visits per summer per person as a guest at our club. If your guest wishes to come more
            often, they need to get a membership of their own.
          </Paragraph>
          <Paragraph>
            Please don't allow a friend or spouse to effectively split a membership with you. The facility's long term
            future depends on the membership dues of everyone.
          </Paragraph>
          <Paragraph>
            The club reserves the right to revoke the membership (without refund) of anyone who continues to bring a non
            member guest who's already visited the club more than 3 times in a season.
          </Paragraph>
        </Panel>
        <Panel
          showArrow={true}
          className="faqPanel"
          key="non-member-usage"
          header="What if I'm playing and a non-member asks if they can use the courts?"
        >
          <Paragraph>
            Due to COVID-19 and contact-tracing requirements, courts can only be used if they've been booked online, and
            only club members are permitted to book courts.
          </Paragraph>
          <Paragraph>
            This is a good time to remind you to put your “shoe tag” on your shoes or tennis bag so everyone will know
            you are a member.
          </Paragraph>
        </Panel>
        <Panel
          showArrow={true}
          className="faqPanel"
          key="court-lights"
          header="How do I use the lights to play at night?"
        >
          <ul>
            <li>There is a grey panel attached to the fence on the park side of the courts.</li>
            <li>
              Press the <b>GREEN button on the bottom</b> to turn on the lights at twilight.
            </li>
            <li>
              THE LIGHTS ARE VERY EXPENSIVE TO RUN so please do not turn them on when not required. Press the RED button
              to turn them off. If turned off by mistake, it will take about 15 minutes for them to reset to come on
              again. They are run on a timer and will go off around 11:30pm even if the red button is not pressed.
            </li>
          </ul>
        </Panel>
        <Panel showArrow={true} className="faqPanel" key="clubhouse-access" header="How can I get into the clubhouse?">
          <Paragraph>
            The clubhouse is only open when staff or board members are on site. There are washroom facilities at the
            Scout House in Forbes Park.
          </Paragraph>
        </Panel>
        <Panel showArrow={true} className="faqPanel" key="club-ownership" header="Who owns the club?">
          <Paragraph>
            The courts and clubhouse are owned by the City of Cambridge. The club is run by a{' '}
            <Link to="/directors">group of volunteers</Link> and a <Link to="/coaching">staff of Pros</Link>. The courts
            are maintained primarily by funds raised through membership fees and fundraising.
          </Paragraph>
          <Paragraph>
            There's no easier way to get to know lots of people at the club than being involved in helping to run
            activities or programs. <EmailLink email="president">Email us</EmailLink> if you are interested in
            participating, even in a small way.
          </Paragraph>
        </Panel>
        <Panel
          showArrow={true}
          className="faqPanel"
          key="buying-tennis-gear"
          header="Where can I buy tennis gear?  And can anyone help me figure out what I need to get?"
        >
          <Paragraph>
            There are several local tennis equipment stores in the Cambridge, Kitchener, and Waterloo area. The Club has
            partnered with several of them in the past for tournament prizes, including Courts Plus (located at 55 Erb
            Street East, Waterloo) and Control the ‘T’ Sports (located at 155 Northfield Drive West, Waterloo).
          </Paragraph>
        </Panel>
        <Panel
          showArrow={true}
          className="faqPanel"
          key="more-lessons"
          header="My child loves the after-school lessons and wants more - how can I arrange that?"
        >
          <Paragraph>
            The Club offers Junior Lessons (camps) Monday to Friday throughout the summer. Check out our{' '}
            <Link to="/camps">Junior Summer Camps</Link> page for more details.
          </Paragraph>
          <Paragraph>
            Members may also book private or semi-private lessons by emailing the <Link to="/coaching">Head Pro</Link>.
          </Paragraph>
        </Panel>
        <Panel
          showArrow={true}
          className="faqPanel"
          key="other"
          header="What can I do other than just play tennis on my own time?"
        >
          <Paragraph>
            The Club offers a variety of programs. Check out our <Link to="/calendar">Calendar page</Link> for a listing
            of programs and events.
          </Paragraph>
        </Panel>
      </Collapse>
    </Section>
  )
}

export default FaqPage
