import { Link } from 'react-router-dom'
import Section from '../common/Section'
import { Typography } from 'antd'

const { Paragraph } = Typography

const AdultDoublesRegistrationComplete = () => {
  return (
    <>
      <Section title="Adult Social Doubles - Sign Up">
        <Paragraph>
          You've successfully signed up for the <Link to="/adult-doubles">Adult Social Doubles</Link> program!
        </Paragraph>
        <Paragraph>
          Watch your email for more information about the seasson's schedule and player assessments.
        </Paragraph>
        {/* <Paragraph>
          If you'd like to sign up for another session, please <Link to="/adult-doubles-register">sign up again</Link>.
        </Paragraph> */}
      </Section>
    </>
  )
}

export default AdultDoublesRegistrationComplete
