import { gql } from '@apollo/client'

export const GET = gql`
  query {
    results: events {
      meta {
        pagination {
          total
        }
      }
      data {
        id
        attributes {
          __typename
          name
          eventType
          note
          description
          date
          startTime
          endTime
        }
      }
    }
  }
`
