import _ from "lodash";
export const isBlank = (str, trim = false) =>
  str === null || str === undefined || 0 === (trim ? _.trim(str) : str).length;

export const isNotBlank = (str, trim = false) => !isBlank(str, trim);

export const plural = (countOrCollection, singluarString, pluralString) => {
  const count = _.isArrayLike(countOrCollection)
    ? _.size(countOrCollection)
    : countOrCollection;
  return count === 1 ? singluarString : pluralString;
};

/**
 * Returns true if the first string (or collection) contains the second string (or item)
 * @param {*} first
 * @param {*} second
 * @param {*} ignoreCase
 * @returns
 */
export const contains = (first, second, ignoreCase = false) => {
  if (ignoreCase) {
    return _.includes(_.toUpper(first), _.toUpper(second));
  } else {
    return _.includes(first, second);
  }
};

export const removePrefix = (str, prefix) => {
  if (_.isNil(str)) {
    return str;
  }
  return _.startsWith(str, prefix) ? str.slice(prefix?.length) : str;
};
