import axios from 'axios'

export const config = {
  // Server config
  API_GRAPHQL_ENDPOINT: process.env.REACT_APP_API_GRAPHQL_ENDPOINT,
  API_REST_ENDPOINT: process.env.REACT_APP_API_REST_ENDPOINT,
  ENABLE_APOLLO_DEVTOOLS: process.env.REACT_APP_ENABLE_APOLLO_DEVTOOLS === 'true',
  PRODUCTION_MODE: process.env.REACT_APP_PRODUCTION_MODE === 'true',
  SERVER_DISABLED: process.env.REACT_APP_SERVER_DISABLED === 'true',

  // PayPal config
  PAYPAL_CLIENTID: process.env.REACT_APP_PAYPAL_CLIENTID,

  axios: axios.create({
    baseURL: process.env.REACT_APP_REST_API_ENDPOINT,
  }),

  // Registration
  REGISTRATION_ENABLED: process.env.REACT_APP_REGISTRATION_ENABLED === 'true',
  EARLY_REGISTRATION_ENABLED: process.env.REACT_APP_EARLY_REGISTRATION_ENABLED === 'true',
  SKIP_PAYMENT_CODE: process.env.REACT_APP_SKIP_PAYMENT_CODE,
  EARLY_REGISTRATION_CODE: process.env.REACT_APP_EARLY_REGISTRATION_CODE,

  // Bookings
  BOOKINGS_ENABLED: process.env.REACT_APP_BOOKINGS_ENABLED === 'true',
}
