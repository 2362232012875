import { APRIL, AUG, JULY, JUNE, MAY, NOV, OCT, SEPT } from '../constants'
import {
  add,
  isWithinInterval as dateFnsIsWithinInterval,
  getDay,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  isFuture,
  isMonday,
  isSameDay,
  isSaturday,
  isSunday,
  isThursday,
  isTuesday,
  isWednesday,
  parseISO,
  set,
  sub,
} from 'date-fns'

import { CURRENT_YEAR } from './commonUtil'
import { Link } from 'react-router-dom'
import _ from 'lodash'

// These need to match the server.
const CANCEL_BOOKING_GRACE_PERIOD = 15
const EDIT_BOOKING_GRACE_PERIOD = 15
const BOOKABLE_GRACE_PERIOD = 15
const LAST_MINUTE_BOOKING_PERIOD = 60 * 2 // 2 hours
const MAX_FUTURE_DAYS = 7

const FAR_FUTURE = new Date(2099, 1, 1)

const BOOKING_RULES_VIEWED_KEY = 'bookingRulesViewed-2'
export const setBookingRulesViewed = () => localStorage.setItem(BOOKING_RULES_VIEWED_KEY, 'yes')
export const bookingRulesViewed = () => localStorage.getItem(BOOKING_RULES_VIEWED_KEY) === 'yes'

export const getCourtsText = (booking) => {
  if (!booking) {
    return ''
  }
  const courts = booking.courts
  const count = _.size(courts)
  if (count === 1) {
    const c = courts[0]
    return c.label || `Court ${c.id}`
  } else if (count > 1) {
    return (
      'Courts ' +
      _.join(
        _.map(booking.courts, (c) => c.id),
        ', ',
      )
    )
  } else {
    return ''
  }
}

const getPlayerText = (booking, playerIndex) => {
  const name = booking[`player${playerIndex}`]
  const isGuest = booking[`player${playerIndex}IsGuest`]
  if (!name) {
    return null
  }
  return isGuest ? `${name} (guest)` : name
}

export const getPlayersText = (booking) => {
  if (!booking) {
    return ''
  }
  const names = _.reject(
    [getPlayerText(booking, 1), getPlayerText(booking, 2), getPlayerText(booking, 3), getPlayerText(booking, 4)],
    _.isNil,
  )
  return _.join(names, ', ')
}

const getTimeslot = (hour, minute = 0, duration = 60) => {
  const time = set(new Date(), { hours: hour, minutes: minute, seconds: 0, milliseconds: 0 })
  return { startTime: time, duration }
}

const DEFAULT_WEEKDAY = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22]
const DEFAULT_WEEKEND = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22]

const getHoursAndMinutes = (hours) => {
  const h = Math.floor(hours)
  const m = (hours % 1).toFixed(1) * 60
  return [h, m]
}

const getTimeslots = (hours) => {
  const result = []
  for (const hour of hours) {
    const [h, m] = getHoursAndMinutes(hour)
    result.push(getTimeslot(h, m))
  }
  return result
}

const isWithinInterval = (date, interval, atEndOfDay = true) => {
  const { start, end } = interval ?? {}
  const endAtEndOfDay = atEndOfDay ? set(end, { hours: 23, minutes: 59, seconds: 59 }) : end
  return dateFnsIsWithinInterval(date, { start, end: endAtEndOfDay })
}

const JUNIOR_LESSONS = { start: new Date(CURRENT_YEAR, MAY, 14), end: new Date(CURRENT_YEAR, JUNE, 27) }
const JUNIOR_CAMPS = { start: new Date(CURRENT_YEAR, JULY, 2), end: new Date(CURRENT_YEAR, AUG, 24) }
const JUNIOR_ADV_CAMPS = { start: new Date(CURRENT_YEAR, JULY, 4), end: new Date(CURRENT_YEAR, AUG, 25) }
const JUNIOR_DROPINS = { start: new Date(CURRENT_YEAR, JUNE, 23), end: new Date(CURRENT_YEAR, SEPT, 14) }
const ADULT_DOUBLES = { start: new Date(CURRENT_YEAR, MAY, 12), end: new Date(CURRENT_YEAR, SEPT, 27) }
const ADULT_LESSONS = { start: new Date(CURRENT_YEAR, MAY, 15), end: new Date(CURRENT_YEAR, SEPT, 15) }
const ADULT_DROPINS = { start: new Date(CURRENT_YEAR, MAY, 7), end: new Date(CURRENT_YEAR, OCT, 2) }

const isJuniorLessonsDay = (date) =>
  isWithinInterval(date, JUNIOR_LESSONS) && (isTuesday(date) || isWednesday(date) || isThursday(date))
const isJuniorCampDay = (date) =>
  isWithinInterval(date, JUNIOR_CAMPS) &&
  !isSaturday(date) &&
  !isSunday(date) &&
  !isSameDay(date, new Date(CURRENT_YEAR, JULY, 1)) &&
  !isSameDay(date, new Date(CURRENT_YEAR, AUG, 5))
const isSwingAndSplashDay = (date) =>
  isDate(date, JULY, 2) ||
  isDate(date, JULY, 4) ||
  isDate(date, JULY, 9) ||
  isDate(date, JULY, 11) ||
  isDate(date, JULY, 16) ||
  isDate(date, JULY, 18) ||
  isDate(date, JULY, 23) ||
  isDate(date, JULY, 25) ||
  isDate(date, JULY, 30) ||
  isDate(date, AUG, 1) ||
  isDate(date, AUG, 6) ||
  isDate(date, AUG, 8)
const isJuniorAdvCampDay = (date) => isWithinInterval(date, JUNIOR_ADV_CAMPS) && isWednesday(date)
const isAdultDoublesDay = (date) =>
  isWithinInterval(date, ADULT_DOUBLES) &&
  (isSunday(date) || isMonday(date) || isThursday(date)) &&
  !isDate(date, MAY, 20)

const isJuniorDropInDay = (date) => false // isWithinInterval(date, JUNIOR_DROPINS) && isThursday(date)

const isAdultLessonsDay = (date) => isWithinInterval(date, ADULT_LESSONS) && isWednesday(date) && !isDate(date, AUG, 30)
const isAdultDropInDay = (date) =>
  isWithinInterval(date, ADULT_DROPINS) &&
  isSunday(date) &&
  !isDate(date, AUG, 20) &&
  !isDate(date, AUG, 27) &&
  !isDate(date, SEPT, 3) &&
  !isDate(date, SEPT, 10)

const isInterurbanHomeDate = (date) =>
  isDate(date, MAY, 28) || isDate(date, JUNE, 11) || isDate(date, JUNE, 25) || isDate(date, JULY, 2)

const removeTimeslot = (timeslots, hour, min = 0) => {
  if (_.isArray(hour)) {
    hour.forEach((h) => removeTimeslot(timeslots, h))
  } else {
    _.remove(timeslots, (t) => getHours(t.startTime) === hour && getMinutes(t.startTime) === min)
  }
}
const addTimeslot = (timeslots, timeslot) => timeslots.push(timeslot)

const isDate = (date, month, day) => isSameDay(date, new Date(CURRENT_YEAR, month, day))

const adjustTimeslots = (date, timeslots) => {
  // Adjust (or replace) timeslots for a given date.

  if (isAfter(date, new Date(CURRENT_YEAR, NOV, 15))) {
    timeslots = []
    addTimeslot(timeslots, {
      ...getTimeslot(8, 0),
      message: 'Courts closed for the season',
      heightOverride: 400,
    })
  }

  if (isDate(date, APRIL, 25)) {
    removeTimeslot(timeslots, _.range(19, 21))
    addTimeslot(timeslots, {
      ...getTimeslot(19, 0, 60 * 2),
      message: <Link to="/interurban">Interurban tryouts</Link>,
    })
  }

  if (isDate(date, APRIL, 24) || isDate(date, APRIL, 30)) {
    removeTimeslot(timeslots, _.range(17, 20))
    addTimeslot(timeslots, { ...getTimeslot(17, 0, 90) })
    addTimeslot(timeslots, {
      ...getTimeslot(18, 30, 90),
      message: <Link to="/adult-doubles">Adult doubles assessments</Link>,
    })
  }

  if (isDate(date, MAY, 4) || isDate(date, MAY, 11) || isDate(date, MAY, 25)) {
    removeTimeslot(timeslots, _.range(11, 14))
    addTimeslot(timeslots, { ...getTimeslot(11, 0, 60 * 2), message: 'Free days in May (juniors)' })
    addTimeslot(timeslots, { ...getTimeslot(13, 0, 60 * 1), message: 'Free days in May (adults)' })
  }
  if (isDate(date, JUNE, 1)) {
    removeTimeslot(timeslots, _.range(10, 15))
    addTimeslot(timeslots, { ...getTimeslot(10, 0, 60 * 5), message: 'Season Opening Tournament', heightOverride: 300 })
  }
  if (isDate(date, JUNE, 22)) {
    removeTimeslot(timeslots, _.range(9, 15))
    addTimeslot(timeslots, { ...getTimeslot(9, 0, 60 * 6), message: 'Parent-child tournament', heightOverride: 300 })
  }
  if (isDate(date, JULY, 13)) {
    removeTimeslot(timeslots, _.range(11, 14))
    addTimeslot(timeslots, { ...getTimeslot(11, 0, 60 * 3), message: 'Wimbledon Social', heightOverride: 150 })
  }
  if (isDate(date, JULY, 27)) {
    removeTimeslot(timeslots, _.range(10, 13))
    addTimeslot(timeslots, {
      ...getTimeslot(10, 0, 60 * 3),
      message: 'Junior Ice Cream Tournament',
      heightOverride: 300,
    })
  }

  if (isDate(date, SEPT, 7)) {
    removeTimeslot(timeslots, _.range(10, 13))
    addTimeslot(timeslots, { ...getTimeslot(10, 0, 60 * 3), message: 'Junior Club Championships' })
  }

  // ##########################################################
  // if (isDate(date, SEPT, 14)) {
  //   removeTimeslot(timeslots, _.range(10, 15))
  //   addTimeslot(timeslots, {
  //     ...getTimeslot(10, 0, 60 * 5),
  //     message: 'Club Championship Semi Finals - Singles',
  //     heightOverride: 300,
  //   })
  // }
  // if (isDate(date, SEPT, 15)) {
  //   removeTimeslot(timeslots, _.range(10, 15))
  //   addTimeslot(timeslots, {
  //     ...getTimeslot(10, 0, 60 * 5),
  //     message: 'Club Championship Finals - Singles',
  //     heightOverride: 300,
  //   })
  // }
  // if (isDate(date, SEPT, 21)) {
  //   removeTimeslot(timeslots, _.range(10, 15))
  //   addTimeslot(timeslots, {
  //     ...getTimeslot(10, 0, 60 * 5),
  //     message: 'Club Championship Semi Finals - Doubles',
  //     heightOverride: 300,
  //   })
  // }
  // if (isDate(date, SEPT, 22)) {
  //   removeTimeslot(timeslots, _.range(10, 15))
  //   addTimeslot(timeslots, {
  //     ...getTimeslot(10, 0, 60 * 5),
  //     message: 'Club Championship Finals - Doubles',
  //     heightOverride: 300,
  //   })
  // }
  // ##########################################################

  if (isJuniorCampDay(date)) {
    removeTimeslot(timeslots, [9, 10, 11, 12])
    addTimeslot(timeslots, {
      ...getTimeslot(9, 0, 60 * 3.25),
      message: <Link to="/juniorcamps">Junior Summer Camps</Link>,
      heightOverride: 100,
    })
    addTimeslot(timeslots, getTimeslot(12, 15, 45))
  }

  if (isJuniorLessonsDay(date)) {
    removeTimeslot(timeslots, [16, 17, 18])
    removeTimeslot(timeslots, 18)
    removeTimeslot(timeslots, 16)
    addTimeslot(timeslots, getTimeslot(16, 0, 30))
    // addTimeslot(timeslots, getTimeslot(15, 0, 60 * 0.75))
    // addTimeslot(timeslots, getTimeslot(15, 45, 60 * 0.75))
    addTimeslot(timeslots, {
      ...getTimeslot(16, 30, 60 * 2),
      message: <Link to="/juniorlessons">Junior After-School Lessons</Link>,
      heightOverride: 100,
    })
    if (isWednesday(date) && isAdultLessonsDay(date)) {
    } else if (isThursday(date) && isAdultDoublesDay(date)) {
    } else {
      removeTimeslot(timeslots, 19)
      addTimeslot(timeslots, getTimeslot(18, 30, 60 * 1.5))
    }
  }

  if (isAdultDoublesDay(date)) {
    if (isMonday(date)) {
      var isLongWeekend = isDate(date, JULY, 1) || isDate(date, AUG, 5) || isDate(date, SEPT, 2)
      removeTimeslot(timeslots, [17, 18, 19, 20, 21, 22])
      addTimeslot(timeslots, getTimeslot(17, 0, 90))
      addTimeslot(timeslots, getTimeslot(21, 30, 90))
      const novice3Courts =
        isDate(date, JULY, 15) || isDate(date, AUG, 19) || isDate(date, SEPT, 16) || isDate(date, SEPT, 23)
      addTimeslot(timeslots, {
        ...getTimeslot(18, 30, 90),
        span: novice3Courts ? 3 : isLongWeekend ? 1 : 2,
        message: <Link to="/adult-doubles">Adult Social Doubles</Link>,
      })
      addTimeslot(timeslots, {
        ...getTimeslot(20, 0, 90),
        message: <Link to="/adult-doubles">Adult Social Doubles</Link>,
        span: isLongWeekend ? 2 : 3,
      })
    } else if (!isSunday(date)) {
      removeTimeslot(timeslots, [17, 18, 19, 20, 21, 22])
      if (!isJuniorLessonsDay(date)) {
        // addTimeslot(timeslots, getTimeslot(18, 0, 30))
        addTimeslot(timeslots, getTimeslot(17, 0, 90))
      }
      addTimeslot(timeslots, getTimeslot(21, 30, 90))
      addTimeslot(timeslots, {
        ...getTimeslot(18, 30, 60 * 3),
        message: <Link to="/adult-doubles">Adult Social Doubles</Link>,
        heightOverride: 100,
      })
    } else {
      removeTimeslot(timeslots, [17, 18, 19])
      addTimeslot(timeslots, getTimeslot(17, 0, 90))
      var isLongWeekend = isDate(date, JUNE, 30) || isDate(date, AUG, 4) || isDate(date, SEPT, 1)
      addTimeslot(timeslots, {
        ...getTimeslot(18, 30, 60 * 1.5),
        message: <Link to="/adult-doubles">Adult Social Doubles</Link>,
        span: isLongWeekend ? 2 : 3,
        heightOverride: 100,
      })
    }
  }

  if (isDate(date, MAY, 20)) {
    removeTimeslot(timeslots, [17, 18, 19, 20, 21, 22])
    addTimeslot(timeslots, getTimeslot(17, 0, 90))
    addTimeslot(timeslots, getTimeslot(21, 30, 90))
    addTimeslot(timeslots, {
      ...getTimeslot(18, 30, 90),
      span: 1,
      message: <Link to="/adult-doubles">Adult Social Doubles</Link>,
    })
    addTimeslot(timeslots, {
      ...getTimeslot(20, 0, 90),
      span: 2,
      message: <Link to="/adult-doubles">Adult Social Doubles</Link>,
    })
  }

  if (isDate(date, OCT, 5)) {
    removeTimeslot(timeslots, [20, 21, 22])
    addTimeslot(timeslots, getTimeslot(21, 30, 90))
    addTimeslot(timeslots, {
      ...getTimeslot(20, 0, 90),
      message: <Link to="/adult-doubles">Adult Social Doubles (intermediate only)</Link>,
      heightOverride: 100,
    })
  }

  if (isAdultLessonsDay(date)) {
    removeTimeslot(timeslots, [19, 20, 21, 22])
    removeTimeslot(timeslots, 18, 30)
    if (isJuniorLessonsDay(date)) {
      addTimeslot(timeslots, getTimeslot(18, 30, 45))
    } else {
      removeTimeslot(timeslots, 18)
      addTimeslot(timeslots, getTimeslot(18, 0, 75))
    }
    addTimeslot(timeslots, getTimeslot(21, 30))
    addTimeslot(timeslots, getTimeslot(22, 30))
    addTimeslot(timeslots, {
      ...getTimeslot(19, 15, 2 * 60 + 15),
      message: <Link to="/lessons">Adult Lessons</Link>,
      heightOverride: 100,
    })
  }

  if (isSwingAndSplashDay(date)) {
    removeTimeslot(timeslots, [13, 14])
    addTimeslot(timeslots, {
      ...getTimeslot(13, 0, 2 * 60),
      message: 'Swing & Splash Camp',
      heightOverride: 100,
    })
  }

  if (isJuniorAdvCampDay(date)) {
    removeTimeslot(timeslots, [17, 18])
    let duration = 60 * 1.5
    addTimeslot(timeslots, {
      ...getTimeslot(17, 0, duration),
      span: 2,
      message: <Link to="/junioradvancedlessons">Advanced Junior Lessons</Link>,
    })
    addTimeslot(timeslots, getTimeslot(18, 30, 45))
  }

  if (isJuniorDropInDay(date)) {
    removeTimeslot(timeslots, 17)
    removeTimeslot(timeslots, 18)
    addTimeslot(timeslots, {
      ...getTimeslot(17, 0, 1 * 60),
      message: <Link to="/juniordropins">Junior Drop-Ins</Link>,
      span: 2,
    })
    addTimeslot(timeslots, {
      ...getTimeslot(18, 0, 1 * 60),
      message: <Link to="/juniordropins">Junior Drop-Ins</Link>,
      span: 2,
    })
  }

  // if (isAdultDropInDay(date) ) {
  //   if (isDate(date, OCT, 1)) {
  //     removeTimeslot(timeslots, [18, 19])
  //     addTimeslot(timeslots, {
  //       ...getTimeslot(18, 0, 2 * 60),
  //       message: <Link to="/dropins">Adult intermediate / advanced singles drop-in</Link>,
  //       heightOverride: 100,
  //     })
  //   } else {
  //     removeTimeslot(timeslots, [17, 18, 19])
  //     addTimeslot(timeslots, {
  //       ...getTimeslot(17, 0, 3 * 60),
  //       message: <Link to="/dropins">Adult intermediate / advanced singles drop-in</Link>,
  //       heightOverride: 100,
  //     })
  //   }
  // }

  if (isInterurbanHomeDate(date)) {
    removeTimeslot(timeslots, [18, 19, 20, 21, 22])
    removeTimeslot(timeslots, 18, 30)
    // 6:30pm start if there are after-school lessons; 6pm start otherwise.
    const startTime = isJuniorLessonsDay(date) ? [18, 30] : [18, 0]
    addTimeslot(timeslots, {
      ...getTimeslot(...startTime, 3 * 60),
      message: <Link to="/interurban">Interurban match</Link>,
    })
  }

  return timeslots
}

const DEFAULTS = {
  0: DEFAULT_WEEKEND, // Sunday
  1: DEFAULT_WEEKDAY,
  2: DEFAULT_WEEKDAY,
  3: DEFAULT_WEEKDAY,
  4: DEFAULT_WEEKDAY,
  5: DEFAULT_WEEKDAY,
  6: DEFAULT_WEEKEND, // Saturday
}

const sortTimeslots = (timeslots) => _.sortBy(timeslots, 'startTime')

export const getStartTimes = (date) => {
  if (!date) {
    return []
  }
  let timeslots = getTimeslots(DEFAULTS[getDay(date)])
  timeslots = adjustTimeslots(date, timeslots)
  timeslots = sortTimeslots(timeslots)

  const result = []
  for (const timeslot of timeslots) {
    const startTime = timeslot.startTime
    const newDate = set(new Date(), {
      year: date.getFullYear(),
      month: date.getMonth(),
      date: date.getDate(),
      hours: startTime.getHours(),
      minutes: startTime.getMinutes(),
      seconds: 0,
      milliseconds: 0,
    })
    result.push({ ...timeslot, startTime: newDate })
  }
  return result
}

export const getStartTimeAsDate = (booking) => (booking ? parseISO(booking.startTime) : null)

export const mayBeCancelled = (booking, user) => {
  if (!booking || !booking.createdByUser || !user) {
    return false
  }
  const createdByUser = booking.createdByUser.id === user.id
  const bookingCancelCutoff = add(getStartTimeAsDate(booking), { minutes: CANCEL_BOOKING_GRACE_PERIOD })
  const isFutureBooking = isFuture(bookingCancelCutoff)

  return (createdByUser && isFutureBooking) || user.mayCancelAnyBooking
}

export const mayBeEdited = (booking, user) => {
  if (!booking || !booking.createdByUser || !user) {
    return false
  }
  const createdByUser = booking.createdByUser.id === user.id
  const bookingEditCutoff = add(getStartTimeAsDate(booking), { minutes: EDIT_BOOKING_GRACE_PERIOD })
  const isFutureBooking = isFuture(bookingEditCutoff)

  return (createdByUser && isFutureBooking) || user.mayEditAnyBooking
}

export const getLatestAllowedBooking = (user) => {
  if (user && user.mayIgnoreFutureBookingLimit) {
    return FAR_FUTURE
  } else {
    return add(set(new Date(), { hours: 23, minutes: 59, seconds: 59 }), { days: MAX_FUTURE_DAYS })
  }
}

export const mayBeBooked = (time, user) => {
  if (_.isNil(time) || !user) {
    return false
  }
  const earliestAllowed = add(time, { minutes: BOOKABLE_GRACE_PERIOD })
  return isFuture(earliestAllowed) && isBefore(time, getLatestAllowedBooking(user))
}

export const isLastMinute = (time) => {
  time = _.isDate(time) ? time : new Date(time)
  const lastMinuteAllowedInterval = {
    start: sub(new Date(), { minutes: BOOKABLE_GRACE_PERIOD }),
    end: add(new Date(), { minutes: LAST_MINUTE_BOOKING_PERIOD }),
  }
  const result = isWithinInterval(time, lastMinuteAllowedInterval, false)
  return result
}
