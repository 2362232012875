import { formatDateTime, parseDateTime } from '../../util/dateTimeUtil'
import { getCourtsText, getPlayersText } from '../../util/bookingUtil'

import { CheckOutlined as Icon } from '@ant-design/icons'
import { Typography } from 'antd'
import _ from 'lodash'
import { notifyInfo } from '../../util/notifyUtil'

const { Text, Link } = Typography

const format = (time) => {
  if (_.isNil(time)) {
    return ''
  }
  const minutes = parseDateTime(time).getMinutes()
  return formatDateTime(time, minutes === 0 ? 'h a' : 'h:mm a')
}

const BookingDisplay = ({ title, booking, hide, onClick }) => {
  const handleClick = () => {
    onClick?.(new Date(booking.startTime))
    hide?.()
  }

  if (!booking) {
    return 'None'
  }

  const players = getPlayersText(booking)
  return (
    <span className="bookingUpcoming">
      <div className="upcomingBookingLink" onClick={handleClick}>
        <Icon style={{ color: 'green', paddingRight: '.5em' }} />
        <Text className="date">{formatDateTime(booking.startTime, 'EEE MMM d')}</Text>
        <Text className="bookingUpcoming"> at </Text>
        <Text className="time">{format(booking.startTime)}</Text>
        <Text className="court">({getCourtsText(booking)})</Text>
        {!_.isEmpty(players) && (
          <>
            <Text className="players">{players}</Text>{' '}
          </>
        )}
      </div>
    </span>
  )
}

export default BookingDisplay
