import { ADULT_DOUBLES_END, ADULT_DOUBLES_START } from '../../constants'
import { Checkbox, Col, Form, Input, Row, Select, Space, Typography } from 'antd'
import { Link, Navigate } from 'react-router-dom'
import { doApprove, doPay } from '../../util/payUtil'
import { useContext, useState } from 'react'

import AppContext from '../common/AppContext'
import { CURRENT_YEAR } from '../../util/commonUtil'
import { EmailLink } from '../common/EmailLink'
import LoginLink from '../common/LoginLink'
import Note from '../common/Note'
import { NtrpInfoIcon } from '../common/SkillLevelInput'
import { PayPalButtons } from '@paypal/react-paypal-js'
import Section from '../common/Section'
import _ from 'lodash'

const { Paragraph, Text } = Typography
const profileColProps = { flex: '1' }
const required = [{ whitespace: true, required: true, message: '' }]

const AdultDoublesRegistration = () => {
  const { currentUser } = useContext(AppContext)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [price, setPrice] = useState(0)
  const [success, setSuccess] = useState(false)

  if (success === true) {
    return <Navigate to="/adult-doubles-register-complete" />
  }

  const handleSessionChanged = (session) => setPrice(15)

  const handleApproveSuccess = (result) => {
    setLoading(false)
    setPrice(0)
    setSuccess(true)
  }

  const pay = async (data, actions) => doPay(price, 'Adult Doubles Registration', data, actions)
  const onApprove = async (data, actions) =>
    doApprove(form, '/my/adult-doubles-signup', handleApproveSuccess, null, false, data, actions)

  const onError = async (data, actions) => {
    console.error('A PayPal error occurred', data)
    setError(data)
  }

  return (
    <>
      <Section title="Adult Social Doubles - Sign Up">
        {!currentUser && (
          <Note
            content={
              <>
                You must be logged-in to sign up for this program. <LoginLink>Log in now</LoginLink>, or{' '}
                <Link to="/register">register</Link> if you're not yet a {CURRENT_YEAR} club member.
              </>
            }
          />
        )}
        <Paragraph>
          <ul>
            <li>
              See the <Link to="/adult-doubles">Adult Social Doubles</Link> page for full details.
            </li>
            <li style={{ fontWeight: 'bold' }}>
              Please ensure you have been assessed by Head Pro Dan Darney at one of the drop-in assessment nights (Apr
              24 or 30, 6:30-8:00pm, rain date May 2) before registering for a session. If you have been previously
              assessed by Dan and your level has not changed, there is no need to be assessed.
            </li>
            <li>
              We are currently limiting sign-ups to one session to allow the maximum number of people a chance to
              participate. If you would like to be considered for multiple sessions in case we have future availability,
              please indicate that below.
            </li>
            <li>
              Due to high demand and to ensure coverage during weeks when people will be on vacation, there will be up
              to 18 people booked in each session. This means there will be up to 6 people in each session not scheduled
              to play each week.
            </li>
            <li>
              If you have any questions or issues, contact the coordinators at: <EmailLink email="adultsocialdoubles" />
            </li>
          </ul>
        </Paragraph>

        {currentUser && (
          <>
            <Paragraph className="">
              To sign up, please provide the following information and click the <i>PayPal</i> button ($15 fee)
            </Paragraph>
            <Paragraph className="note">
              If your personal info below is wrong, please correct it in <Link to="/profile">your profile</Link>.
            </Paragraph>

            <Form
              name="registration-details"
              layout="vertical"
              size="small"
              form={form}
              className="form"
              style={{ maxWidth: '50em' }}
            >
              <Row gutter={20}>
                <Col {...profileColProps} className="noWrap">
                  <Form.Item label="Name">
                    {currentUser?.firstName} {currentUser?.lastName}
                  </Form.Item>
                </Col>
                <Col {...profileColProps} className="noWrap">
                  <Form.Item label={<span className="noWrap">Skill level</span>}>
                    <span className="noWrap">
                      {currentUser?.skill} <NtrpInfoIcon style={{ verticalAlign: 'bottom' }} />
                    </span>
                  </Form.Item>
                </Col>
                <Col {...profileColProps} className="noWrap">
                  <Form.Item label="Phone">{currentUser?.phone}</Form.Item>
                </Col>
                <Col {...profileColProps} className="noWrap">
                  <Form.Item label="Email">{currentUser?.email}</Form.Item>
                </Col>
              </Row>
              <Row gutter={10} style={{ marginTop: '1em' }}>
                <Col xs={24}>
                  <Paragraph strong>
                    NOTE: If a session is closed, email the coordinators at <EmailLink email="adultsocialdoubles" /> to
                    be placed on a wait list. We'll try to fit you in when possible throughout the season.
                  </Paragraph>
                  <Text>Session: </Text>{' '}
                  <Form.Item label="" name="session" rules={required}>
                    <Select placeholder="Select..." onChange={handleSessionChanged} style={{ maxWidth: '30em' }}>
                      <Select.Option key="sunday-early" disabled>
                        Sundays 6:30 - 8:00PM{' '}
                        <Text italic type="secondary" style={{ paddingLeft: '2em' }}>
                          (Multi-level intermediate 3.0-3.5) - closed
                        </Text>
                      </Select.Option>
                      <Select.Option key="monday-early" disabled>
                        Mondays 6:30 - 8:00PM{' '}
                        <Text italic type="secondary" style={{ paddingLeft: '2em' }}>
                          (Beginner/Novice 2.0-2.5)
                        </Text>
                      </Select.Option>
                      <Select.Option key="monday-late" disabled>
                        Mondays 8:00 - 9:30PM{' '}
                        <Text italic type="secondary" style={{ paddingLeft: '2em' }}>
                          (Intermediate 3.0) - closed
                        </Text>
                      </Select.Option>
                      <Select.Option key="thursday-early" disabled>
                        Thursdays 6:30 - 8:00PM{' '}
                        <Text italic type="secondary" style={{ paddingLeft: '1.3em' }}>
                          (Advanced 4.0+)
                        </Text>
                      </Select.Option>
                      <Select.Option key="thursday-late" disabled>
                        Thursdays 8:00 - 9:30PM{' '}
                        <Text italic type="secondary" style={{ paddingLeft: '1.3em' }}>
                          (High intermediate 3.5) - closed
                        </Text>
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} style={{ marginTop: '1em' }}>
                <Col span={24}>
                  <Form.Item label="" name="ableToSwitchNights" valuePropName="checked">
                    <Checkbox>
                      I'm willing to switch nights if my preferred night is full{' '}
                      <Text className="note">
                        (the program coordinators will contact you if they need to switch you to a different session)
                      </Text>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="" name="interestedInPlayingBothNights" valuePropName="checked">
                    <Checkbox>
                      If there is future availability, I would like to play both nights{' '}
                      <Text className="note">
                        (additional registration and payment would be required at a later date)
                      </Text>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="" name="willSpare" valuePropName="checked">
                    <Checkbox>
                      Add me to the spares list (no fee){' '}
                      <Text className="note">
                        (by selecting this option, you consent to having your name, email address, and phone number
                        added to the spares list which will be distributed to all program participants)
                      </Text>
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} style={{ marginTop: '1em' }}>
                <Col xs={24}>
                  <Text>
                    List any dates during the season ({ADULT_DOUBLES_START} - {ADULT_DOUBLES_END} ) that you will NOT be
                    available to play due to vacation or other commitments. Please note that there will be 5-8 nights
                    throughout the season when you will not be scheduled to play due to the high number of registrants.
                    We will try to align your unscheduled nights with your unavailable nights.
                  </Text>
                  <Form.Item label="" name="notes">
                    <Input.TextArea style={{ maxWidth: '40em' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} style={{ marginTop: '1em' }}>
                <Col span={24}>
                  {price > 0 && (
                    <Space direction="horizontal">
                      <Text style={{ fontSize: '1.2em' }}>
                        Price: <b>${price}</b>
                      </Text>
                    </Space>
                  )}
                </Col>
              </Row>
              <Row gutter={10}>
                <Form.Item>
                  {price > 0 && (
                    <PayPalButtons
                      style={{ layout: 'vertical', color: 'gold' }}
                      fundingSource="paypal"
                      createOrder={pay}
                      onApprove={onApprove}
                      onError={onError}
                    />
                  )}
                </Form.Item>
              </Row>
            </Form>
          </>
        )}
      </Section>
    </>
  )
}

export default AdultDoublesRegistration
