import { Alert, Button, Popover, Typography } from 'antd'

import BookingDisplay from './BookingDisplay'
import { StarTwoTone as UpcomingBookingsIcon } from '@ant-design/icons'
import _ from 'lodash'
import { useState } from 'react'

const { Title, Text, Paragraph } = Typography

const Content = ({ bookings }) => {
  return (
    <div
      style={{
        maxHeight: '16em',
        overflowY: 'auto',
      }}
    >
      <ul style={{ paddingLeft: '1em' }}>
        {bookings.map((booking) => {
          return (
            <li>
              <BookingDisplay key={booking?.id} title="Next booking" booking={booking} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const UpcomingBookingsPopover = ({ bookings, setDate }) => {
  const [showAll, setShowAll] = useState(false)

  const MAX = 5
  const bookingsToShow = showAll ? bookings : bookings?.slice(0, MAX)
  const hiddenCount = _.size(bookings) - MAX

  if (!bookings) {
    return null
  }

  return (
    <div
      style={{
        padding: 10,
        paddingTop: 5,
        alignItems: 'top',
        width: 'fit-content',
        float: 'right',
      }}
    >
      <Popover
        defaultVisible={false}
        trigger={['click']}
        placement="topRight"
        overlayStyle={{ minWidth: '30em', maxWidth: '40em' }}
        overlayInnerStyle={{
          backgroundColor: '#fffad7',
          border: '0px solid #666',
          borderRadius: '1em',
        }}
        arrowContent={null}
        showArrow={false}
        content={
          <>
            {!_.isEmpty(bookingsToShow) ? (
              <div
                style={{
                  maxHeight: '80vh',
                  overflowY: 'auto',
                }}
              >
                <Paragraph strong>My upcoming court bookings:</Paragraph>
                <div style={{ paddingLeft: '0.5em', paddingBottom: '0.5em' }}>
                  {bookingsToShow.map((booking) => {
                    return (
                      <div key={booking?.id}>
                        <BookingDisplay key={booking?.id} title="Next booking" booking={booking} onClick={setDate} />
                        <br />
                      </div>
                    )
                  })}
                </div>
                {hiddenCount > 0 && !showAll && (
                  <span className="bookingUpcoming">
                    + {hiddenCount} more
                    <Button type="link" size="small" style={{ fontSize: '0.9em' }} onClick={() => setShowAll(true)}>
                      Show all
                    </Button>
                  </span>
                )}
              </div>
            ) : (
              <Paragraph style={{ textAlign: 'center', paddingTop: '1em' }}>
                You have no upcoming court bookings.
              </Paragraph>
            )}
          </>
        }
      >
        <Title level={4} className="noPad" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
          <Button type="primary">My bookings</Button>
        </Title>
      </Popover>
    </div>
  )
}

export default UpcomingBookingsPopover
