import { notifyError, notifySuccess } from './notifyUtil'

import axios from '../axios/axios'

export const doPay = async (price, description, data, actions) => {
  try {
    if (!price || price < 0) {
      notifyError('no-price', `Error processing payment.  Could not determine price.`)
      return
    }
    if (!description) {
      notifyError('no-description', `Error processing payment.  No description provided.`)
      return
    }
    return actions.order
      .create({
        purchase_units: [
          {
            description,
            amount: {
              value: price,
            },
          },
        ],
        application_context: {
          shipping_preference: 'NO_SHIPPING',
        },
      })
      .then(
        (orderID) => {
          console.log(`Setting orderID to ${orderID}`)
          return orderID
        },
        (e) => {
          console.error('Error caught in promise rejection of actions.order.create: ', e)
        },
      )
  } catch (e) {
    console.error('Error calling actions.order.create: ', e)
  }
}

const defaultErrorHandler = (e) => {
  notifyError('error', e)
}

export const doApprove = async (form, postUrl, successHandler, errorHandler, noAuth, data, actions) => {
  const handleError = (e) => (errorHandler || defaultErrorHandler)(e)
  try {
    console.log('onApprove.start')
    const details = await actions.order.capture()
    console.log('onApprove.done actions.order.capture')
    const { payer, purchase_units } = details
    const referenceId = purchase_units[0].reference_id
    const payerName = details.payer.name.given_name

    try {
      const values = form.getFieldsValue(true)
      const config = noAuth ? { noauth: true } : {}
      axios.post(postUrl, { data: values }, config).then((result) => successHandler(result, values), handleError)
      console.log('onApprove.done axios call')
    } catch (e) {
      console.log('onApprove.got error: ', e)
      handleError(e)
    } finally {
    }
  } catch (e) {
    handleError(e)
  }
}
