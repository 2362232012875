import { Button, Col, Collapse, Modal, Popover, Typography } from 'antd'
import { bookingRulesViewed, setBookingRulesViewed } from '../../util/bookingUtil'
import { useEffect, useState } from 'react'

import { InfoCircleTwoTone as InfoIcon } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import useResponsive from '../../hooks/useResponsive'

const { Title, Paragraph } = Typography
const { Panel } = Collapse
const BookingInstructionsModal = () => {
  const [visible, setVisible] = useState(false)
  const bp = useResponsive()

  const handleAgree = () => {
    setBookingRulesViewed()
    setVisible(false)
  }

  useEffect(() => {
    if (!bookingRulesViewed()) {
      setVisible(true)
    }
  }, [])

  return (
    <>
      <Modal
        title="Rules and guidelines for the court booking system"
        maskClosable={false}
        closeIcon={' '}
        keyboard={false}
        visible={visible}
        width={bp.xs ? '100%' : '650px'}
        footer={[
          <Button type="primary" onClick={handleAgree}>
            I agree
          </Button>,
        ]}
      >
        <Paragraph>
          Please review and agree to the following rules and guidelines for the Hespeler Tennis Club's court booking
          system:
        </Paragraph>
        <Paragraph>
          <ul>
            <li>
              Courts may be booked up to <b>one week in advance</b>, but you may only create a booking{' '}
              <b>once every 22 hours</b> (not including last-minute bookings)
            </li>
            <li>
              A <b>last-minute booking</b> allows you to book a court that's still open within the next two hours.
              Last-minute bookings don't count towards your 22-hour booking window.
            </li>
            <li>
              <b>Consecutive timeslots</b>: For singles play, consecutive timeslots shall not be booked. For doubles
              play, booking a maximum of two consecutive timeslots is permitted.
            </li>
            <li>
              You <b>must include the full names</b> of any players who will be sharing the court with you.
              <br />
              Guests are permitted but they must be flagged on the booking, and there is a limit of{' '}
              <b>three visits per season per guest</b>.
            </li>
            <li>
              A booking may be cancelled at any time, but please <b>avoid cancelling without sufficient notice</b> as
              this may result in courts sitting empty.{' '}
            </li>
          </ul>
        </Paragraph>
        <Paragraph>
          In rare cases, a booking may need to be cancelled due to club functions that need to be rescheduled (due to
          inclement weather, etc). You'll be notified if this occurs.
        </Paragraph>
        <Paragraph>
          Abusing this booking system by not showing up for your booked court times may result in loss of your court
          booking privileges. See the <Link to="/policies">HTC Policies and Procedures</Link> for more information.
        </Paragraph>
      </Modal>
      <Paragraph>
        See the{' '}
        <Button className="noPad" type="link" onClick={() => setVisible(true)}>
          rules and guidelines
        </Button>{' '}
        for details about the Hespeler Tennis Club court booking system.
      </Paragraph>
    </>
  )
}

export default BookingInstructionsModal
