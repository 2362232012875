import { Col, Divider, Row, Typography } from 'antd'

import { EmailLink } from '../common/EmailLink'
import { Link } from 'react-router-dom'
import Section from '../common/Section'

const { Paragraph, Text, Title } = Typography
const RegisterStep4Details = ({ registration }) => {
  const { paymentInfo } = registration
  const price = <b>${registration.price}</b>
  const regNumNote = <b>Registration number: {registration.registrationNumber}</b>
  return (
    <>
      <Paragraph style={{ fontSize: '1.2em' }}>
        {paymentInfo.skippedPayment ? (
          <>
            Your registration has been submitted, but <span>payment is still pending.</span>
          </>
        ) : (
          <b>Your registration has been completed successfully!</b>
        )}
      </Paragraph>
      <Paragraph>
        Your registration number is <Text strong>{registration.registrationNumber}</Text>
      </Paragraph>
      <Paragraph>
        A confirmation email with your registration details has been sent to {registration.email}. Please check your
        spam folder if it doesn't arrive shortly.
      </Paragraph>
      {paymentInfo.skippedPayment && (
        <>
          <Paragraph>
            <Title level={4}>
              Please provide payment through one of the following methods (or as previously discussed with an HTC
              representative)
            </Title>
          </Paragraph>
          <Title level={2} style={{ marginTop: 0 }}>
            e-Transfer
          </Title>
          <Paragraph>
            Send {price} to <b>pay@hespelertennis.on.ca</b>
            <br />
            Include your <b>full name</b> and {regNumNote} in the e-transfer comment/note.
          </Paragraph>
          <Title level={2} style={{ marginTop: 0 }}>
            Cheque
          </Title>
          <Paragraph>
            Cheque for {price} made out to <b>Hespeler Tennis Club</b>.<br />
            Include {regNumNote} in the cheque memo.
            <br />
            Please drop the cheque off in person to an HTC staff member or representative at the clubhouse.
          </Paragraph>
        </>
      )}

      <Section title="What's next?" level={2}>
        <Paragraph>Now that you're registered, you can sign up for various leagues and programs at the club.</Paragraph>
        <Paragraph>
          Don't forget to checkout out our <Link to="/calendar">club calendar</Link>, and{' '}
          <Link to="/courtbooking">book a court</Link> to come out and play.
        </Paragraph>

        <ul>
          <li>
            <Link to="/adult-doubles-register">Adult Social Doubles</Link>
          </li>
          <li>
            <Link to="/lessons">Adult group lessons</Link>
          </li>
          <li>
            <Link to="/ladder">Adult singles and doubles ladders</Link>
          </li>
          <li>
            <Link to="/juniorcamps">Junior summer camps</Link>
          </li>
          <li>
            <Link to="/junioradvancedlessons">Junior advanced lessons</Link>
          </li>
        </ul>
      </Section>
    </>
  )
}

export default RegisterStep4Details
