import Axios from 'axios'
import _ from 'lodash'
import { config } from '../util/config'

const publicToken =
  '1db64352335204ab7f18c2a3d37f1abb7f8d52ba5c32995ef1eeb10aced58ec96662c48f138bc48636bf741fcf4ebc49044347cc381b83341018b5811f72f28ddb7312a9398431f8f3f16785b0ce308bd3dd2d34c1c2ac369101a64cbb071b61b2115c30648188429b3c778d93b702407b72d9a2f409385c9b6d0fcf14f46c8e'

const axios = Axios.create({ baseURL: config.API_REST_ENDPOINT })

axios.interceptors.request.use(function (config) {
  if (!config.noauth) {
    const token = localStorage.getItem('authToken') || publicToken
    // Don't override the auth header if a request has set a specific one.
    if (_.isNil(config?.headers?.Authorization)) {
      config.headers.Authorization = token ? `Bearer ${token}` : ''
    }
  }
  return config
})

export default axios
