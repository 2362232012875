import { Button, Form, Input, Space, Typography } from 'antd'
import { ERRORS, transformServerError } from '../../util/errorUtil'
import { useContext, useState } from 'react'

import AppContext from '../common/AppContext'
import { EmailLink } from '../common/EmailLink'
import Section from '../common/Section'
import axios from '../../axios/axios'
import { notifySuccess } from '../../util/notifyUtil'
import { useNavigate } from 'react-router-dom'
import useQueryParams from '../../hooks/useQueryParams'

const { Text, Paragraph } = Typography

const passwordRule = [
  {
    whitespace: true,
    required: true,
    min: 6,
    message: `Password must be at least 6 characters`,
  },
]

const ResetPassword = () => {
  const { login } = useContext(AppContext)
  const navigate = useNavigate()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const queryParams = useQueryParams()
  const code = queryParams.get('code')

  const doResetPassword = async (fields) => {
    try {
      setLoading(true)
      const result = await axios.post('/auth/reset-password', { ...fields, code }, { noauth: true })
      login(result.data)
      notifySuccess('Password reset', 'Your password was reset successfully.')
      navigate('/')
    } catch (e) {
      console.error('Error reseting password', e)
      const { code, description } = transformServerError(e)
      if (!code || code === ERRORS.UNKNOWN) {
        setError('Sorry, an unknown error occurred while trying to reset your password.')
      } else {
        setError(description)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Section title="Reset password">
        {error ? (
          <>
            <Paragraph type="danger">{error}</Paragraph>
            <Paragraph>
              Please try again, or <EmailLink email="webadmin">contact us</EmailLink> for assistance.
            </Paragraph>
          </>
        ) : (
          <Paragraph>Enter your new password below.</Paragraph>
        )}
        <div style={{ maxWidth: '20em' }}>
          <Form onFinish={doResetPassword} layout="vertical">
            <Form.Item name="password" required={false} rules={passwordRule}>
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item name="passwordConfirmation" required={false} rules={passwordRule}>
              <Input.Password placeholder="Password (confirm)" />
            </Form.Item>
            <Space direction="vertical">
              <Space direction="horizontal">
                <Form.Item>
                  <Button htmlType="submit" type="primary" loading={loading}>
                    Reset password
                  </Button>
                </Form.Item>
              </Space>
            </Space>
          </Form>
        </div>
      </Section>
    </>
  )
}

export default ResetPassword
