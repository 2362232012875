import { Button, Typography } from 'antd'

import { EmailLink } from '../common/EmailLink'
import { Link } from 'react-router-dom'
import Section from '../common/Section'

const { Paragraph, Title, Text } = Typography

const LadderPage = () => {
  return (
    <>
      <Section title="Ladders">
        <Paragraph>
          If you have questions about this program, please send an email to <EmailLink email="ladder" />. For more
          information on the application we for HTC Ladders, see the{' '}
          <a href="https://tennisrungs.com/" target="_blank" rel="noreferrer">
            TennisRungs
          </a>{' '}
          website.
        </Paragraph>
        <Title level={2}>Singles Ladder</Title>
        <Paragraph>
          Our singles ladder is a co-ed ladder in which club members of all skill levels (beginner through advanced) can
          participate. Participants challenge each other, reserving court time at their mutual convenience, and climb
          the ladder with each win.
        </Paragraph>
        <Title level={2}>Doubles Ladder</Title>
        <Paragraph>
          Our doubles ladder allows for competition between men's teams, ladies' teams, and mixed teams, all in one
          ladder. This year, for the first time, individuals registering for the doubles ladder will not be required to
          have a partner, but will compete with one or more doubles ladder participants of their choosing. Doubles
          ladders standings will be based on individual scores rather than team scores. In this way you can try out many
          team combinations to find your best fit for availability, skill level, play style, personality, etc.
        </Paragraph>

        <Title level={2}>TennisRungs App</Title>
        <Paragraph>
          HTC Ladders are managed using the{' '}
          <a href="https://tennisrungs.com/" target="_blank" rel="noreferrer">
            TennisRungs
          </a>{' '}
          software (via the website or mobile app).
        </Paragraph>
        <Paragraph>
          The TennisRungs mobile app does not have a built-in chat feature but it does allow participants to submit
          challenges to each other. Participants should coordinate match times and dates via text, phone, or email
          (contact info will be shared among ladder participants).
        </Paragraph>
        <Paragraph>Ladder participants are responsible for recording their own score using TennisRungs.</Paragraph>
        <Paragraph>
          Club tennis balls (from a club hopper) are available for use anytime the clubhouse is open. Please be sure to
          return the balls before you leave and before the clubhouse is closed for the day.
        </Paragraph>
        <Paragraph>
          Shortly following receipt of your payment and registration information (detailed below) you will receive an
          invite to the applicable HTC ladder(s) via email.
        </Paragraph>
        <Title level={2}>Register</Title>
        <Paragraph></Paragraph>
        {/* <Button
            type="primary"
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdTPEGpSNEpEy5rCUSfS1AK5T7il2asq8IxLecLVXGhP49HjA/viewform"
            style={{ color: 'white' }}
          >
            Sign up
          </Button> */}
        <Paragraph>
          To particpate in one or both ladders (including multiple teams on the doubles ladder), send an{' '}
          <b>e-transfer for $10</b> to <EmailLink email="pay" />
        </Paragraph>
        <Paragraph>
          <b>Returning members</b> who have played on the ladder in a prior year can then join the 2024 singles and/or
          doubles ladders on the{' '}
          <a href="https://tennisrungs.com/" target="_blank" rel="noreferrer">
            TennisRungs
          </a>{' '}
          site or app.
        </Paragraph>
        <Paragraph>
          <b>New members</b> must first sign up at TennisRungs before joining our ladders. The "Join code" in the
          registration form is the HTC gate code for this season.
          <Button type="link" target="_blank" href="https://app.tennisrungs.com/Public/Register/122440318">
            New member sign up
          </Button>
        </Paragraph>
      </Section>
    </>
  )
}

export default LadderPage
