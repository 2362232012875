import { Button, Col, Row, Typography } from 'antd'
import { INTERURBAN_TRYOUTS, INTERURBAN_TRYOUTS_TIME } from '../../constants'

import { INTERURBAN_SCHEDULE_URL } from '../programs/InterurbanSchedule'
import { Link } from 'react-router-dom'
import Section from '../common/Section'

const { Paragraph } = Typography

const LaddersSection = () => {
  return (
    <>
      <Section title="Interurban" level={2}>
        <Paragraph>
          Interurban is a competitive doubles travel league, playing matches with other clubs in the region.{' '}
        </Paragraph>
        <Paragraph>
          {/* <Button className="padRight" target="_blank" href={INTERURBAN_SCHEDULE_URL} type="link">
            Results and schedule
          </Button> */}
          {/* Tryouts for the team will be held{' '}
          <b>
            {INTERURBAN_TRYOUTS}, {INTERURBAN_TRYOUTS_TIME}
          </b> 
          <br />*/}
          <Link className="padRight" to="/interurban">
            {' '}
            <Button className="padRight" type="link">
              More details
            </Button>
          </Link>
        </Paragraph>
      </Section>
    </>
  )
}

export default LaddersSection
