import AppContext from './AppContext'
import { Button } from 'antd'
import { useContext } from 'react'

const LoginLink = (props) => {
  const { setLoginVisible } = useContext(AppContext)

  return (
    <Button className="noPad" type="link" onClick={setLoginVisible}>
      {props.children || <>Login</>}
    </Button>
  )
}

export default LoginLink
