import { Typography } from 'antd'
const { Paragraph } = Typography

const Json = ({ object, visible = true }) =>
  !visible || !object ? null : (
    <Paragraph code copyable style={{ paddingTop: '10px' }}>
      {JSON.stringify(object)}
    </Paragraph>
  )

export default Json
