import { Table, Typography } from 'antd'

import _ from 'lodash'

export const INTERURBAN_SCHEDULE_URL =
  'https://www.waterlootennis.com/wp-content/uploads/2024/08/IU-Schedule-Results-2024-Finals-Week-2.pdf'

const { Text } = Typography
const InterurbanSchedule = () => {
  const TEAMS = {
    HESPELER: 'Hespeler',
    GREYSTONE: 'Greystone',
    GUEPLPH: 'Guelph',
    WATERLOO: 'Waterloo',
    CAMBRIDGE: 'Cambridge',
    CUTTEN: 'Cutten',
  }

  // prettier-ignore
  const data = [
    { week: 1, date: 'May 16', homeTeam: TEAMS.GREYSTONE, awayTeam: TEAMS.HESPELER, homeSets: 13, awaySets: 3, homeGames: 85, awayGames: 47, },
    { week: 1, date: 'May 16', homeTeam: TEAMS.GUEPLPH, awayTeam: TEAMS.CAMBRIDGE, homeSets: 16, awaySets:0, homeGames:96, awayGames:16, },
    { week: 1, date: 'May 16', homeTeam: TEAMS.CUTTEN, awayTeam: TEAMS.WATERLOO, homeSets:0, awaySets:6, homeGames:10, awayGames:36, note:"Men only" },

    { week: 2, date: 'May 23', homeTeam: TEAMS.HESPELER, awayTeam: TEAMS.GUEPLPH, homeSets: 3, awaySets: 13, homeGames:56, awayGames: 87, },
    { week: 2, date: 'May 23', homeTeam: TEAMS.WATERLOO, awayTeam: TEAMS.GREYSTONE, homeSets: 5, awaySets: 11, homeGames: 59, awayGames: 79, },
    { week: 2, date: 'May 23', homeTeam: TEAMS.CAMBRIDGE, awayTeam: TEAMS.CUTTEN, homeSets: 4, awaySets: 2, homeGames: 34, awayGames: 29,  note:"Men only"},

    { week: 3, date: 'May 30', homeTeam: TEAMS.CAMBRIDGE, awayTeam: TEAMS.HESPELER, homeSets: 1, awaySets: 15, homeGames: 59, awayGames: 74, },
    { week: 3, date: 'May 30', homeTeam: TEAMS.CUTTEN, awayTeam: TEAMS.GREYSTONE, homeSets: 4, awaySets: 2, homeGames: 56, awayGames: 86, note:"Men only" },
    { week: 3, date: 'May 30', homeTeam: TEAMS.GUEPLPH, awayTeam: TEAMS.WATERLOO, homeSets: 15, awaySets: 1, homeGames: 96, awayGames: 46, },

    { week: 4, date: 'June 6', homeTeam: TEAMS.HESPELER, awayTeam: TEAMS.CUTTEN, homeSets: 6, awaySets: 0, homeGames:36, awayGames: 11,  note:"Men only"},
    { week: 4, date: 'June 6', homeTeam: TEAMS.WATERLOO, awayTeam: TEAMS.CAMBRIDGE, homeSets: 15, awaySets: 1, homeGames: 96, awayGames: 46, },
    { week: 4, date: 'June 6', homeTeam: TEAMS.GREYSTONE, awayTeam: TEAMS.GUEPLPH, homeSets: 6, awaySets: 10, homeGames: 66, awayGames: 81, },

    { week: 5, date: 'June 13', homeTeam: TEAMS.HESPELER, awayTeam: TEAMS.WATERLOO, homeSets: null, awaySets: null, homeGames: null, awayGames: null, note:"Rainout" },
    { week: 5, date: 'June 13', homeTeam: TEAMS.CAMBRIDGE, awayTeam: TEAMS.GREYSTONE, homeSets: null, awaySets: null, homeGames: null, awayGames: null, note:"Rainout" },
    { week: 5, date: 'June 13', homeTeam: TEAMS.CUTTEN, awayTeam: TEAMS.GUEPLPH, homeSets:1, awaySets: 5, homeGames: 19, awayGames: 33,  note:"Men only"},

    { week: 6, date: 'June 20', homeTeam: TEAMS.HESPELER, awayTeam: TEAMS.GREYSTONE, homeSets: 9, awaySets: 7, homeGames:72, awayGames:67, },
    { week: 6, date: 'June 20', homeTeam: TEAMS.WATERLOO, awayTeam: TEAMS.CUTTEN, homeSets: 6, awaySets: 0, homeGames: 36, awayGames:8,  note:"Men only"},
    { week: 6, date: 'June 20', homeTeam: TEAMS.CAMBRIDGE, awayTeam: TEAMS.GUEPLPH, homeSets:0, awaySets: 16, homeGames: 36, awayGames: 8, },

    { week: 7, date: 'June 27', homeTeam: TEAMS.GUEPLPH, awayTeam: TEAMS.HESPELER, homeSets: null, awaySets: null, homeGames: null, awayGames: null, },
    { week: 7, date: 'June 27', homeTeam: TEAMS.CUTTEN, awayTeam: TEAMS.CAMBRIDGE, homeSets: null, awaySets: null, homeGames: null, awayGames: null, note:"Men only" },
    { week: 7, date: 'June 27', homeTeam: TEAMS.GREYSTONE, awayTeam: TEAMS.WATERLOO, homeSets: null, awaySets: null, homeGames: null, awayGames: null, },

    { week: 8, date: 'July 4', homeTeam: TEAMS.HESPELER, awayTeam: TEAMS.CAMBRIDGE, homeSets: null, awaySets: null, homeGames: null, awayGames: null, },
    { week: 8, date: 'July 4', homeTeam: TEAMS.GREYSTONE, awayTeam: TEAMS.CUTTEN, homeSets: null, awaySets: null, homeGames: null, awayGames: null,  note:"Men only"},
    { week: 8, date: 'July 4', homeTeam: TEAMS.WATERLOO, awayTeam: TEAMS.GUEPLPH, homeSets: null, awaySets: null, homeGames: null, awayGames: null, },

    { week: 9, date: 'July 11', homeTeam: TEAMS.CUTTEN, awayTeam: TEAMS.HESPELER, homeSets: null, awaySets: null, homeGames: null, awayGames: null, note:"Men only" },
    { week: 9, date: 'July 11', homeTeam: TEAMS.CAMBRIDGE, awayTeam: TEAMS.WATERLOO, homeSets: null, awaySets: null, homeGames: null, awayGames: null, },
    { week: 9, date: 'July 11', homeTeam: TEAMS.GUEPLPH, awayTeam: TEAMS.GREYSTONE, homeSets: null, awaySets: null, homeGames: null, awayGames: null, },

    { week: 10, date: 'July 18', homeTeam: TEAMS.WATERLOO, awayTeam: TEAMS.HESPELER, homeSets: null, awaySets: null, homeGames: null, awayGames: null, },
    { week: 10, date: 'July 18', homeTeam: TEAMS.GREYSTONE, awayTeam: TEAMS.CAMBRIDGE, homeSets: null, awaySets: null, homeGames: null, awayGames: null, },
    { week: 10, date: 'July 18', homeTeam: TEAMS.GUEPLPH, awayTeam: TEAMS.CUTTEN, homeSets: null, awaySets: null, homeGames: null, awayGames: null,  note:"Men only"},

    { week: 11, date: 'July 25', homeTeam: "Rain date", awayTeam: "Rain date", homeSets: null, awaySets: null, homeGames: null, awayGames: null, },
    { week: 11, date: 'July 25', homeTeam: "Rain date", awayTeam: "Rain date", homeSets: null, awaySets: null, homeGames: null, awayGames: null, },
    { week: 11, date: 'July 25', homeTeam: "Rain date", awayTeam: "Rain date", homeSets: null, awaySets: null, homeGames: null, awayGames: null, },

    { week: 12, date: 'Aug 1', homeTeam: "Semifinal 1" , awayTeam:"Semifinal 1", homeSets: null, awaySets: null, homeGames: null, awayGames: null, },
    { week: 12, date: 'Aug 1', homeTeam: "Semifinal 2", awayTeam:"Semifinal 2", homeSets: null, awaySets: null, homeGames: null, awayGames: null, },
    { week: 12, date: '', homeTeam: "\u00A0", awayTeam: "\u00A0", homeSets: null, awaySets: null, homeGames: null, awayGames: null, },

    { week: 13, date: 'Aug 8', homeTeam: "3rd place match" , awayTeam: "3rd place match", homeSets: null, awaySets: null, homeGames: null, awayGames: null, },
    { week: 13, date: 'Aug 8', homeTeam: "Final" , awayTeam: "Final", homeSets: null, awaySets: null, homeGames: null, awayGames: null, },
  ]

  const renderTeam = (record, isHome) => {
    const team = isHome ? record?.homeTeam : record?.awayTeam
    const { homeSets, awaySets, homeGames, awayGames } = record ?? {}
    if (!_.isNumber(homeSets)) {
      return team
    }
    const homeTeamWon = homeSets !== awaySets ? homeSets > awaySets : homeGames > awayGames
    return (homeTeamWon && isHome) || (!homeTeamWon && !isHome) ? <Text strong>{team}</Text> : <Text>{team}</Text>
  }

  const renderWeek = (text, record, index) => (index % 3 === 0 ? text : null)
  const renderScore = (record, isHome) => {
    const sets = isHome ? record?.homeSets : record?.awaySets
    const games = isHome ? record?.homeGames : record?.awayGames
    if (!_.isNumber(games)) {
      return null
    }
    return (
      <>
        {sets} <Text italic>({games})</Text>
      </>
    )
  }

  const renderHome = (value, record) => renderScore(record, true)
  const renderAway = (value, record) => renderScore(record, false)

  const columns = [
    { title: 'Week', dataIndex: 'week', width: '4em', render: renderWeek },
    { title: 'Date', dataIndex: 'date', width: '5em', render: renderWeek },
    {
      title: 'Away team',
      dataIndex: 'awayTeam',
      width: '8em',
      render: (text, record) => renderTeam(record, false),
      align: 'right',
    },
    {
      title: '',
      width: '2em',
      render: (text, record) => (record.date?.length > 0 ? '@' : ' '),
      align: 'center',
    },
    { title: 'Home team', dataIndex: 'homeTeam', width: '8em', render: (text, record) => renderTeam(record, true) },
    {
      title: (
        <>
          Away
          <br />
          Sets (Games)
        </>
      ),
      dataIndex: 'awaySets',
      width: '8em',
      render: renderAway,
      align: 'center',
    },
    {
      title: (
        <>
          Home
          <br />
          Sets (Games)
        </>
      ),
      dataIndex: 'homeSets',
      width: '8em',
      render: renderHome,
      align: 'center',
    },
    { title: 'Note', dataIndex: 'note', width: '10em' },
  ]

  return (
    // <Table
    //   style={{ maxWidth: '60em' }}
    //   size="small"
    //   columns={columns}
    //   dataSource={data}
    //   rowClassName={(record) => (record.week % 2 === 0 ? 'interurbanScheduleRowEven' : 'interurbanScheduleRowOdd')}
    //   pagination={false}
    //   scroll={{ y: 500 }}
    // />
    <>
      <object data={INTERURBAN_SCHEDULE_URL} width="100%" height="800">
        Interurban schedule and results
      </object>
      Open in new window:{' '}
      <a target="_blank" rel="noreferrer" href={INTERURBAN_SCHEDULE_URL}>
        Interurban schedule and results
      </a>
    </>
  )
}

export default InterurbanSchedule
