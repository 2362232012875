import Section from '../common/Section'
import { Typography } from 'antd'
import bylawDoc from '../../assets/docs/HTC-bylaw-No-1.pdf'
import pdfIcon from '../../assets/images/icon-pdf.png'
const { Paragraph } = Typography

const IncorporationPage = () => {
  return (
    <>
      <Section title="Not-for-Profit Incorporation">
        <Paragraph>
          On April 25, 2013 the Hespeler Tennis Club was incorporated as a not for profit corporation under the Ontario
          Corporations Act. The current board of directors has passed an initial by-law which outlines how the
          corporation is to operate. In order to be effective, this bylaw must be ratified by at least 2/3rds of the
          voting members of the tennis club who attend at the first annual general meeting.
        </Paragraph>
        <Paragraph>
          Voting members include:
          <ol>
            <li>All individuals who are at least 18 years of age and who have paid their annual membership dues;</li>
            <li>
              All individuals who are at least 18 years of age and who are employed directly or indirectly as coaches by
              the Corporation; and
            </li>
            <li>
              All representatives of such corporations, partnerships and other legal entities s are admitted as members
              by the Board.
            </li>
          </ol>
        </Paragraph>

        <Paragraph>
          A copy of the bylaw can be found here: <img style={{ verticalAlign: 'middle' }} src={pdfIcon} alt="" />{' '}
          <a target="_blank" rel="noreferrer" href={bylawDoc}>
            HTC By-Law #1
          </a>
        </Paragraph>
      </Section>
    </>
  )
}

export default IncorporationPage
