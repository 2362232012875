import { Alert, Button, Col, Form, Row, Typography } from 'antd'
import { getDates, getTimes, isLastMinuteEnabledForCurrentWeek } from '../../util/adultLessonUtil'
import { hideAllNotifications, notifyError, notifySuccess, notifyWarn } from '../../util/notifyUtil'
import { isBefore, isThursday as isLessonDay, isSameDay, nextThursday as nextLessonDay, parseISO, set } from 'date-fns'
import { useCallback, useContext, useEffect, useState } from 'react'

import AppContext from '../common/AppContext'
import DateSelectInput from '../common/DateSelectInput'
import { InvalidTimezone } from '../error/InvalidTimezone'
import LessonDeleteConfirmationModal from './LessonDeleteConfirmationModal'
import LoginLink from '../common/LoginLink'
import TimeSelectInput from '../common/TimeSelectInput'
import _ from 'lodash'
import axios from '../../axios/axios'
import { formatDateTime } from '../../util/dateTimeUtil'

const { Paragraph, Title, Text } = Typography

const colProps = { xs: 24, sm: 10, lg: 8 }

const MAX_PER_USER = 3
const DISABLED = true
// const now = set(new Date(), { date: 17, hours: 17, minutes: 56 })
const now = new Date()

const AdultLessonSignup = () => {
  const [form] = Form.useForm()
  const [cancelConfirmationModalVisible, setCancelConfirmationModalVisible] = useState(false)
  const [selectedToCancel, setSelectedToCancel] = useState()
  const [, setLoading] = useState(false)
  const [, setError] = useState()
  const [stats, setStats] = useState()
  const [dates, setDates] = useState()
  const [times, setTimes] = useState()
  const [signupsCount, setSignupsCount] = useState()
  const [remainingSignupsCount, setRemainingSignupsCount] = useState()
  const [myLessons, setMyLessons] = useState()
  const { currentUser, validTimezone } = useContext(AppContext)

  const fetchStats = async (onlyLastMinute) => {
    const result = await axios.get('/adult-lesson-signup-stats')
    setStats(result.data)
    const dates = getDates(result.data, now, onlyLastMinute)
    setDates(dates)
  }

  const fetchOwn = async () => {
    try {
      const result = await axios.get('/my/adult-lesson-signups')
      console.log(JSON.stringify(result))
      const lessons = _.sortBy(result.data, 'date')
      setMyLessons(lessons)
      const nonLastMinuteSignups = _.filter(lessons, (l) => !l.isLastMinute)
      setSignupsCount(_.size(nonLastMinuteSignups))
      let remaining = MAX_PER_USER - _.size(nonLastMinuteSignups)
      remaining = remaining > 0 ? remaining : 0
      setRemainingSignupsCount(remaining)
      fetchStats(remaining === 0)
    } catch (e) {
      notifyError('msg', e)
    } finally {
    }
  }

  const refresh = () => {
    if (currentUser) {
      fetchOwn()
    }
  }

  useEffect(() => {
    ;(async () => {
      if (currentUser) {
        fetchOwn()
      }
    })()
  }, [currentUser])

  const handleDateChanged = (dateKey) => {
    const times = getTimes(dateKey, stats, now)
    setTimes(times)
  }

  const handleTimeChanged = (timeKey) => {}

  const onFinish = (values) => {
    const { date, timeslot } = values
    const isLastMinute = _.find(dates, (d) => d.key === date)?.isLastMinute
    const existing = _.filter(myLessons, (l) => l.date === date && l.timeslot === timeslot)
    if (_.size(existing) > 0) {
      notifyWarn('msg', "You're already signed-up for this lesson.")
      return
    }

    const signup = { ...values, isLastMinute }

    axios.post('/my/adult-lesson-signup', { data: signup }).then(
      (result) => {
        notifySuccess('msg', 'Successfully signed up', <>You've successfully signed up for this lesson.</>, 10)
        form.setFieldsValue({ date: undefined, timeslot: undefined })
        setLoading(false)
        refresh()
      },
      (e) => {
        setError(e)
        notifyError('msg', e)
        setLoading(false)
      },
    )
  }

  let nextLessonDate = isLessonDay(now) ? now : nextLessonDay(now)
  let cancelCutoffTime = set(nextLessonDate, { hours: 18, minutes: 0, seconds: 0 }) // 6pm today

  const mayCancel = (signup) => {
    const lessonDate = parseISO(signup.date)
    if (isSameDay(lessonDate, now) && isBefore(now, cancelCutoffTime)) {
      // Can cancel same day, up to the cut off time.
      return true
    } else if (isBefore(lessonDate, now)) {
      // Can't cancel past lessons.
      return false
    }
    return true
  }

  const handleCancel = () => {
    // notifyInfo('msg', 'Cancelled ' + JSON.stringify(selectedToCancel))
    axios.delete(`/my/adult-lesson-signup/${selectedToCancel.id}`, {}).then(
      (result) => {
        notifySuccess('msg', 'Lesson signup cancelled', <>You've cancelled this lesson signup.</>, 4)
        setCancelConfirmationModalVisible(false)
        setSelectedToCancel(null)
        refresh()
      },
      (e) => {
        notifyError('msg', e)
      },
    )
    setCancelConfirmationModalVisible(false)
  }

  const showCancelConfirmation = (signup) => {
    hideAllNotifications()
    setSelectedToCancel(signup)
    setCancelConfirmationModalVisible(true)
  }

  const getDeleteLink = (signup) => {
    return !mayCancel(signup) ? null : (
      <Button type="link" onClick={() => showCancelConfirmation(signup)}>
        Cancel
      </Button>
    )
  }

  const getTimeslotText = (timeslot) => {
    if (timeslot === 'early') {
      return '7:15pm'
    } else if (timeslot === 'late') {
      return '8:30pm'
    }
  }

  // const disabled = DISABLED && currentUser?.username !== 'mason'

  return (
    <>
      <Title level={2}>Sign up for lessons</Title>
      <Paragraph>
        <ul>
          <li>
            To give everyone fair access to these lessons, you can{' '}
            <b>sign up in advance for at most {MAX_PER_USER} sessions</b>. This may later be increased based on demand.
          </li>
          <li>
            If spots remain for the upcoming week, you can sign up for these on the Monday, Tuesday, or Wednesday of
            that week as a <b>"last-minute" signup</b>, which won't count towards your allowed {MAX_PER_USER} spots for
            the season.
          </li>
          <li>You may cancel a signup up to 6pm of the lesson date, but please avoid late cancellations.</li>
        </ul>
      </Paragraph>
      {!currentUser ? (
        <Paragraph>
          Please <LoginLink>log in</LoginLink> to sign up for the adult lessons.
        </Paragraph>
      ) : !validTimezone ? (
        <InvalidTimezone />
      ) : (
        <>
          <Alert
            type="warning"
            message="(2024-05-11) Lesson signups made before May 6 have been removed"
            description={
              <>
                <Paragraph>
                  Unfortunately an earlier bug in the signups caused some lessons to be overbooked, or to have people
                  booked for the wrong times. Because of this,{' '}
                  <b>we decided to erase all signups made prior to May 6</b>, and have members sign up again. This was
                  communicated to the membership via email on May 6.
                </Paragraph>
                <Paragraph>
                  To give all adult members a chance to participate in these lessons, you will now also be restricted to
                  signing up for at most 3 lessons at once. This may be increased later in the season. As before, if any
                  spots remain open on the Monday, Tuesday, or Wednesday of a lesson week, you can sign up then as a
                  "last-minute" signup, which won't count towards your 3-lesson limit.
                </Paragraph>
                {/* <Paragraph>
                Signups will reopen on <b>Saturday May 11.</b>
              </Paragraph> */}
              </>
            }
          />
          <br />
          <Form
            name="adult-lesson-details"
            layout="vertical"
            size="small"
            form={form}
            onFinish={onFinish}
            className="form"
          >
            {_.size(myLessons) === 0 ? (
              <Paragraph>You haven't signed up for any lessons yet.</Paragraph>
            ) : (
              <>
                <Paragraph>
                  {_.size(myLessons) > 0 && (
                    <Text>
                      You've signed up for the following {signupsCount} {signupsCount === 1 ? 'lesson' : 'lessons'},{' '}
                      {remainingSignupsCount > 0 ? (
                        <>
                          so you can sign up in advance{' '}
                          {remainingSignupsCount === 1
                            ? 'for one more lesson'
                            : `for ${remainingSignupsCount} more lessons`}
                          .
                        </>
                      ) : (
                        <>so you can't sign up in advance for any additional lessons.</>
                      )}
                    </Text>
                  )}
                </Paragraph>
                <ul>
                  {myLessons.map((lesson) => {
                    return (
                      <li key={lesson.id}>
                        <b>{formatDateTime(lesson.date, 'EE MMMM d')}</b> @ {getTimeslotText(lesson.timeslot)}{' '}
                        {lesson.isLastMinute ? (
                          <>
                            <i>(last-minute signup)</i>
                          </>
                        ) : (
                          ''
                        )}
                        {getDeleteLink(lesson)}
                      </li>
                    )
                  })}
                </ul>
              </>
            )}

            {(remainingSignupsCount > 0 || isLastMinuteEnabledForCurrentWeek(now)) && (
              <>
                <Row gutter={10} align="middle">
                  <Col xs={24} sm={10} md={12} lg={7}>
                    <DateSelectInput label="Week" dates={dates} name="date" onChange={handleDateChanged} />
                  </Col>
                  <Col {...colProps}>
                    <TimeSelectInput times={times} name="timeslot" onChange={handleTimeChanged} />
                  </Col>
                  <Col flex="1">
                    <Button type="primary" htmlType="submit" size="middle">
                      Sign up
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form>
          <LessonDeleteConfirmationModal
            visible={cancelConfirmationModalVisible}
            onOk={handleCancel}
            onClose={() => setCancelConfirmationModalVisible(false)}
          />
        </>
      )}
    </>
  )
}

export default AdultLessonSignup
