import {
  UserAddOutlined as AddUserIcon,
  LeftOutlined as BackIcon,
  RightOutlined as NextIcon,
  ExclamationCircleOutlined as WarningIcon,
} from '@ant-design/icons'
import { Button, Col, Divider, Form, Input, Row, Typography } from 'antd'

import { EMAIL_RULES } from '../../util/commonUtil'
import { useEffect } from 'react'

const { Text, Paragraph, Title } = Typography

const colProps = { xs: 24, sm: 12, lg: 12, xl: 9 }
const required = [{ whitespace: true, required: true, message: '' }]

const RegisterStep1Details = ({ registration, setRegistration, onNext }) => {
  const onValuesChange = (updated, all) => {
    setRegistration({ ...registration, ...updated })
  }

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(registration)
  }, [form, registration])

  const onFinish = (values) => {
    setRegistration({ ...registration, ...values })
    onNext()
  }

  const spacerCol = <Col xs={0} lg={0} xl={6}></Col>

  return (
    <>
      {/* {registration.isReturning ? (
        <Paragraph>
          <Text strong>You are registering as a returning member</Text> and will receive the returning member discount.
        </Paragraph>
      ) : (
        <Paragraph>
          <WarningIcon style={{ fontSize: '1.2em' }} />
          <Text strong style={{ paddingLeft: '.8em', paddingRight: '.8em' }}>
            You are registering as a new member.
          </Text>
          <Text>
            Returning members must use the link emailed to them on April 9 to get the returning member discount.
          </Text>
        </Paragraph>
      )} */}
      <Paragraph>
        For <i>adult</i>, or <i>senior</i>, or <i>junior</i> registrations, enter the member's (or guardian's) contact
        information. For <i>twosome</i> or <i>family</i> memberships, enter the primary contact's information.
      </Paragraph>
      <Form name="registration-details" layout="vertical" size="small" form={form} onFinish={onFinish}>
        <Row gutter={10}>
          <Col {...colProps}>
            <Form.Item label="Last name" name="lastName" rules={required}>
              <Input />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item label="Email" name="email" rules={EMAIL_RULES}>
              <Input />
            </Form.Item>
          </Col>
          {spacerCol}
          <Col {...colProps}>
            <Form.Item label="Phone" name="phone" rules={required}>
              <Input />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item label="Phone (alternate)" name="phone2">
              <Input />
            </Form.Item>
          </Col>
          {spacerCol}
          <Col xs={24} sm={12} md={8} lg={8} xl={6}>
            <Form.Item label="Street" name="street" rules={required}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={6}>
            <Form.Item label="City" name="city" rules={required}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={6}>
            <Form.Item label="Postal code" name="postalCode" rules={required}>
              <Input style={{ textTransform: 'uppercase' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" style={{ paddingTop: '2em' }}>
          <Col>
            <Form.Item>
              <Button icon={<NextIcon />} htmlType="submit" type="primary" size="large">
                Add members
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default RegisterStep1Details
