import { add, getDay, getHours, set } from 'date-fns'

import BookingRow from './BookingRow'
import _ from 'lodash'
import { defaultDate } from '../../util/dateTimeUtil'
import { getStartTimes } from '../../util/bookingUtil'

const getHeading = (courtLabel) => {
  return (
    <div className="bookingCourtName" style={{ width: '30%', maxWidthx: '20em' }}>
      {courtLabel}
    </div>
  )
}

const BookingsTable = ({ date, bookings, onAdd, onEdit, onDelete }) => {
  const timeslots = _.sortBy(getStartTimes(date), ['hour', 'minute'])

  return (
    <div className="bookingTable">
      <div style={{ display: 'flex', marginBottom: '1em' }}>
        <div className="bookingTime"></div>
        {getHeading('Court 1')}
        {getHeading('Court 2')}
        {getHeading('Court 3')}
      </div>
      <div className="booking"></div>
      {timeslots.map((timeslot, i) => {
        const next = i < _.size(timeslots) - 1 ? timeslots[i + 1] : null
        return (
          <BookingRow
            key={i}
            time={timeslot.startTime}
            nextTime={next?.startTime}
            duration={timeslot.duration}
            message={timeslot.message}
            span={timeslot.span}
            heightOverride={timeslot.heightOverride}
            bookings={bookings}
            onAdd={onAdd}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        )
      })}
    </div>
  )
}

export default BookingsTable
