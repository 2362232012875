import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Space, Typography } from 'antd'
import { defaultDate, defaultTime, formatDateTime, shortDateTime } from '../../util/dateTimeUtil'

import AppContext from '../common/AppContext'
import _ from 'lodash'
import { useContext } from 'react'
import { useEffect } from 'react'
import useResponsive from '../../hooks/useResponsive'
import { useWindowSize } from '../../hooks/useWindowSize'

const { Text, Paragraph, Title } = Typography

const BookingDeleteConfirmationModal = ({ booking, visible, onOk, onClose }) => {
  return (
    <Modal
      title="Delete booking?"
      onOk={() => onOk(booking)}
      onCancel={onClose}
      onClose={onClose}
      okText="Yes, delete"
      cancelText="Don't delete"
      okButtonProps={{ type: 'primary', danger: true }}
      cancelButtonProps={{ type: 'link' }}
      visible={visible}
      closable={true}
      maskClosable={true}
      keyboard={true}
      destroyOnClose={true}
    >
      {booking && (
        <Paragraph>
          Are you sure you want to delete the booking for <b>{formatDateTime(booking.startTime, 'MMM d')}</b> at{' '}
          <b>{formatDateTime(booking.startTime, 'h:mm a')}</b>?
        </Paragraph>
      )}
    </Modal>
  )
}

export default BookingDeleteConfirmationModal
