import { Col, Row, Typography } from 'antd'
const { Text } = Typography

const CalendarEntry = ({ date, time, note, label }) => {
  return (
    <Row wrap={false}>
      {/* <Col style={{ fontWeight: 'normal', paddingRight: '1em', whiteSpace: 'nowrap', color: '#999' }}>{date}</Col> */}
      <Col>
        {date && (
          <Text
            type="secondary"
            italic
            style={{
              border: '0px solid red',
              display: 'inline-block',
              width: '8em',
              paddingRight: '1em',
              fontSize: '.9em',
            }}
          >
            {date}
          </Text>
        )}
        {time && (
          <Text type="secondary" italic style={{ paddingRight: '1em', whiteSpace: 'nowrap', fontSize: '.8em' }}>
            {time}
          </Text>
        )}
      </Col>
      <Col>{label}</Col>
      {note && (
        <Col>
          <Text type="secondary" italic style={{ paddingLeft: '1em', whiteSpace: 'nowrap', fontSize: '.8em' }}>
            {note}
          </Text>
        </Col>
      )}
    </Row>
  )
}

export default CalendarEntry
