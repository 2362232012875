import _ from 'lodash'
export const transformMarkdownUri = (src, alt, title) => 'http://localhost:1337' + src

export const CURRENT_YEAR = new Date().getFullYear()

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const EMAIL_RULES = [
  { whitespace: true, required: true, pattern: EMAIL_REGEX, message: 'Invalid email address' },
]

export const toNumber = (v) => {
  if (_.isNumber(v)) {
    return v
  } else if (_.isString(v)) {
    let num = Number(v)
    if (!isNaN(num)) {
      num = parseInt(v, 10)
    }
    return isNaN(num) ? null : num
  } else {
    return undefined
  }
}
