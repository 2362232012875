import { Button } from 'antd'

export const FacebookLink = () => (
  <Button
    className="noPad"
    type="link"
    target="_blank"
    href="http://www.facebook.com/pages/Hespeler-Tennis-Club/351162026025"
  >
    Facebook
  </Button>
)

export const TwitterLink = () => (
  <Button className="noPad" type="link" target="_blank" href="http://twitter.com/HespelerTennis">
    Twitter
  </Button>
)

export const InstagramLink = () => (
  <Button className="noPad" type="link" target="_blank" href="http://instagram.com/hespeler_tc?ref=badge">
    Instagram
  </Button>
)

export const MailingListLink = () => (
  <Button className="noPad" type="link" target="_blank" href="http://eepurl.com/cGFasj">
    Mailing list
  </Button>
)
