import { SINGLES_DROPIN_END, SINGLES_DROPIN_START } from '../../constants'

import { EmailLink } from '../common/EmailLink'
import Section from '../common/Section'
import { Typography } from 'antd'

const { Paragraph, Title, Text } = Typography

const DropIns = () => {
  return (
    <>
      <Section title="Weekly Drop-Ins">
        <Title level={2}>Adult Intermediate / Advanced Drop-Ins</Title>
        <Paragraph>Competitive singles tennis for high level intermediate and advanced players. </Paragraph>
        <Paragraph>There's no fee for members to participate. </Paragraph>
        <Paragraph>
          Drop in when you can and play short singles matches (or doubles depending on number of participants){' '}
        </Paragraph>
        <Paragraph>Coaches will be on-site to coordinate matches. </Paragraph>
        <Paragraph>
          Adult Drop-Ins will be held on <b>Sunday evenings, 5:00 - 8:00pm</b> running {SINGLES_DROPIN_START} until{' '}
          {SINGLES_DROPIN_END}.
        </Paragraph>
      </Section>
    </>
  )
}

export default DropIns
