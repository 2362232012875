import {
  PlusCircleOutlined as AddUserIcon,
  LeftOutlined as BackIcon,
  InfoCircleOutlined as InfoIcon,
  RightOutlined as NextIcon,
} from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import { useEffect, useState } from 'react'

import Json from '../common/Json'
import Section from '../common/Section'
import axios from '../../axios/axios'
import { notifyError } from '../../util/notifyUtil'

const PayPalTest = () => {
  const [show, setShow] = useState(false)
  const [success, setSuccess] = useState(false)
  const [ErrorMessage, setErrorMessage] = useState('')
  const [orderID, setOrderID] = useState(false)

  useEffect(() => {
    if (success) {
      alert('Payment successful!!')
    }
  }, [success])

  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: 'Sunflower',
            amount: {
              currency_code: 'CAD',
              value: 0.01,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: 'NO_SHIPPING',
        },
      })
      .then((orderID) => {
        setOrderID(orderID)
        return orderID
      })
  }

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details
      const name = details.payer.name.given_name
      alert(`Transaction completed by ${name}`)
      setSuccess(true)
    })
  }
  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage('An Error occured with your payment ')
  }

  return (
    <Section title="PayPal test">
      <button type="submit" onClick={() => setShow(true)}>
        Buy now
      </button>
      <div>Error? {ErrorMessage}</div>
      {show ? (
        <div style={{ maxWidth: '10em' }}>
          <PayPalButtons
            style={{ layout: 'vertical', color: 'blue' }}
            createOrder={createOrder}
            onApprove={onApprove}
          />
        </div>
      ) : null}
    </Section>
  )
}

export default PayPalTest
