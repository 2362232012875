import { GET } from '../../gql/event.gql'
import { config } from '../../util/config'
import { notifyError } from '../../util/notifyUtil'
import { useQuery } from '@apollo/client'

const useEvents = () => {
  const { data, loading, error } = useQuery(GET, { skip: config.SERVER_DISABLED })
  if (error) notifyError('query-error', error)
  return { events: data?.results?.data, data, loading, error }
}

export default useEvents
