import { Collapse } from 'antd'
import Section from '../common/Section'
import { Table } from 'ant-table-extensions'
import _ from 'lodash'
import { compareStrings } from '../../util/compareUtil'
import { defaultDateTime } from '../../util/dateTimeUtil'
import useRegistrations from '../../hooks/api/useRegistrations'

const flatten = (data) => _.flatMap(data, (data) => ({ id: data.id, ...data.attributes }))

const colProps = (dataIndex) => {
  return {
    sorter: (a, b) => compareStrings(_.get(a, dataIndex), _.get(b, dataIndex)),
  }
}

const RegistrationListPage = () => {
  const { registrations, loading, error } = useRegistrations()

  const columns = [
    { title: 'Row', width: '3em', fixed: 'left', dataIndex: 'id', render: (value, record, index) => <>{index + 1}</> },
    { title: 'ID', width: '3em', dataIndex: 'id', ...colProps('id') },
    { title: 'Registration number', width: '12em', dataIndex: 'registrationNumber', ...colProps('registrationNumber') },
    { title: 'State', dataIndex: 'state', ...colProps('state') },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      ...colProps('createdAt'),
      render: (text, record) => defaultDateTime(text),
    },
    { title: 'Last name', dataIndex: 'lastName', ...colProps('lastName') },
    { title: 'Email', dataIndex: 'email', ...colProps('email') },
    { title: 'Membership type', dataIndex: 'membershipType', ...colProps('membershipType') },
    { title: 'Phone', dataIndex: 'phone', ...colProps('phone') },
    { title: 'Postal code', dataIndex: 'postalCode', ...colProps('postalCode') },
    { title: 'Price', dataIndex: 'price', ...colProps('price') },
    { title: 'Street', dataIndex: 'street', ...colProps('street') },
  ]

  return (
    // <div style={{ overflowx: 'scroll' }}>
    <div>
      <Section title="Registrations">
        <Table
          className="compactTable"
          scroll={{ x: 2000, y: 600 }}
          size="small"
          columns={columns}
          dataSource={flatten(registrations)}
          loading={loading}
          pagination={false}
          exportable
          exportableProps={{ showColumnPicker: true, btnProps: { type: 'link' } }}
          searchable
        />
      </Section>
    </div>
  )
}

export default RegistrationListPage
