import {
  PlusCircleOutlined as AddUserIcon,
  LeftOutlined as BackIcon,
  InfoCircleOutlined as InfoIcon,
  RightOutlined as NextIcon,
} from '@ant-design/icons'
import { Alert, Button, Checkbox, Col, Divider, Row, Space, Tooltip, Typography } from 'antd'
import { notifyError, notifyInfo, notifyWarn } from '../../util/notifyUtil'
import { useEffect, useState } from 'react'

import { EmailLink } from '../common/EmailLink'
import { Link } from 'react-router-dom'
import { PayPalButtons } from '@paypal/react-paypal-js'
import RegistrationSummary from './RegistrationSummary'
import _ from 'lodash'
import axios from '../../axios/axios'

const { Paragraph, Title, Text } = Typography

const RegisterStep3Details = ({ registration, setRegistration, onPrevious, onNext }) => {
  const [pendingRegistration, setPendingRegistration] = useState()
  const [policiesAccepted, setPoliciesAccepted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [paying, setPaying] = useState(false)
  const [success, setSuccess] = useState(false)

  const AlternatePaymentButton = () => (
    <Button type="primary" size="large" onClick={() => alternatePay()}>
      Submit registration
    </Button>
  )

  useEffect(() => {
    if (success) {
      return
    }
    setLoading(true)
    axios.post('/registration/calc', { data: registration }, { noauth: true }).then(
      (result) => {
        setPendingRegistration(() => result.data)
        setLoading(false)
      },
      (e) => {
        setError(e)
        notifyError('registration-calc-error', e)
        setLoading(false)
      },
    )
  }, [registration, success])

  const alternatePay = async () => {
    try {
      const registrationId = await pay(null, null, true)
      if (registrationId) {
        onApprove(null, null, true, registrationId)
      }
    } catch (e) {
      notifyError('alternate-payment-error', e)
    }
  }

  const pay = async (data, actions, skipPayment = false) => {
    let registrationId
    let price
    try {
      const createOrUpdate = registration.id ? axios.put : axios.post
      const result = await createOrUpdate('/registrations', { data: registration }, { noauth: true })
      registrationId = result.data?.data?.id
      price = result.data?.data?.attributes?.price
      if (!registrationId) {
        notifyError('no-registration-id', `Error processing payment.  Could not determine HTC registration id.`)
        return
      }
      if (!price || price < 0) {
        notifyError('no-registration-price', `Error processing payment.  Could not determine HTC registration price.`)
        return
      }
      console.log(`Setting pending registration to: ${JSON.stringify(result.data.data)}`)
      setPendingRegistration({ members: registration.members, ...result.data.data.attributes })
    } catch (e) {
      setError(e)
      console.error('Pay error', e)
      return
    } finally {
      setLoading(false)
    }

    if (skipPayment) {
      return registrationId
    }
    try {
      return actions.order
        .create({
          purchase_units: [
            {
              description: '2024 Registration',
              reference_id: registrationId,
              amount: {
                // value: '0.01',
                value: price,
              },
            },
          ],
          // not needed if a shipping address is actually needed
          application_context: {
            shipping_preference: 'NO_SHIPPING',
          },
        })
        .then(
          (orderID) => {
            console.log(`Setting orderID to ${orderID}`)
            return orderID
          },
          (e) => {
            console.error('Error caught in promise rejection of actions.order.create: ', e)
          },
        )
    } catch (e) {
      console.error('Error calling actions.order.create: ', e)
    }
  }

  const onApprove = async (data, actions, skipPayment = false, registrationId) => {
    try {
      console.log('onApprove.start')
      let details
      if (skipPayment) {
        details = { skippedPayment: true }
      } else {
        details = await actions.order.capture()
        console.log('onApprove.done actions.order.capture')
        const { payer, purchase_units } = details
        const name = details.payer.name.given_name
        registrationId = purchase_units[0].reference_id
      }
      // alert(`Transaction completed by ${name}`)
      setSuccess(true)
      try {
        console.log('onApprove.about to call /registrations/paid')
        const result = await axios.post(
          '/registration/paid',
          { registrationId, members: registration.members, paymentInfo: details },
          { noauth: true },
        )
        console.log('onApprove.done axios call')
        // Success!
        setRegistration({ price: pendingRegistration.price, ...registration, ...result.data?.data })
        onNext()
      } catch (e) {
        console.log('onApprove.got error: ', e)
        setError(e)
        notifyError('registration-calc-error', e)
      } finally {
        setLoading(false)
      }
    } catch (e) {
      notifyError('paypal-approve-error', e)
    }
  }

  const onError = async (data, actions) => {
    console.error('A PayPal error occurred', data)
    setError(data)
  }

  return (
    <>
      {error && (
        <Alert
          type="error"
          message={
            <>
              Sorry, there was an error processing your registration. Please try again, and contact{' '}
              <EmailLink email="webadmin" /> if the problem persists.
            </>
          }
          style={{ marginBottom: '2em' }}
        />
      )}
      {loading && <>Calculating membership price...</>}
      {pendingRegistration && (
        <>
          <Row justify="space-between">
            <Col span={24}>
              <Title level={2} style={{ marginTop: 0 }}>
                Important information
              </Title>
              <Paragraph>
                Please review the following before completing your registration:{' '}
                <Link to="/policies" target="_blank" style={{ paddingLeft: '1.0em' }}>
                  Policies and procedures
                </Link>
                <Link to="/code-of-conduct" target="_blank" style={{ paddingLeft: '1.0em' }}>
                  Code of conduct
                </Link>
                <Link to="/refund-policy" target="_blank" style={{ paddingLeft: '1.0em' }}>
                  Refund policy
                </Link>
              </Paragraph>
            </Col>
            <Col span={24}>
              <Checkbox onChange={(e) => setPoliciesAccepted(e?.target?.checked)} style={{ fontWeight: 'bold' }}>
                I have read and agree to the terms outlined in the HTC policies and procedures, code of conduct, and
                refund policy.
              </Checkbox>
            </Col>
          </Row>
          {/* {!error && (
            <Paragraph>
              Confirm your registration details below, then click the <i>PayPal</i> button to pay.
            </Paragraph>
          )} */}
          <Divider />
          <Row justify="start">
            <Col>
              <Space direction="vertical" size="middle">
                <Text>
                  <Text strong>Membership type:</Text> <Text>{_.toUpper(pendingRegistration.membershipType)}</Text>{' '}
                  {/* <Text italic>({pendingRegistration.isReturning ? 'returning member' : 'new member'})</Text> */}
                </Text>
                {pendingRegistration.discount > 0 && (
                  <Space>
                    <Text strong>Discount:</Text>
                    <Text>${pendingRegistration.discount}</Text>
                    {pendingRegistration.discountDescription && (
                      <Text italic>({pendingRegistration.discountDescription})</Text>
                    )}
                  </Space>
                )}
                <Space>
                  <Text strong>Total due:</Text>
                  <Text>${pendingRegistration.price}</Text>
                </Space>
              </Space>
            </Col>
            <Col xs={24}>
              <div>
                <Divider />
                <Text strong>Member details:</Text>
                <RegistrationSummary
                  registration={pendingRegistration}
                  editable={false}
                  // setRegistration={setRegistration}
                />
              </div>
            </Col>
          </Row>
        </>
      )}
      <Row justify="space-between" style={{ paddingTop: '2em' }}>
        <Col>
          <Button icon={<BackIcon />} onClick={onPrevious} size="large">
            Back
          </Button>
        </Col>
        <Col>
          {!policiesAccepted ? (
            <Tooltip title="You must agree to the HTC policies and procedures, code of conduct, and refund policy before continuing">
              <Button disabled type="primary" size="large">
                Complete registration
              </Button>
            </Tooltip>
          ) : (
            <>
              {!error && (
                <Space>
                  {registration.isSkipPayment ? (
                    <AlternatePaymentButton />
                  ) : (
                    <PayPalButtons
                      style={{ layout: 'vertical', color: 'gold' }}
                      fundingSource="paypal"
                      createOrder={pay}
                      onApprove={onApprove}
                      onError={onError}
                    />
                  )}
                </Space>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  )
}

export default RegisterStep3Details
