import { Form, Select } from 'antd'

const required = [{ whitespace: true, required: true, message: '' }]

const GenderInput = ({ disabled = false }) => {
  return (
    <Form.Item label="Gender" name="gender" rules={required}>
      <Select disabled={disabled}>
        <Select.Option key="female">Female</Select.Option>
        <Select.Option key="male">Male</Select.Option>
        <Select.Option key="other">Other</Select.Option>
        <Select.Option key="undisclosed">Undisclosed</Select.Option>
      </Select>
    </Form.Item>
  )
}

export default GenderInput
