import { format, formatDistanceToNow } from 'date-fns'

import _ from 'lodash'
import { config } from './config'
import { zonedTimeToUtc } from 'date-fns-tz'

const JSON_TIMESTAMP = 'yyyy-MM-dd HH:mm:ss.SSSSSS'
const JSON_DATE = `yyyy-MM-dd`

export const parseDateTime = (val) => zonedTimeToUtc(val, config.DB_TIMEZONE || 'America/Toronto')

export const formatDateTime = (dateTimeString, formatString) => {
  if (_.isNil(dateTimeString)) {
    return 'N/A'
  } else {
    return format(parseDateTime(dateTimeString), formatString)
  }
}

export const LONG_DATE = 'MMMM d, Y'
export const MEDIUM_DATE = 'MMM d, Y'
export const SHORT_DATE = 'yyyy/MM/dd'
export const LONG_TIME = 'h:mm:ss a'
export const SHORT_TIME = 'h:mm a'
export const LONG_DATETIME = `${LONG_DATE}' at '${LONG_TIME}`
export const MEDIUM_DATETIME = `${MEDIUM_DATE}' '${SHORT_TIME}`
export const SHORT_DATETIME = `${SHORT_DATE}' '${SHORT_TIME}`

// Public format functions.
export const longDateTime = (val) => formatDateTime(val, LONG_DATETIME)
export const defaultDateTime = (val) => formatDateTime(val, MEDIUM_DATETIME)
export const shortDateTime = (val) => formatDateTime(val, SHORT_DATETIME)
export const defaultDate = (val) => formatDateTime(val, MEDIUM_DATE)
export const defaultTime = (val) => formatDateTime(val, LONG_TIME)
export const jsonDateShort = (val) => formatDateTime(val, JSON_DATE)
export const jsonDate = (val) => formatDateTime(val, JSON_TIMESTAMP)

export const durationInWords = (val, addSuffix = true) =>
  _.isNil(val)
    ? 'N/A'
    : formatDistanceToNow(parseDateTime(val), {
        addSuffix,
        includeSeconds: false,
      })
