import { Button, Space, Typography } from 'antd'

import { Link } from 'react-router-dom'
import MembershipPrices from './MembershipPrices'
import Note from '../common/Note'
import Section from '../common/Section'
import { config } from '../../util/config'

const { Text, Paragraph } = Typography

const MembershipPage = () => {
  return (
    <div style={{ maxWidth: '63em' }}>
      <Section title="Registration - 2024 Season">
        <Paragraph>
          Whether you're joining just to get out and play or you would like to get involved with some of our excellent
          adult and children's programming, you'll find what you're looking for at the Hespeler Tennis Club!
        </Paragraph>
        <Paragraph>
          With membership you receive access to all premises of Hespeler Tennis Club, including entry codes for three
          fenced, lighted courts.
        </Paragraph>
      </Section>

      <Section title="Online Registration" level={2}>
        <Paragraph>
          Register online and pay via Paypal. All members will be required to have a 'shoe tag' affixed to their shoe or
          tennis bag. Your registration confirmation email will include instructions about how to pick up your tag.
        </Paragraph>
        {config.REGISTRATION_ENABLED ? (
          <>
            {/* <Paragraph>
              Note that returning members should use the link emailed to them to ensure they pay the returning member
              rates.
            </Paragraph> */}
            <Space align="baseline" direction="horizontal">
              <Link to="/register">
                <Button type="primary">Register now</Button>
              </Link>
            </Space>
          </>
        ) : (
          <Paragraph strong>Online registration for the upcoming season will open on April 1.</Paragraph>
        )}
      </Section>

      <Section title="In-Person Registration" level={2}>
        <Paragraph>There will be no in-person registration.</Paragraph>
      </Section>

      <Section title="Pricing" level={2}>
        <MembershipPrices />
      </Section>

      <Section title="Adult Members" level={2}>
        <Text>Benefits of membership:</Text>
        <ul>
          <li>Access to singles and doubles ladders</li>
          <li>Access to adult social doubles league</li>
          <li>6 free group lessons throughout the summer with our head pro</li>
          <li>Access to several tournaments and festivals during the summer (nominal fee)</li>
          <li>Gate code to access courts on your own time</li>
          <li>No court fees</li>
        </ul>
      </Section>

      <Section title="Junior Members" level={2}>
        <Text>Benefits of membership:</Text>
        <ul>
          <li>6 weeks of after-school lessons in the spring included with membership</li>
          <li>Access to 3 fully-staffed junior tournaments during the summer</li>
          <li>Access to several tournaments and festivals during the summer (nominal fee)</li>
          <li>Gate code to access courts on your own time</li>
          <li>No court fees</li>
        </ul>
      </Section>
    </div>
  )
}

export default MembershipPage
