import { gql } from '@apollo/client'

// export const LOGIN = gql`
//   mutation Login($input: UsersPermissionsLoginInput!) {
//     login(input: $input) {
//       jwt
//       user {
//         id
//         username
//         email
//         skill
//         ageGroup
//         phone
//         firstName
//         lastName
//       }
//     }
//   }
// `

// export const CURRENT_USER = gql`
//   query Me {
//     result: me {
//       id
//       username
//       email
//       skill
//       ageGroup
//       phone
//       firstName
//       lastName
//       role {
//         id
//         name
//         description
//         type
//       }
//     }
//   }
// `

export const SAVE_USER = gql`
  mutation UpdateUsersPermissionsUser($updateUsersPermissionsUserId: ID!, $data: UsersPermissionsUserInput!) {
    updateUsersPermissionsUser(id: $updateUsersPermissionsUserId, data: $data) {
      data {
        attributes {
          username
          email
          skill
          ageGroup
          phone
          firstName
          lastName
        }
      }
    }
  }
`

// export const GET_USER = gql`
//   query GetUser($id: ID) {
//     usersPermissionsUser(id: $id) {
//       data {
//         attributes {
//           username
//           email
//           role {
//             data {
//               attributes {
//                 name
//               }
//             }
//           }
//           ageGroup
//           firstName
//           lastName
//         }
//       }
//     }
//   }
// `
