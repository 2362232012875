import { EmailLink } from '../common/EmailLink'
import Section from '../common/Section'
import { Typography } from 'antd'
const { Paragraph, Title, Text } = Typography

const JuniorDropIns = () => {
  return (
    <>
      <Section title="Weekly Drop-Ins">
        <Title level={2}>Junior Drop-Ins (all levels)</Title>
        {/* <Paragraph>Put the skills you’ve learned in lessons and camps to the test.</Paragraph>
        <Paragraph>Coaches will be on-site to coordinate and offer guidance. </Paragraph>
        <Paragraph>Open to players ages 10 - 17. </Paragraph>
        <Paragraph>
          One court will be dedicated to teens. No partner needed - we'll match you with other participants.{' '}
        </Paragraph>
        <Paragraph>
          Junior Drop-Ins will be held on <b>Thursday evenings, 5:00 - 7:00pm</b> running June 23 until September 15
        </Paragraph> */}
      </Section>
    </>
  )
}

export default JuniorDropIns
