import { createContext } from 'react'

const defaultValue = {
  currentUser: null,
  login: (loginResult) => {},
  logout: () => {},
}

const AppContext = createContext(defaultValue)

export const AppProvider = AppContext.Provider
export default AppContext
